import { BiCoffee, BiWifi } from 'react-icons/bi'
import { CgGym } from 'react-icons/cg'
import { GiHomeGarage } from 'react-icons/gi'
import { MdCheckCircle, MdLocalLaundryService, MdPool, MdRestaurant, MdSpa } from 'react-icons/md'

export const iconsAmn = amn => {
  if (amn === 'Academia') {
    return CgGym
  }
  if (amn === 'Internet') {
    return BiWifi
  }
  if (amn === 'Estacionamento') {
    return GiHomeGarage
  }
  if (amn === 'Café da manhã') {
    return BiCoffee
  }
  if (amn === 'Piscina') {
    return MdPool
  }
  if (amn === 'Restaurante') {
    return MdRestaurant
  }
  if (amn === 'Spa') {
    return MdSpa
  }
  if (amn === 'Lavanderia') {
    return MdLocalLaundryService
  }
  return MdCheckCircle
}
