import React from 'react';
import { Box, Flex, Heading, Icon, Text, Button, useMediaQuery } from '@chakra-ui/react';
// import ResumeCard from '../../components/ResumeCard'
import { VscChecklist } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGrid } from '../../components/DataGrid';
import { getCellStatusReservation, isNull } from '../../utils/FormatFunctions';
import AlertModal from '../../components/AlertModal';
import { InfoButton } from '../../components/Buttons/InfoButton';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { SearchFilter } from '../../components/inputs/filters/search';
import { DateFilter } from '../../components/inputs/filters/date';
import { useFetchCPT } from '../../hooks/useFetchCPT';

const Exchanges = () => {
  const baseUrl = '/v1/reservations';
  const { requestApiCPT, data, loading, error } = useFetchCPT(baseUrl);
  const navigate = useNavigate();

  const columns = [
    { name: 'service', label: 'Serviço' },
    { name: 'cng_service_id', label: 'Número da reserva' },
    // { name: 'cng_booking_number', label: 'Número do serviço' },
    // { name: 'cng_service_id', label: 'Número do serviço' },
    { name: 'checkin_date', label: 'Checkin' },
    { name: 'checkout_date', label: 'Checkout' },
    { name: 'status', label: 'Status' },
    { name: 'cng_booking_number', label: 'Enviar voucher' },
    // { name: 'id', label: 'Id' },
    // { name: 'cc_id', label: 'CC Id' },
    // { name: 'id_user', label: 'Id do Usuário' },
    // { name: 'transaction_id', label: 'Id de Transação' },
    // { name: 'user_firstname', label: 'Nome' },
    // { name: 'observation', label: 'Observação' },
    // { name: 'created_at', label: 'Data de criação' },
    // { name: 'user_lastaccess', label: 'Último acesso' },
  ];

  const openVoucherTab = (bookId: any) => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`${process.env.REACT_APP_API_CPT_URL}/v1/viewvoucher1/${bookId}`, '_blank', 'noreferrer');
  };

  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  });

  return (
    <Flex flexDir="column" ml={isMobile ? '0' : '4vw'} w={isMobile ? '100%' : '73vw'}>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="panel/dashboard" />
            <Heading as="h1" size="lg">
              Reservas
            </Heading>
          </Flex>

          <DataGrid
            isId={null}
            columns={columns}
            rows={data != null && data?.reservations}
            requestApiCPT={requestApiCPT}
            error={error}
            baseUrl={baseUrl}
            data={data != null && data?.reservations}
            hasCreate
            hasCreateUrl="/"
            loading={loading}
            infoButton={[
              {
                name: 'steps',
                button: (
                  <AlertModal
                    alertMsg={
                      <Flex align="center">
                        <Icon as={VscChecklist} color="#1890FF" mr="5px" fontSize="25px" />
                        <Text>Clique no ícone para listar as etapas de reservas</Text>
                      </Flex>
                    }
                    msgStatus="info"
                    ButtonOpen={InfoButton}
                  />
                ),
              },
            ]}
            columnWithAction={[
              {
                name: 'steps',
                columnAction: (rowItem: {
                  id: any;
                  total_comment:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <Flex align="center">
                    <IconGeneralButton
                      Icon={VscChecklist}
                      color="#1890FF"
                      fontSize="25px"
                      onClick={() => navigate(`${rowItem.id}`)}
                    />
                    <Text fontWeight="bold">{rowItem.total_comment}</Text>
                  </Flex>
                ),
              },
            ]}
            rowActions={{ show: true, edit: false, delete: false }}
            formatColum={[
              {
                field: 'cng_booking_number',
                formatField: (value: any) => (
                  <Button onClick={() => openVoucherTab(value)} type="button" colorScheme="red">
                    Visualizar voucher
                  </Button>
                ),
              },
              {
                field: 'service',
                formatField: (value: any) => (value === null ? 'Hotel' : 'Hotel'),
              },
              {
                field: 'cng_service_id',
                formatField: (value: any, item: any) => {
                  if (item && item.cng_booking_number && item.cng_service_id) {
                    return `${item.cng_booking_number}-${value}`;
                  } else {
                    return 'Valor Padrão';
                  }
                },
              },

              {
                field: 'created_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'checkin_date',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy'),
              },
              {
                field: 'checkout_date',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy'),
              },
              {
                field: 'user_lastaccess',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'status',
                formatField: getCellStatusReservation,
              },
              {
                field: 'cc_id',
                formatField: isNull,
              },
            ]}
            headerFilters={[
              {
                name: 'search',
                filterComponent: SearchFilter,
              },
              {
                name: 'startDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data inicial',
              },
              {
                name: 'endDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data final',
              },
            ]}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Exchanges;
