import React from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';

export default function SideMenuDesktop(props) {
  return (
    <Box
      userSelect="none"
      justifyContent="flex-start"
      cursor="pointer"
      onClick={() => props.clicked({ route: props.route })}
    >
      <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
        <Box
          cursor="pointer"
          display="flex"
          flexDir="row"
          alignItems="center"
          borderRadius="10px"
          boxShadow="base"
          bg="white"
          h="55px"
          w="16vw"
        >
          <Flex ml="5">
            {props.actual ? (
              <Box w="3px" h="100%" backgroundColor="#E3203D" mr={1}>
                &nbsp;
              </Box>
            ) : (
              <Box w="3px" h="100%">
                &nbsp;
              </Box>
            )}
            <Box w="24px" mr="2">
              {/* Verifica se o ícone é uma string (caminho de imagem) ou um componente React */}
              {typeof props.icon === 'string' ? <Image h="24px" src={props.icon} alt={props.title} /> : props.icon}
            </Box>
            <Box fontWeight={700} ml={2}>
              {props.title}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
