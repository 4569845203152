import React from 'react'
import { Box } from '@chakra-ui/react'
import { rWidth } from '../../utils/responsiveSizes'
import SliderBedroom from '../../components/SliderBedroom'

export default function BedroomDetails({ title, textDescription, image }) {
  return (
    <Box display="flex" justifyContent="center" w="100%">
      <Box w={rWidth}>
        <SliderBedroom images={image} title={title} textDescription={textDescription} />
      </Box>
    </Box>
  )
}
