import React from 'react'
import { Box, Flex, Text, Image, Icon } from '@chakra-ui/react'
import { VscError } from 'react-icons/vsc'
import { AiOutlineAudio, AiOutlineCheckCircle } from 'react-icons/ai'
import { GoDotFill } from 'react-icons/go'
import { CgFileDocument } from 'react-icons/cg'
import { MdOndemandVideo } from 'react-icons/md'

import { handleImgError } from './HandleFunctions'
import moment from 'moment'

export const getCellExclusive = (v: boolean) => {
  if (v === true) {
    return <AiOutlineCheckCircle color="green" fontSize={25} />
  }
  return <VscError color="red" fontSize={25} />
}

export const getCellStatus = (v: string | number) => {
  if (v === '1' || v === 1) {
    return <span style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}>Ativo</span>
  }
  return <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>Inativo</span>
}
export const getCellStatusReservation = (v: string | number) => {
  if (v === '1' || v === 1) {
    return (
      <span style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}>Confirmado</span>
    )
  }
  if (v === '0' || v === 0) {
    return (
      <span style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}>Cancelado</span>
    )
  }
  return (
    <span style={{ color: 'white', padding: 4, background: 'yellow', borderRadius: 5, fontSize: 14 }}>Aguardando</span>
  )
}

export const getCellReview = (v: string | number) => {
  if (v === '1' || v === 1) {
    return (
      <span style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}>Aprovado</span>
    )
  }
  return <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>Reprovado</span>
}

export const userType = (item: string) => {
  if (item === '1') {
    return 'Administrador'
  }
  if (item === '2') {
    return 'Colaborador'
  }
}

export const isNull = (item: any) => {
  if (item === '' || item === undefined || item === null) {
    return '-'
  } else {
    return item
  }
}

export const formatPrice = (value: number) => {
  const formattedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)

  return formattedPrice
}

export const getCellGeneric = (item: any) => {
  if (item) {
    return 'Não informado(a)'
  } else {
    return item
  }
}

export const getCellColor = (value: any) => {
  if (value)
    return (
      <Flex align="center">
        <Box bg={value} borderRadius="5px" w="20px" h="20px" mr="5px" />
        <Text>{value}</Text>
      </Flex>
    )
  return 'Não informado'
}

export const getCellImage = (value: any) => {
  if (value?.medium) return <Image w="50px" borderRadius="5px" onError={handleImgError as any} src={value.medium} />
  return 'Não informado'
}

export const getFileTypeIcon = (value: any) => {
  if (value === 'video')
    return (
      <Flex align="center">
        <Icon as={MdOndemandVideo} mr="5px" fontSize="25px" />
        <Text>Vídeo</Text>
      </Flex>
    )
  if (value === 'audio')
    return (
      <Flex align="center">
        <Icon as={AiOutlineAudio} mr="5px" fontSize="25px" />
        <Text>Audio</Text>
      </Flex>
    )
  if (value === 'docs')
    return (
      <Flex align="center">
        <Icon as={CgFileDocument} mr="5px" fontSize="25px" />
        <Text>Documento</Text>
      </Flex>
    )
  return 'Não informado'
}

export const isValidCpf = (value: any) => {
  const repValue = value.replace(/\D/g, '')
  const repLength = repValue.length
  return repLength < 9
}

export const getStatusDot = (value: any) => {
  if (value.length > 0)
    return [
      ...(value?.map(
        (item: {
          status: any
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        }) => (
          // eslint-disable-next-line react/jsx-key
          <Flex align="center">
            <Icon as={GoDotFill} mr="5px" color={item.status ? 'green' : 'red'} fontSize="15px" />
            <Text>{item.name}</Text>
          </Flex>
        ),
      ) ?? []),
    ]
  return 'Não informado'
}

export const formatDate = (date: string | undefined): JSX.Element | undefined => {
  if (date) {
    return <Text>{moment(date).format('DD/MM/yyyy hh:mm')}</Text>
  } else {
    return <Text>--------------------</Text>
  }
}

export const formatTransactionType = (transactionType: string | undefined): JSX.Element | undefined => {
  if (transactionType === 'D') {
    return <Text color="green.500">Deposito</Text>
  } else if (transactionType === 'S') {
    return <Text color="red.500">Saque</Text>
  }
}

export const formatAbrangency = (abrangency: string | undefined): JSX.Element | undefined => {
  if (abrangency === 'N') {
    return <Text>Nacional</Text>
  } else if (abrangency === 'I') {
    return <Text>Internacional</Text>
  }
}

export const formatCashclubs = (row: any, item: any): JSX.Element | undefined => {
  if (item?.transaction_type === 'D' && item?.cashclubs !== undefined) {
    return <Text color="green.500">+ {item.cashclubs}</Text>
  } else if (item?.transaction_type === 'S' && item?.cashclubs !== undefined) {
    return <Text color="red.500">- {item.cashclubs}</Text>
  }

  // Se algo estiver faltando ou não corresponder às condições, retorne undefined ou null.
  return undefined
}
