import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { rWidth } from '../../utils/responsiveSizes'
import TabFilter from '../../components/TabFilter'
import LocationFilter from '../../components/LocationFilter'
import SliderDetails from '../../components/SliderDetails'
import { apiCommons } from '../../services/api'
import { useSearch } from '../../providers/SearchProvider'

export default function Details() {
  const { state } = useLocation()

  const [hotelDetails, setHotelDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const { getHotelDetailsCangoroo, mnPrice, mxPrice, nNights, roomOnly, sR, nationality } = useSearch()

  const getHotelDetails = async () => {
    await setLoading(true)
    await apiCommons
      .get(`/mapping/hotel/${state?.itemId}/token/fdaa4256-1e4d-40c6-aa1c-1eaeafdbff04?managerId=469`)
      .then(res => {
        setHotelDetails(res?.data)
        localStorage.setItem('_hotelDetails', JSON.stringify(res?.data))
        setLoading(false)
      })
      .catch(res => console.log(res))
  }

  useEffect(() => {
    if (state?.itemId !== null) {
      getHotelDetails()
      getHotelDetailsCangoroo({
        hotelId: state?.itemId,
        minimunPrice: mnPrice,
        maxPrice: mxPrice,
        numNights: nNights,
        roomsOnly: roomOnly,
        sRooms: sR,
        nation: nationality,
      })
    }
  }, [state?.itemId])
  return (
    <Box display="flex" justifyContent="center" w="100%">
      {loading ? (
        <CircularProgress mt="50px" isIndeterminate color="red" thickness="12px" />
      ) : (
        <Box w={rWidth} flexDir={['column', 'row']}>
          <Box ml={['20px', 0]}>
            <TabFilter />
          </Box>
          <LocationFilter data={hotelDetails} />
          <SliderDetails images={hotelDetails?.photos} loading={loading} data={hotelDetails} />
        </Box>
      )}
    </Box>
  )
}
