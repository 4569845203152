/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Grid, GridItem, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { AutoComplete, AutoCompleteInput, AutoCompleteList } from '@choc-ui/chakra-autocomplete'
import { selectTypeDstn, typeDestination } from '../../utils/hotelsSearch' //typeDestination é a propriedade que chama o tipo de ícone
import { useLanguage } from '../../providers/LanguageProvider'
import { translates } from '../../utils/languague'

export default function DentinationSearch({
  inputReference,
  verifySrc,
  onChanged,
  destinations,
  loadingD,
  mapping,
  setDestinationID,
  setHotelID,
  setSearch,
}) {
  const { language } = useLanguage()

  const groupByTypes = item => {
    const isType = destinations => destinations?.type === item
    return destinations?.filter(isType)
  }

  const handleFocus = item => {
    inputReference.current.focus({ focusVisible: false })
    inputReference.current.value = `${item}`
  }

  return (
    <>
      <InputGroup zIndex={2}>
        <AutoComplete freeSolo emptyState="" closeOnBlur onSuspend={false}>
          <AutoCompleteInput
            value={inputReference?.current?.value}
            ref={inputReference}
            focusBorderColor={(verifySrc != '' || verifySrc?.length > 20) && 'red'}
            borderColor={(verifySrc != '' || verifySrc?.length > 20) && 'red'}
            placeholder={translates(language)?.searchbox?.default?.item2}
            onChange={async e => {
              setSearch(e?.target?.value)
              if (destinations !== [] && (await e?.target?.value?.length) >= 3) {
                onChanged(e?.target?.value)
                setSearch(e?.target?.value)
              } else {
                setSearch(translates(language)?.filters?.item24)
              }
            }}
          />
          <AutoCompleteList>
            <Box display="flex" alignSelf="center" alignItems="center" justifyContent="flex-start">
              {destinations?.length == 0 && !loadingD && translates(language)?.searchbox?.default?.item14}
              {loadingD ? translates(language)?.searchbox?.default?.item13 : ''}
            </Box>

            {mapping?.map(_ => (
              <div key="mapping">
                {groupByTypes('Multi-Region (within a country')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Multi-Region (within a country')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Multi-Region (within a country').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`'Multi-Region (within a country' - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Multi-City (Vicinity)')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Multi-City (Vicinity)')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Multi-City (Vicinity)').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`'Multi-City (Vicinity)' - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('City')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'City')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('City').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`City - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Neighborhood')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Neighborhood')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Neighborhood').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Neighborhood - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Airport')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Airport')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Airport').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Airport - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Point of Interest')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Point of Interest')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Point of Interest').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Point of Interest - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Point of Interest Shadow')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Point of Interest Shadow')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Point of Interest Shadow').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Point of Interest Shadow - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Hotel')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Hotel')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Hotel').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Hotel - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Train')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Train')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Train').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Train - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
                {groupByTypes('Country')?.length > 0 && (
                  <Box alignItems="center" justifyContent="flex-start">
                    <Text fontWeight="medium" ml="8px">
                      {selectTypeDstn(language, 'Country')}
                    </Text>
                  </Box>
                )}
                {groupByTypes('Country').map((h, idx) => (
                  <Flex
                    p="8px"
                    key={`Country - ${idx}`}
                    _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                    onClick={() => {
                      setDestinationID(h?.destinationId)
                      setHotelID(h?.hotelId)
                      handleFocus(`${h?.name[language]}`)
                      setSearch(`${h?.name[language]}`)
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                      <Box>{typeDestination(h?.type)}</Box>
                      <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                    </Box>
                  </Flex>
                ))}
              </div>
            ))}
          </AutoCompleteList>
        </AutoComplete>
        <InputRightElement
          pointerEvents="none"
          // eslint-disable-next-line react/no-children-prop
          children={<BiSearch color="gray.300" />}
        />
      </InputGroup>
    </>
  )
}
