import React from 'react'
import { Flex, Box, Image } from '@chakra-ui/react'

export default function SideMenu(props) {
  return (
    <Box
      userSelect="none"
      justifyContent="flex-start"
      cursor="pointer"
      onClick={() => props.clicked({ route: props.route })}
      // onClick={() => props.clicked({ component: props.component, route: props.route })}
    >
      <Flex>
        {props.actual ? (
          <Box w="3px" h="100%" backgroundColor="#E3203D" mr={1}>
            &nbsp;
          </Box>
        ) : (
          <Box w="3px" h="100%">
            &nbsp;
          </Box>
        )}
        <Box w="24px">
          <Image h="24px" mb={3} src={props.icon} />
        </Box>
        <Box fontWeight={700} ml={2}>
          {props.title}
        </Box>
      </Flex>
      <Box />
    </Box>
  )
}
