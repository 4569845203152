/* eslint-disable react/no-children-prop */
import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeftCircle } from 'react-icons/bs'

export const ReturnButton = ({ previousPage, ...props }: any) => {
  const navigate = useNavigate()
  return (
    <IconButton
      // color={useColorModeValue('text.light.redInterm', 'text.dark.redInterm')}
      variant="ghost"
      borderRadius="full"
      _hover={{ opacity: 0.6 }}
      onClick={() => navigate(`/${previousPage}`)}
      icon={<BsArrowLeftCircle fontSize="30px" />}
      mr="15px"
      {...props}
    />
  )
}
