import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import DashboardCard from '../../../components/DashboardCard';
import ReactTable from '../../../components/ReactTable';
import { apiCPT } from '../../../services/api';
import DashboardCardMaster from '../../../components/DashboardCardMaster';
// import { useFetchCPT } from '../../../hooks/useFetchCPT';

const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem('__user_data') || '{}');

  //const baseUrl = '/exchanges';
  //const { requestApi, data, loading, error } = useFetchCPT(baseUrl);
  const navigate = useNavigate();
  const [dados, setDados] = useState<any>({});
  const [dados2, setDados2] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    getInfos();
  }, []);

  const getInfos = async () => {
    try {
      const getData = await apiCPT.get(`v1/dashboard`);

      if (getData.status === 200) {
        setDados(getData.data);
      }

      const getData2 = await apiCPT.get(`v1/ownership/0?reviewed=null`);

      if (getData2.status === 200) {
        setDados2(getData2.data.ownership);
        setPageSize(getData2.data.paginateCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Empreendimento',
        columns: [
          {
            Header: 'Nome',
            accessor: 'enterprise.enterprise_name',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Unidade Habitacional',
            accessor: 'habitational_unity.room_type',
          },
          {
            Header: 'Data de entrada',
            accessor: 'start_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(new Date(row.original.start_at.toString())).format('DD/MM/YYYY').toString()}</Text>;
            },
          },
          {
            Header: 'Data de saída',
            accessor: 'end_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(new Date(row.original.end_at.toString())).format('DD/MM/YYYY').toString()}</Text>;
            },
          },
        ],
      },
    ],
    [],
  );
  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      {Number(userData.user_type) === 1 && <DashboardCardMaster />}

      {Number(userData.user_type) === 3 && <DashboardCard />}
      {Number(userData.user_type) >= 4 && (
        <>
          <Box mt="20px" mb="20px">
            <Text fontSize="18px" fontWeight="bold" color="#2D3748">
              Pendentes de aprovação
            </Text>
          </Box>

          <Box>
            <ReactTable
              columns={columns}
              pageNumber={pageNumber}
              data={dados2}
              totaPages={pageSize}
              onChange={(e: any) => {
                console.log('Akkkk:: ', e);
                setPageNumber(e.pageIndex + 1);
                setPerPage(e.sPageSize);
              }}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};
export default Dashboard;
