import React from 'react';
import AdmEnterprise from './AdmEnterprise';
import ClientEnterprise from './ClientEnterprise';

const UserComponent: React.FC = () => {
  const userData = JSON.parse(localStorage.getItem('__user_data') || '{}');

  if (Number(userData.user_type) <= 3) {
    return <ClientEnterprise />;
  } else {
    return <AdmEnterprise />;
  }
};

export default UserComponent;
