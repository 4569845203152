import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnterprisesForm from '../../../components/forms/EnterprisesForm';
import EnterprisesAdmForm from '../../../components/forms/EnterprisesAdmForm';
import { useFetchCPT } from '../../../hooks/useFetchCPT';

export default function UsersCadastroPage() {
  const { itemId } = useParams();
  const userData = JSON.parse(localStorage.getItem('__user_data') || '{}');
  const { requestApiCPT, data } = useFetchCPT(`v1/enterprises/${itemId}`);

  // Adicionar log para verificar o valor de user_type
  console.log('user_type:', userData.user_type);

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        await requestApiCPT('get');
      }
    };
    getInfos();
  }, [itemId, requestApiCPT]);

  if (Number(userData.user_type) === 3) {
    console.log('Renderizando EnterprisesForm');
    return <EnterprisesForm defaultValues={data?.enterprise ?? []} />;
  } else if (Number(userData.user_type) > 3) {
    console.log('Renderizando EnterprisesAdmForm');
    return data?.enterprise ? (
      <EnterprisesAdmForm defaultValues={data.enterprise} defaultValues2={data} />
    ) : (
      <EnterprisesAdmForm defaultValues={[]} defaultValues2={[]} />
    );
  } else {
    console.log('User type não reconhecido');
    return;
  }
}
