import React, { useState } from 'react'
import {
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Box,
  useDisclosure,
  Portal,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import FocusLock from 'react-focus-lock'
import { CalendarPanel } from './calendarPanel'
import { Month_Names_Short, Weekday_Names_Short } from './calendarUtils'

function RangeCalendarPanel({ dayzedHookProps, configs, propsConfigs, selected }) {
  const [hoveredDate, setHoveredDate] = useState(null)

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null)
  }

  // Date level
  const onMouseEnterHighlight = date => {
    if (!Array.isArray(selected) || !selected?.length) {
      return
    }
    setHoveredDate(date)
  }

  const isInRange = date => {
    if (!Array.isArray(selected) || !selected?.length) {
      return false
    }
    const firstSelected = selected[0]
    if (selected.length === 2) {
      const secondSelected = selected[1]
      return firstSelected < date && secondSelected > date
    }
    return (
      hoveredDate && ((firstSelected < date && hoveredDate >= date) || (date < firstSelected && date >= hoveredDate))
    )
  }

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  )
}

const DefaultConfigs = {
  dateFormat: 'dd/MM/yyyy',
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
}

export function RangeDatepicker({
  configs = DefaultConfigs,
  propsConfigs = {},
  id,
  name,

  defaultIsOpen = false,
  onChange = () => null,
  ...props
}) {
  const { selectedDates, minDate, maxDate, onDateChange, disabled } = props

  // chakra popover utils
  const [dateInView, setDateInView] = useState(selectedDates[0] || new Date())
  const [offset, setOffset] = useState(0)
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen })

  // dayzed utils
  const handleOnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return
    }
    const newDates = [...selectedDates]
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        const firstTime = selectedDates[0]
        if (firstTime < date) {
          newDates.push(date)
          // update
          onClose()
        } else {
          newDates.unshift(date)
        }
        onDateChange(newDates)
      } else if (newDates.length === 2) {
        onDateChange([date])
      }
    } else {
      newDates.push(date)
      onDateChange(newDates)
    }
  }

  const onPopoverClose = () => {
    onClose()
    setDateInView(selectedDates[0] || new Date())
    setOffset(0)
  }

  // eventually we want to allow user to freely type their own input and parse the input
  let intVal = selectedDates[0] ? `${format(selectedDates[0], configs.dateFormat)}` : ''
  intVal += selectedDates[1] ? ` - ${format(selectedDates[1], configs.dateFormat)}` : ''

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <PopoverTrigger>
        <Input
          id={id}
          autoComplete="off"
          isDisabled={disabled}
          name={name}
          value={intVal}
          onChange={(e => e.target.value, onChange)}
          {...propsConfigs.inputProps}
        />
      </PopoverTrigger>
      <Portal>
        <Box zIndex="sticky">
          <PopoverContent width="100%">
            <PopoverBody>
              <FocusLock>
                <RangeCalendarPanel
                  dayzedHookProps={{
                    onDateSelected: handleOnDateSelected,
                    selected: selectedDates,
                    monthsToDisplay: 2,
                    date: dateInView,
                    minDate: minDate,
                    maxDate: maxDate,
                    offset: offset,
                    onOffsetChanged: setOffset,
                  }}
                  configs={configs}
                  propsConfigs={propsConfigs}
                  selected={selectedDates}
                />
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}
