/* eslint-disable react/no-children-prop */
import { EditIcon } from '@chakra-ui/icons'
import { IconButton, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export const EditButton = ({ onClick }: any) => {
  return (
    <IconButton
      onClick={onClick}
      color={useColorModeValue('theme.light.blueStrong', 'theme.dark.blueStrong')}
      icon={<EditIcon h={5} w={5} />}
      size="sm"
      mr={2}
      variant="ghost"
      _hover={{ opacity: 0.5 }}
      aria-label=""
    />
  )
}
