import React, { createContext, useState, useContext, useEffect } from 'react'

export const LanguageContext = createContext(null)

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(JSON.parse(localStorage?.getItem('language')))

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem('language')) == null ||
      JSON.parse(localStorage?.getItem('language')) == undefined
    ) {
      localStorage.setItem('language', JSON.stringify('pt-BR'))
      // window.location.reload()
    }
  }, [])

  const value = React.useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage],
  )

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

export function useLanguage() {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('The context The Perfil must be within a valid provider')
  }
  return context
}
