import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FiUserPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const DashboardCardMaster = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDir="column">
      <Box mb="20px">
        <Text fontSize="18px" fontWeight="bold" color="#2D3748">
          Ações rápidas
        </Text>
      </Box>
      <Flex flexDir="row">
        <Box
          cursor="pointer"
          onClick={() => navigate('/search')}
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#FFFFFF"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <FiUserPlus fontSize="40px" color="#000" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
            Fazer uma reserva
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
export default DashboardCardMaster;
