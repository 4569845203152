export const colors = {
  theme: {
    primary: '#272525',
    secondary: '#4A5568',
    tertiary: '#3C3C3C',
    body: '#E5E5E5',
    boxs: '#EDF2F7',
    white: '#FFFFFF',
    gray: '#F5F5F5',
    fusca: '#EDEDED',
    celta: '#8B8E93',
    grayLight: '#C7C9CB',
  },
  red: {
    500: '#000',
  },
  text: {
    primary: '#3C3C3C',
    secondary: '#272525',
    tertiary: '#4A5568',
    input: '#8B8E93',
    white: '#FFFFFF',
    gray: '#A2A5A8',
    blue: '#1569A8',
    chumbo: '#737577',
    celta: '#787B80',
    uno: '#505255',
    green: '#07530E',
  },
}
