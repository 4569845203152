/* eslint-disable react/no-children-prop */
import React from 'react'
import { IconButton } from '@chakra-ui/react'

export const IconGeneralButton = ({ onClick, Icon, ...rest }: any) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<Icon />}
      fontSize="30px"
      mr={2}
      variant="ghost"
      _hover={{ opacity: 0.5 }}
      aria-label=""
      {...rest}
    />
  )
}
