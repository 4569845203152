import React, { useEffect, useState } from 'react';
import { Stack, Center, Text, Box, Button } from '@chakra-ui/react';
import { BsCheck } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export default function BookSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  // const data = { cng_booking_number: 411, cng_service_id: 333 }
  const [userData, setUserData] = useState([]);

  const openInNewTab = () => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`https://api.whatsapp.com/send?phone=556230892500`, '_blank', 'noreferrer');
  };

  const openVoucherTab = bookId => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`${process.env.REACT_APP_API_CPT_URL}/v1/viewvoucher1/${bookId}`, '_blank', 'noreferrer');
  };

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      setUserData(JSON.parse(localStorage.getItem('__user_data')));
    }
  }, []);

  return (
    <Stack spacing={-12}>
      <Center mt="150px" flexDir="column">
        <Box>
          <Text fontSize="16px" color="#1A202C" fontWeight="semibold">{`Obrigado, ${userData?.user_firstname}!`}</Text>
          <Box display="flex" flexDir="row">
            <Text
              fontSize="24px"
              color="#1A202C"
              fontWeight="semibold"
            >{`Sua reserva em ${userData?.user_firstname} está confirmada.`}</Text>
            <Text fontSize="24px" color="red" fontWeight="semibold">
              {' '}
              {` Reserva #${data?.cng_booking_number} - ${data?.cng_service_id}`}
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDir="column" mt="40px" alignContent="flex-start">
          <Box display="flex" flexDir="row" alignContent="flex-start">
            <BsCheck style={{ marginRight: 10, color: 'green' }} />
            <Text>{`Enviamos seu email de confirmação com o voucher para ${userData?.email}!`}</Text>
          </Box>
          <Box display="flex" flexDir="row" justifyContent="flex-start">
            <BsCheck style={{ marginRight: 10, color: 'green' }} />
            <Text>Qualquer eventual problema, pode entrar em contato com o suporte da Club Share!</Text>
          </Box>
        </Box>

        <Box
          mt="50px"
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={3}
        >
          <Button
            onClick={() => navigate('/', { replace: true, preventScrollReset: true, state: null, relative: 'path' })}
            colorScheme="green"
          >
            Voltar para o início
          </Button>
          <Button onClick={() => openInNewTab()} type="button" colorScheme="red">
            Suporte Club Share
          </Button>
          <Button
            mt={3}
            gridColumn="span 2"
            onClick={() => openVoucherTab(data?.cng_booking_number)}
            type="button"
            colorScheme="red"
          >
            Abrir voucher agora!
          </Button>
        </Box>
      </Center>
    </Stack>
  );
}
