import NotFoundPage from '../pages/NotFoundPage'

import RecoverPass from '../pages/Recover'
import Login from '../pages/Login'
import Register from '../pages/Register'
import UpdateOldClient from '../pages/UpdateOldClient'
import ContactPage from '../pages/Contact'
import Association from '../pages/Association'

export const OpenRoutes = [
  {
    name: 'Not Found',
    path: '*',
    component: NotFoundPage,
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
  },
  {
    name: 'Associação',
    path: '/association',
    component: Association,
  },
  {
    name: 'Contato',
    path: '/contact',
    component: ContactPage,
  },
  {
    name: 'Registro',
    path: '/register',
    component: Register,
  },
  {
    name: 'Atualizar usuário antigo',
    path: '/updateOldClient',
    component: UpdateOldClient,
  },
  {
    name: 'Recuperar Senha',
    path: '/recover',
    component: RecoverPass,
  },
]
