import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  useColorModeValue,
  useToast,
  Input,
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Image,
  FormControl,
  FormLabel,
  Switch,
  HStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import logo from '../../assets/logo.svg'
import { apiCPT } from '../../services/api'
import { STORAGE_KEYS } from '../../constants/Config'

export default function Login() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [error2, setError2] = React.useState('')
  const [isSucess, setIsSucess] = React.useState(false)
  const [backHotel, setBackHotel] = useState(false)
  const [username, setUsername] = useState('')
  const [senha, setSenha] = useState('')
  const toast = useToast()
  const titleColor = useColorModeValue('gray.900', 'teal.200')
  const textColor = useColorModeValue('gray.700', 'white')

  useEffect(() => {
    if (state?.isBacktoHotel) {
      setBackHotel(state?.isBacktoHotel ?? false)
    }
  }, [state?.isBacktoHotel !== null])

  function handleClick() {
    if (username == '') {
      toast({
        title: 'Aviso',
        description: 'Informe o seu e-mail cadastrado',
        status: 'error',
        duration: 4000,
        isClosable: false,
      })
      return
    }
    if (senha == '') {
      toast({
        title: 'Aviso',
        description: 'Informe a senha cadastrada',
        status: 'error',
        duration: 4000,
        isClosable: false,
      })

      return
    }
    setLoading(true)
    toast({
      title: 'Aviso',
      description: 'Processando credenciais..',
      status: 'info',
      duration: 4000,
      isClosable: false,
    })
    const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username)

    apiCPT
      .post('/v1/login', {
        email: isEmail ? username : null,
        cpf: isEmail ? null : username?.replace(/\D/g, ''),
        password: senha,
        grant_type: 'password',
      })
      .then(res => {
        if (res.status == 200) {
          localStorage.setItem(STORAGE_KEYS.AUTH, JSON.stringify(res.data))

          apiCPT.get(`v1/users/${res.data.user.user_id}`).then(p => {
            toast({
              title: 'Aviso',
              description: 'Acesso Permitido! Redirecionando..',
              status: 'success',
              duration: 500,
              isClosable: false,
            })
            localStorage.setItem('__user_data', JSON.stringify(p.data))
            if (backHotel) {
              navigate(-1)
            } else {
              navigate('/', { replace: true })
            }
          })
          setLoading(false)
        } else {
          toast({
            title: 'Aviso',
            description: 'Credenciais Inválidas!',
            status: 'error',
            duration: 4000,
            isClosable: false,
          })
          setLoading(false)
        }
        setLoading(false)
      })
      .catch(res => {
        setLoading(false)
        toast({
          title: 'Aviso',
          description: 'Credenciais Inválidas!',
          status: 'error',
          duration: 4000,
          isClosable: false,
          render: console.log(res),
        })
      })
  }

  const startsWithNumbers = value => /^\d/.test(value)

  const formatCPF = value => {
    const formattedValue = value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') // Aplica a máscara do CPF

    return formattedValue
  }

  const openInNewTab = () => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`https://api.whatsapp.com/send?phone=556230892500`, '_blank', 'noreferrer')
  }

  return (
    <Flex position="relative" mb="40px">
      <Flex w="100%" maxW="1044px" mx="auto" justifyContent="space-between" mb="40px">
        <Flex alignItems="center" justifyContent="start" w={{ base: '100%', md: '50%', lg: '42%' }}>
          <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: '150px', lg: '80px' }}>
            <Heading size="md">
              <Link to="/">
                <Image w="250px" mb={7} src={logo} />
              </Link>
            </Heading>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Boas-vindas
            </Heading>
            <Text mb="36px" color={textColor} fontWeight="bold" fontSize="16px" fontFamily="Poppins">
              Entre com suas credenciais
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                E-mail ou CPF
              </FormLabel>
              <Input
                value={username}
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder="Seu e-mail ou CPF cadastrado"
                size="lg"
                onChange={p => {
                  const inputValue = p.target.value

                  if (startsWithNumbers(inputValue) && inputValue.length === 11) {
                    // Se começa com números e tem o mesmo comprimento de um CPF
                    setUsername(formatCPF(inputValue))
                  } else {
                    setUsername(inputValue)
                  }
                }}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Senha
              </FormLabel>
              <Input
                onChange={p => setSenha(p.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleClick()
                  }
                }}
                borderRadius="15px"
                mb="36px"
                fontSize="sm"
                type="password"
                placeholder="Sua senha"
                size="lg"
              />
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={['column', 'column', 'row']}
              >
                <HStack mb={[2, 0, 0, 0, 0]} display="flex" alignItems="center">
                  <Switch id="remember-login" colorScheme="red" />
                  <FormLabel fontSize="sm" htmlFor="remember-login" fontWeight="normal">
                    Lembrar de mim
                  </FormLabel>
                </HStack>

                <Link
                  onClick={async () => {
                    if (username === '') {
                      setError2(
                        'Informe o usuário para recuperar a senha  e  depois precione o  botão  de esqueceu  a  senha  novamente.',
                      )
                    } else {
                      const res = await apiCPT
                        .put(`/v1/request_update_password`, {
                          email: username,
                        })
                        .then(res => {
                          if (res.status === 200) {
                            setIsSucess(true)
                          }
                        })
                        .catch(err => {
                          if (err.response.status === 404) {
                            setError2('Usuário não encontrado.')
                          } else {
                            setError2('Erro ao enviar e-mail.')
                          }
                        })
                      return res
                    }
                  }}
                  fontSize={4}
                  color={titleColor}
                  as="span"
                  fontWeight="bold"
                >
                  Esqueceu sua senha?
                </Link>
              </FormControl>
              <Button
                isDisabled={loading}
                disabled={loading}
                isLoading={loading}
                onClick={() => handleClick()}
                fontSize="16px"
                type="submit"
                bg="red.500"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: 'red.600',
                }}
                _active={{
                  bg: 'red.700',
                }}
              >
                Acessar
              </Button>

              <>
                {error2 !== '' && (
                  <Alert status="error" mb="20px" borderRadius="5px">
                    <AlertIcon />
                    {error2}
                  </Alert>
                )}
              </>

              <>
                {isSucess && (
                  <Alert status="success" mb="20px" borderRadius="5px">
                    <AlertIcon />
                    Um e-mail foi enviado, vá até a caixa de e-mail e siga as instruções!
                  </Alert>
                )}
              </>
            </FormControl>
            <Flex
              flexDirection={['column', 'column', 'row']}
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium" mr="4px">
                Não tem acesso?
              </Text>
              <Link onClick={() => openInNewTab()} color={titleColor} as="span" fontWeight="bold">
                <Text color="red.600">Entre em contato conosco </Text>
              </Link>
            </Flex>
            <Flex
              flexDirection={['column', 'column', 'row']}
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium" mr="4px">
                ou
              </Text>
              <Link color="red" as="span" to="/register" fontWeight="bold">
                <Text color="red.500">Obter acesso aqui!</Text>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={'../../assets/imglogin.png'}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          />
        </Box>
      </Flex>
    </Flex>
  )
}
