/* eslint-disable react/no-children-prop */
import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

export const CreateButton = ({ onClick, ...rest }: any) => {
  return (
    <Button
      onClick={onClick}
      borderRadius="100px"
      w="150px"
      variant="outline"
      boxShadow="0px 3px 6px #2D3748"
      background="#FAFAFA"
      leftIcon={<Icon as={FaPlus} fontSize="20px" />}
      {...rest}
    >
      CRIAR
    </Button>
  )
}
