// hooks/useOwnershipData.ts
import { useEffect } from 'react'
import { useFetchCPT } from '../hooks/useFetchCPT'

export const useOwnershipData = (user_id: string | undefined) => {
  const baseUrl = `v1/ownership/${user_id || ''}`
  const { data, requestApiCPT, loading } = useFetchCPT(baseUrl)

  useEffect(() => {
    if (user_id !== undefined) {
      requestApiCPT('get')
    }
  }, [user_id])

  return { data, requestApiCPT, loading }
}
