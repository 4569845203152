import axios from 'axios'
import { apiCPT } from '../services/api'

export function identifyCardFlag(cardNumber) {
  const cleanedCardNumber = cardNumber.replace(/\D/g, '')

  if ((cleanedCardNumber.startsWith('34') || cleanedCardNumber.startsWith('37')) && cleanedCardNumber.length === 15) {
    return 'american_express'
  } else if (cleanedCardNumber.startsWith('4') && [13, 16].includes(cleanedCardNumber.length)) {
    return 'visa'
  } else if (
    cleanedCardNumber.startsWith('5') &&
    cleanedCardNumber.length === 16 &&
    ['1', '2', '3', '4', '5'].includes(cleanedCardNumber[1])
  ) {
    return 'mastercard'
  } else if (
    cleanedCardNumber.startsWith('6') &&
    cleanedCardNumber.length === 16 &&
    cleanedCardNumber.slice(1, 4) === '011'
  ) {
    return 'discover'
  } else if (
    (cleanedCardNumber.startsWith('5067') ||
      cleanedCardNumber.startsWith('4576') ||
      cleanedCardNumber.startsWith('4011')) &&
    cleanedCardNumber.length === 16
  ) {
    return 'elo'
  } else if (
    (cleanedCardNumber.startsWith('3841') || cleanedCardNumber.startsWith('6011')) &&
    cleanedCardNumber.length === 16
  ) {
    return 'hipercard'
  } else if (
    (cleanedCardNumber.startsWith('300') ||
      cleanedCardNumber.startsWith('301') ||
      cleanedCardNumber.startsWith('302') ||
      cleanedCardNumber.startsWith('303') ||
      cleanedCardNumber.startsWith('304') ||
      cleanedCardNumber.startsWith('305')) &&
    cleanedCardNumber.length === 14
  ) {
    return 'diners_club'
  } else {
    return 'mastercard'
  }
}

export default identifyCardFlag

export const getTokenCard = async ({ dataForm, customer_id }) => {
  try {
    const tokenCardJSON = {
      holder_name: dataForm?.name,
      card_expiration: dataForm?.expiry,
      card_number: dataForm?.number,
      card_cvv: dataForm?.cvc,
      payment_method_code: 'credit_card',
      payment_company_code: identifyCardFlag(dataForm?.number),
      customer_id: customer_id,
    }
    const optionsTokenCard = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_VINDI_PUBLIC}/payment_profiles`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${process.env.REACT_APP_KEY_VINDI_PUBLIC}`,
      },
      data: tokenCardJSON,
    }
    const res = await axios.request(optionsTokenCard)

    if (res.status !== 201) {
      throw new Error()
      return {
        data: {
          errors: [
            { id: 'invalid_parameter', parameter: 'card_number', message: 'inválido(a)' },
            { id: 'invalid_parameter', parameter: 'payment_profile', message: 'Tempo excedido na criação do perfil' },
          ],
        },
      }
    }
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getProfile = async ({ gateway_token, customer_id }) => {
  try {
    const dataJSON = {
      gateway_token: gateway_token,
      payment_method_code: 'credit_card',
      customer_id: customer_id,
    }
    const option = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_CPT_URL}/v1/payment_profiles`,
      data: dataJSON,
    }
    const res = await axios.request(option)

    return res
  } catch (error) {
    console.log(error)
  }
}

export const getProducts = async () => {
  try {
    const res = await apiCPT.get('/v1/products')

    if (res.status === 200) {
      return res?.data
    } else {
      console.error(`Error fetching products. Status: ${res.status}`)
      return null
    }
  } catch (err) {
    console.error(err)
    return null
  }
}

export const getProductID = async description => {
  const response = await getProducts()
  const products = await response?.data

  console.log('Products in getProductID:', products) // Este log para verificar os produtos recebidos

  const product = (await products) && products.find(x => x?.description.toLowerCase().includes(description))

  if (product) {
    console.log('Product found:', product) // Este log para verificar o produto encontrado
    return product?.product_vindi_id || null
  } else {
    console.log('Product not found') // Este log para verificar que nenhum produto foi encontrado
    throw new Error('Produto não encontrado')
  }
}

export const getPayment = async ({ gateway_token, customer_id, amount, typeProduct = 'hotelaria' }) => {
  try {
    // Obtém a lista de produtos
    const products = await apiCPT.get('/v1/products').then(res => res?.data?.products)

    console.log('Products in getPayment:', products) // Log para verificar os produtos recebidos

    // Encontra o produto desejado
    const product = products?.find(x => x?.description?.toLowerCase()?.includes(typeProduct))

    if (!product) {
      console.log('Product não encontrado') // Log para verificar que nenhum produto foi encontrado
      // Handle o caso em que o produto não foi encontrado (pode definir um produto padrão ou lançar um erro)
      return null
    }

    console.log('Product encontrado:', product) // Log para verificar o produto encontrado

    // Restante do código para processar o pagamento
    const paymentJSON = {
      customer_id: customer_id,
      payment_method_code: 'credit_card',
      gateway_token: gateway_token,
      bill_items: [
        {
          product_id: product?.product_vindi_id || null,
          amount: amount,
        },
      ],
    }

    const optionPayment = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_CPT_URL}/v1/bills`,
      data: paymentJSON,
    }

    const res = await axios.request(optionPayment)

    return res
  } catch (error) {
    console.log(error)
  }
}

export const getDellBill = async idx => {
  try {
    const optionDellBill = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_CPT_URL}/v1/bills/${idx}`,
      data: {
        id: idx,
        comments: 'Cancelado por motivo de não ter passado o cartão',
      },
    }
    const res = await axios.request(optionDellBill)

    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res
      } else {
        return res
      }
    }
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getDellCharge = async idx => {
  try {
    const optionDellCharges = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_CPT_URL}/v1/charges/${idx}`,
      data: {
        id: idx,
        comments: 'Cancelado por motivo de não ter passado o cartão',
      },
    }
    const res = await axios.request(optionDellCharges)

    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res
      } else {
        return res
      }
    }
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getRefund = async idx => {
  try {
    const optionRefund = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_CPT_URL}/v1/charges/${idx}/refund`,
      data: {
        cancel_bill: true,
        comments: 'Cancelado por motivo de não ter passado o cartão',
      },
    }
    const res = await axios.request(optionRefund)

    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res
      } else {
        return res
      }
    }
    return res
  } catch (error) {
    console.log(error)
  }
}
