import React, { useState } from 'react'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { useLanguage } from '../../providers/LanguageProvider'
import { translates } from '../../utils/languague'

export default function Children({ idx, rooms, setRooms }) {
  const { language } = useLanguage()
  const [countChildren, setCounChildren] = useState(rooms ?? 0)
  return (
    <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" mt="10px" alignItems="center">
          <Text fontWeight="bold" fontSize="16px">
            {translates(language)?.searchbox?.default?.item9}
          </Text>
          <Box margin="10px" display="flex" flexDirection="row">
            <IconButton
              onClick={async () => {
                if (countChildren[idx]?.childrenAge.length > 0) {
                  await setCounChildren(old => [
                    ...old,
                    {
                      [rooms[idx]?.childrenAge]: rooms[idx]?.childrenAge.pop(),
                    },
                  ])
                  await setRooms(old => [...old])
                }
              }}
              colorScheme="red"
              size="sm"
              aria-label="Remove Children"
              icon={<BiMinus />}
            />
            <Box ml="5px" mr="5px">
              <Text fontWeight="bold" fontSize="20px">
                {countChildren[idx]?.childrenAge?.length}
              </Text>
            </Box>
            <IconButton
              onClick={async () => {
                if (countChildren[idx]?.childrenAge.length < 3) {
                  await setCounChildren(old => [
                    ...old,
                    {
                      [rooms[idx]?.childrenAge]: rooms[idx]?.childrenAge.push(0),
                    },
                  ])
                  await setRooms(old => [...old])
                }
              }}
              colorScheme="red"
              size="sm"
              aria-label="Add Children"
              icon={<BiPlus />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
