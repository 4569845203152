import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { useLanguage } from '../../providers/LanguageProvider'

export default function LocationFilter({ data }) {
  const { language } = useLanguage()
  const sliptName = data?.destination?.name[language].split(',', 3)
  const navigate = useNavigate()
  return (
    <Box
      display="flex"
      justifyItems="flex-start"
      width={['100%', '72%']}
      flexDir={['column', 'row']}
      alignItems={['start', 'center']}
      h={['25px', '70px']}
    >
      {sliptName && (
        <>
          <Box
            minW={['200px', '120px']}
            mr={[0, '10px']}
            display="flex"
            justifyContent={['start']}
            alignItems={['center']}
            flexDir={['row-reverse', 'row']}
          >
            <Link to="/">
              <Text>Página inicial</Text>
            </Link>
            <Box visibility={['visible', 'hidden']}>
              <BiChevronLeft style={{ marginLeft: '0px', marginTop: '2%' }} color="black" size={15} />
            </Box>
            <Box visibility={['hidden', 'visible']}>
              <BiChevronRight style={{ marginLeft: '10px', marginTop: '2%' }} color="black" size={15} />
            </Box>
          </Box>
          <Box
            visibility={['hidden', 'visible']}
            mr="10px"
            display="flex"
            alignItems="center"
            flexDir={['column', 'row']}
          >
            <Box cursor="pointer" onClick={() => navigate(-1, { preventScrollReset: true })}>
              <Text>Hotel</Text>
            </Box>
            <BiChevronRight style={{ marginLeft: '10px', marginTop: '2%' }} color="black" size={15} />
          </Box>
          <Box
            visibility={['hidden', 'visible']}
            mr="10px"
            display="flex"
            alignItems="center"
            flexDir={['column', 'row']}
          >
            <Box cursor="pointer" onClick={() => navigate(-1, { preventScrollReset: true })}>
              <Text>{sliptName[2] ? sliptName[2] : ''}</Text>
            </Box>
            <BiChevronRight style={{ marginLeft: '10px', marginTop: '2%' }} color="black" size={15} />
          </Box>
          <Box
            visibility={['hidden', 'visible']}
            mr="10px"
            display="flex"
            alignItems="center"
            flexDir={['column', 'row']}
          >
            <Box cursor="pointer" onClick={() => navigate(-1, { preventScrollReset: true })}>
              <Text>{sliptName[1] ? sliptName[1] : ''}</Text>
            </Box>
            <BiChevronRight style={{ marginLeft: '10px', marginTop: '2%' }} color="black" size={15} />
          </Box>
          <Box
            visibility={['hidden', 'visible']}
            mr="10px"
            display="flex"
            alignItems="center"
            flexDir={['column', 'row']}
          >
            <Box cursor="pointer" onClick={() => navigate(-1, { preventScrollReset: true })}>
              <Text>{sliptName[0] ? sliptName[0] : ''}</Text>
            </Box>
            <BiChevronRight style={{ marginLeft: '10px', marginTop: '2%' }} color="black" size={15} />
          </Box>
          <Box
            visibility={['hidden', 'visible']}
            mr="10px"
            minW="400px"
            display="flex"
            fontWeight="bold"
            alignItems="center"
            flexDir={['column', 'row']}
          >
            <Box cursor="pointer" onClick={() => navigate(-1, { preventScrollReset: true })}>
              <Text>{data?.name ? data?.name : ''}</Text>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
