import React, { useEffect } from 'react'
import { Text } from '@chakra-ui/react'

const Timer = (props: any) => {
  const { minutes, setMinutes, seconds, setSeconds } = props

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <Text fontWeight="bold" color="text.light.secondary">
      {minutes.toString()}:{seconds < 10 ? `0${seconds.toString()}` : seconds.toString()}
    </Text>
  )
}

export default Timer
