import React from 'react'

import Routes from './routes/index'
// eslint-disable-next-line import/extensions

import { AuthProvider } from './providers/Auth'

export default function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  )
}
