// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'

interface InputInterface {
  name: string | undefined | any
  label: string | undefined
  placeholder?: string | undefined
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  styles?: any
  labelStyles?: any
  infoButton?: JSX.Element
  isRequired?: boolean
  value?: any
  control: any
  errors: any
}

export const UsernameInputForm = ({
  name,
  label,
  placeholder,
  iconLeft,
  iconRight,
  styles = {},
  labelStyles = {},
  infoButton,
  isRequired = false,
  control,
  errors,
  ...props
}: InputInterface) => {
  const [valueProps, setValueProps] = useState('')

  const ajusta = (v: string) => {
    const digitos = !v ? '' : v.replace(/[^\d]/g, '')
    if (!digitos || digitos.length < 10) return v
    const corte = digitos.length === 10 ? 6 : 7
    const max = digitos.length > 11 ? 11 : digitos.length
    return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`
  }

  const maskBuilder = (v: string | any[]) => {
    if (
      !v ||
      v.length === 0 ||
      !Number(valueProps.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', ''))
    )
      return ''
    return '(99) 99999-9999'
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(valueProps.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '')))
      setValueProps(ajusta(e.target.value))
    else setValueProps(e.target.value)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={2}>
      <Flex align="end">
        <FormLabel style={labelStyles} fontWeight="bold" mb={1} mr={2} htmlFor={name}>
          {label}
        </FormLabel>
        {infoButton}
      </Flex>

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: onChangeField, onBlur, ref } }) => (
          <InputGroup alignItems="center" alignContent="center">
            {iconLeft && (
              <InputLeftElement pointerEvents="none" fontSize="1.2em">
                {iconLeft}
              </InputLeftElement>
            )}
            <Input
              id={name}
              placeholder={placeholder}
              mask={maskBuilder(valueProps)}
              as={InputMask}
              maskChar={''}
              boxShadow="0px 3px 6px #2D3748"
              style={styles}
              onBlur={onBlur}
              inputRef={ref}
              value={valueProps}
              onChange={e => {
                handleChange(e)
                onChangeField(e)
              }}
              {...props}
            />
            {iconRight && (
              <InputRightElement pointerEvents="none" fontSize="1.2em">
                {iconRight}
              </InputRightElement>
            )}
          </InputGroup>
        )}
      />

      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  )
}
