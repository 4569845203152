import moment from 'moment'

// Retorna o dia em numeral
export const theNumberDay = data => {
  return moment(data, 'YYYY-MM-DD').format('DD')
}

// Traduz retorno do dia da semana
export const weekDay = dateSchedule => {
  if (dateSchedule === 1) {
    return 'Seg'
  }
  if (dateSchedule === 2) {
    return 'Ter'
  }
  if (dateSchedule === 3) {
    return 'Qua'
  }
  if (dateSchedule === 4) {
    return 'Qui'
  }
  if (dateSchedule === 5) {
    return 'Sex'
  }
  if (dateSchedule === 6) {
    return 'Sáb'
  }
  if (dateSchedule === 7) {
    return 'Dom'
  } else {
    return 'Dia'
  }
}

export const weekDayFull = dateSchedule => {
  if (dateSchedule === 1) {
    return 'Segunda'
  }
  if (dateSchedule === 2) {
    return 'Terça'
  }
  if (dateSchedule === 3) {
    return 'Quarta'
  }
  if (dateSchedule === 4) {
    return 'Quinta'
  }
  if (dateSchedule === 5) {
    return 'Sexta'
  }
  if (dateSchedule === 6) {
    return 'Sábado'
  }
  if (dateSchedule === 7) {
    return 'Domingo'
  } else {
    return 'Dia'
  }
}

// Retorna o dia da semana
export const theWeekDay = data => {
  if (moment(data, 'YYYY-MM-DD').isValid) {
    return weekDay(moment(data, 'YYYY-MM-DD').isoWeekday())
  }
}

export const theWeekDayFull = data => {
  if (moment(data, 'YYYY-MM-DD').isValid) {
    return weekDayFull(moment(data, 'YYYY-MM-DD').isoWeekday())
  }
}

// Traduz retorno do mês
export const monthName = dateSchedule => {
  if (dateSchedule === 1) {
    return 'Janeiro'
  }
  if (dateSchedule === 2) {
    return 'Fevereiro'
  }
  if (dateSchedule === 3) {
    return 'Março'
  }
  if (dateSchedule === 4) {
    return 'Abril'
  }
  if (dateSchedule === 5) {
    return 'Maio '
  }
  if (dateSchedule === 6) {
    return 'Junho'
  }
  if (dateSchedule === 7) {
    return 'Julho'
  }
  if (dateSchedule === 8) {
    return 'Agosto'
  }
  if (dateSchedule === 9) {
    return 'Setembro'
  }
  if (dateSchedule === 10) {
    return 'Outubro'
  }
  if (dateSchedule === 11) {
    return 'Novembro'
  }
  if (dateSchedule === 12) {
    return 'Dezembro'
  } else {
    return 'Mês'
  }
}

// Retorna o mês abreviado
export const theMonthName = data => {
  return monthName(moment(data, 'YYYY-MM-DD').month())
}

// Retorna o horário
export const asHour = data => {
  if (moment(data, 'hh-mm-ss').isValid()) {
    return moment(data, 'hh-mm-ss').format('HH:mm')
  } else {
    return '12:00 - 18:00'
  }
}

// Traduz retorno do mês abreviado
export const monthAbb = dateSchedule => {
  if (dateSchedule === 0) {
    return 'Jan'
  }
  if (dateSchedule === 1) {
    return 'Fev'
  }
  if (dateSchedule === 2) {
    return 'Mar'
  }
  if (dateSchedule === 3) {
    return 'Abr'
  }
  if (dateSchedule === 4) {
    return 'Maio '
  }
  if (dateSchedule === 5) {
    return 'Jun'
  }
  if (dateSchedule === 6) {
    return 'Jul'
  }
  if (dateSchedule === 7) {
    return 'Ago'
  }
  if (dateSchedule === 8) {
    return 'Set'
  }
  if (dateSchedule === 9) {
    return 'Out'
  }
  if (dateSchedule === 10) {
    return 'Nov'
  }
  if (dateSchedule === 11) {
    return 'Dez'
  } else {
    return 'Mês'
  }
}

// Retorna o mês abreviado
export const theMonthAbb = data => {
  if (moment(data, 'YYYY-MM-DD').isValid()) {
    return monthAbb(moment(data, 'YYYY-MM-DD').month()).toUpperCase()
  }
}
