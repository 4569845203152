/* eslint-disable @typescript-eslint/no-unsafe-call */
import { apiCNG } from '../services/api';

export const getBookingConditions = async ({ hotelId, roomId, token }) => {
  try {
    // await setLoadingC(true)
    const res = await apiCNG.post('/Hotel.svc/getBookingConditions', {
      HotelId: hotelId,
      RoomIds: [roomId],
      Token: token,
    });
    if (res.status === 200 && res.data.Token) {
      // await setLoadingC(false)
      // await setDoBookingPolice(res?.data)
    } else {
      return res;
    }
  } catch (err) {
    // await setLoadingC(false)
    // await console.error(err)
  }
};

export const getDoBooking = async ({
  dataForm,
  hotelId,
  roomId,
  token,
  paymentType,
}: {
  dataForm: any,
  hotelId: number,
  roomId: number,
  token: string,
  paymentType: boolean | string,
}) => {
  try {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await localStorage.setItem('timer', false);

    const adultData = await dataForm?.data?.map((item, idx) =>
      idx === 0
        ? {
            ...item,
            Address: item?.Address,
            Age: item?.Age,
            Cpf: item?.DocumentNumber?.replace(/[^a-zA-Z0-9 ]/g, ''),
            MainPax: idx === 0 ? 'true' : false,
            PhoneDDD: item?.Phone?.substr(4, 2),
            PhoneDDI: item?.Phone?.substr(0, 3),
            PhoneNumber: item?.Phone?.substr(7, 17),
            ZipCode: item?.ZipCode?.replace(/[^a-zA-Z0-9 ]/g, ''),
            isChild: 'false',
          }
        : {
            Age: item?.Age,
            Name: item?.Name,
            Surname: item?.Surname,
            MainPax: 'false',
            isChild: 'false',
          },
    );

    const childrenData = await dataForm?.dataC?.map(item => ({
      Name: item?.Name,
      Surname: item?.Surname,
      Age: item?.Age,
      MainPax: 'false',
      isChild: 'true',
    }));

    const unicData = childrenData != undefined ? [...adultData, ...childrenData] : [...adultData];

    const bookJSON = {
      HotelId: hotelId,
      BookingId: '0',
      Rooms: [
        {
          RoomId: roomId,
          Paxs: unicData,
        },
      ],
      Payment: {
        IsBilled: paymentType === 'billed' ? false : true,
      },
      RoomId: roomId,
      Token: token,
    };

    const res = await apiCNG.post('/Hotel.svc/DoBooking', bookJSON);

    if (res?.data?.Rooms[0]?.ReservationId === null) {
      console.log('Instabilidade backend cng');
      throw new Error();
      return { data: { Rooms: [{ ReservationId: null }] } };
    }
    return res;
  } catch (error) {
    console.log(`catch book =>${error}`);
    return { data: { Rooms: [{ ReservationId: null }] } };
  }
};
