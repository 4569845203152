import React, { useState } from 'react'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'

export default function Age({ idx, id, rooms, setRooms }) {
  const { language } = useLanguage()
  const [countAge, setCounAge] = useState(rooms ?? 0)

  return (
    <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" mt="10px" alignItems="center">
        <Text fontWeight="bold" fontSize="16px">
          {translates(language)?.searchbox?.default?.item10}
        </Text>
        <Box margin="10px" display="flex" flexDirection="row">
          <IconButton
            onClick={async () => {
              if (rooms[idx]?.childrenAge[id] > 0) {
                await setCounAge(old => [
                  ...old,
                  {
                    [rooms[idx]?.childrenAge[id]]: rooms[idx]?.childrenAge[id] - 1,
                  },
                ])
                await rooms[idx]?.childrenAge?.splice(id, 1, rooms[idx]?.childrenAge[id] - 1)
                await setRooms(old => [...old])
              }
            }}
            colorScheme="red"
            size="sm"
            aria-label="Remove Children"
            icon={<BiMinus />}
          />
          <Box ml="5px" mr="5px">
            <Text fontWeight="bold" fontSize="20px">
              {countAge[idx]?.childrenAge[id]}
            </Text>
          </Box>
          <IconButton
            onClick={async () => {
              if (rooms[idx]?.childrenAge[id] < 17) {
                await setCounAge(old => [
                  ...old,
                  {
                    [rooms[idx]?.childrenAge[id]]: rooms[idx]?.childrenAge[id] + 1,
                  },
                ])
                // eslint-disable-next-line no-unused-expressions, prettier/prettier
                await rooms[idx]?.childrenAge?.splice(id, 1, rooms[idx]?.childrenAge[id] + 1)
                await setRooms(old => [...old])
              }
            }}
            colorScheme="red"
            size="sm"
            aria-label="Add Children"
            icon={<BiPlus />}
          />
        </Box>
      </Box>
    </Box>
  )
}
