// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import {
  Center,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { AiFillCloseCircle, AiOutlineCheckCircle, AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai'
import { MdOutlineSmsFailed } from 'react-icons/md'
// import { DeleteButton } from '../buttons/DeleteButton';

const AlertModal = ({
  alertMsg,
  msgStatus,
  closeOnOverlayClick = true,
  ButtonOpen,
  buttonOpenStyles = {},
  ConfirmButton,
  useSecondaryActions,
  isOpenSecondary,
  onCloseSecondary,
}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const status = {
    info: {
      color: 'yellow',
      icon: AiOutlineInfoCircle,
    },
    success: {
      color: 'green',
      icon: AiOutlineCheckCircle,
    },
    warning: {
      color: 'orange',
      icon: AiOutlineWarning,
    },
    error: {
      color: 'red',
      icon: MdOutlineSmsFailed,
    },
  }

  return (
    <>
      {ButtonOpen && <ButtonOpen onClick={onOpen} style={buttonOpenStyles} />}

      <Modal
        isCentered
        closeOnEsc={closeOnOverlayClick}
        closeOnOverlayClick={closeOnOverlayClick}
        isOpen={useSecondaryActions ? isOpenSecondary : isOpen}
        onClose={useSecondaryActions ? onCloseSecondary : onClose}
      >
        <ModalOverlay />
        <ModalContent w="100%" borderRadius="20px" boxShadow="0px 10px 20px #000000" justifyContent="space-between">
          <ModalHeader
            borderTopRadius="20px"
            p={!closeOnOverlayClick ? '20px' : '0px'}
            background={status[msgStatus]?.color}
          >
            {closeOnOverlayClick && (
              <IconButton
                onClick={useSecondaryActions ? onCloseSecondary : onClose}
                color="white"
                icon={<AiFillCloseCircle />}
                fontSize="20px"
                mr={2}
                variant="ghost"
                // _hover={{ opacity: 0.5 }}
                aria-label=""
              />
            )}
          </ModalHeader>

          <ModalBody mb={5}>
            <Center my={5}>
              {msgStatus && (
                <Icon as={status[msgStatus]?.icon} color={status[msgStatus]?.color} mr="5px" fontSize="100px" />
              )}
            </Center>
            {alertMsg ?? (
              <Text fontWeight="bold" textAlign="center" fontSize="25px">
                Você tem certeza dessa ação?
              </Text>
            )}
          </ModalBody>

          <ModalFooter mb={5} justifyContent="center">
            {ConfirmButton}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AlertModal
