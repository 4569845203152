/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { HStack, Box, Center, Flex, useToast } from '@chakra-ui/react'
import { useLanguage } from '../../providers/LanguageProvider'
import { useCotation } from '../../providers/CotationProvider'
import { useTimer } from 'react-timer-hook'
import { useSearch } from '../../providers/SearchProvider'
import DrawlerNavBar from './DrawlerNavBar'

export default function NavBarAuth() {
  const toast = useToast()
  const [isTimer, setIsTimer] = useState(localStorage.getItem('timer'))
  const { language } = useLanguage()
  const { resetTimer, setResetTimer } = useSearch()
  const { isCotation } = useCotation()

  useEffect(() => {
    setIsTimer(localStorage.getItem('timer'))
  }, [localStorage.getItem('timer')])

  useEffect(() => {
    if (resetTimer === 'reset') {
      const time = new Date()
      time.setSeconds(time.getSeconds() + 780)
      restart(time)
      setResetTimer('')
    }
    if (window.location.pathname === '/') {
      localStorage.setItem('timer', false)
      setIsTimer(false)
    }
    if (window.location.pathname.includes('panel')) {
      localStorage.setItem('timer', false)
      setIsTimer(false)
    }
  }, [isTimer, resetTimer, window.location.pathname])

  useEffect(() => {
    JSON.parse(localStorage.getItem('language'))
  }, [language])

  useEffect(() => {
    JSON.parse(localStorage.getItem('cotation'))
  }, [isCotation])

  const time = new Date()

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: time.setSeconds(time.getSeconds() + 780),
    onExpire: () => {
      setResetTimer('')
      localStorage.setItem('timer', false)
      setIsTimer(false)
      toast({
        title: 'Seu tempo expirou!',
        description: 'Esta reserva pode ter tido seus preços alterados, talvez será necessário recomeçar todo processo',
        status: 'error',
        duration: 21000,
        isClosable: false,
      })
    },
  })

  return (
    <>
      <DrawlerNavBar />
      {isTimer == 'true' ? (
        <Box
          borderBottomColor={'gray.300'}
          borderTopColor={'gray.300'}
          borderBottomWidth={1}
          borderTopWidth={1}
          bg="white"
        >
          <Center>
            <HStack>
              <Flex w="100%" justify="center" mt="5px" alignItems="center" align="center">
                <div style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  <p style={{ fontSize: '12px' }}>Tempo restante para concluir esta reserva</p>
                  <div style={{ fontSize: '18px' }}>
                    <span>{minutes}</span>:<span>{String(seconds).padStart(2, '0')}</span>
                  </div>
                </div>
              </Flex>
            </HStack>
          </Center>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
