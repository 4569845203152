/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef, useState } from 'react'

import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Heading, Button } from '@chakra-ui/react'
import { VscEmptyWindow } from 'react-icons/vsc'
// import { ReactI18NextChild } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { PaginationType } from '../../hooks/useFetchCPT'
import { Paginator } from '../Paginator'
import { CreateButton } from '../Buttons/CreateButton'
import { EditButton } from '../Buttons/EditButton'
import { ViewButton } from '../Buttons/ViewButton'
import Loader from '../Loader'
import AlertModal from '../AlertModal'
import { DeleteButton } from '../Buttons/DeleteButton'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'

interface RowActionsProps {
  show?: boolean
  edit?: boolean
  delete?: boolean
}

interface FormatColumnProps {
  field: string
  formatField: any
}

interface DataGridProps {
  columns: any
  rows: any
  requestApiCPT: any
  error: any
  isId: any
  baseUrl: string
  data: any
  loading: boolean
  hasCreate?: boolean
  hasCreateUrl?: string | boolean
  deleteAlertMsg?: any
  infoButton?: any[]
  columnWithAction?: any[]
  rowActions?: RowActionsProps
  headerFilters?: any[]
  formatColum?: FormatColumnProps[]
}

export const DataGrid = ({
  columns,
  rows,
  requestApiCPT,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error,
  isId,
  baseUrl,
  data,
  loading,
  hasCreate,
  hasCreateUrl = false,
  deleteAlertMsg,
  infoButton,
  columnWithAction,
  rowActions,
  headerFilters,
  formatColum = [],
}: DataGridProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<PaginationType>({} as PaginationType)
  const [update, setUpdate] = useState(false)
  const [queryParams, setQueryParams] = useState<any>({
    pageNumber: pagination?.current_page ?? 1,
    perPage: 10,
  })

  const handleDeleteItem = async (itemId: string) => {
    await requestApiCPT('delete', null, `${baseUrl.split('?')[0]}/${itemId}`, 'Item excluído com sucesso!')
    setUpdate(old => !old)
    if (!error.msg) {
      setUpdate(old => !old)
      await requestApiCPT('get', { params: queryParams }, `${baseUrl}${location.pathname}`)
    }
  }

  useEffect(() => {
    requestApiCPT('get', { params: queryParams })
  }, [queryParams, update])

  useEffect(() => {
    setPagination(data?.pagination)
  }, [data])

  const tableRef = useRef<HTMLDivElement | null>(null)

  const handleScrollLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollBy({ left: -100, behavior: 'smooth' }) // Ajuste a distância de rolagem conforme necessário
    }
  }

  const handleScrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollBy({ left: 100, behavior: 'smooth' }) // Ajuste a distância de rolagem conforme necessário
    }
  }

  const [showScrollButtons, setShowScrollButtons] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const hasHorizontalScroll = tableRef.current.scrollWidth > tableRef.current.clientWidth
        setShowScrollButtons(hasHorizontalScroll)
      }
    }

    // Adiciona um listener para verificar o scroll
    window.addEventListener('resize', handleScroll)

    // Remove o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  const scrollButtonStyle = {
    height: '100%', // Ocupa a altura da tabela verticalmente
    cursor: 'pointer',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Sombreamento
    transition: 'background-color 0.3s', // Efeito de transição
    backgroundColor: showScrollButtons ? '#eee' : 'transparent', // Cor de fundo condicional
  }

  return (
    <Box>
      <Flex mb={5} align="center">
        <Flex flexWrap="wrap" align="center" w="79%">
          {headerFilters
            ?.filter(filter => filter?.isMain)
            ?.map(filterProps => (
              <Box key={filterProps.name} mr={4} w="79%">
                <filterProps.filterComponent
                  isDisabled={data?.data?.length <= 0 && !queryParams[filterProps.name]?.toString()}
                  onChange={(value: any) => setQueryParams((old: any) => ({ ...old, [filterProps.name]: value }))}
                  onInputChange={filterProps?.onInputChange}
                  isSearchable={filterProps?.isSearchable}
                  setValue={filterProps?.setValue}
                  placeholder={filterProps?.placeholder ?? 'Buscar'}
                  options={filterProps?.options}
                />
              </Box>
            ))}
        </Flex>
      </Flex>

      <Flex
        flexWrap="wrap"
        align="center"
        mb={5}
        flexDirection={['column', 'row']} // Define a direção da flexbox para coluna em telas pequenas
      >
        {headerFilters
          ?.filter(filter => !filter?.isMain)
          ?.map(filterProps => (
            <Box key={filterProps.name} flex={1} mb={[3, 0]} width={['100%', 'auto']}>
              <filterProps.filterComponent
                isDisabled={data?.data?.length <= 0 && !queryParams[filterProps.name]?.toString()}
                onChange={(value: any) => setQueryParams((old: any) => ({ ...old, [filterProps.name]: value }))}
                onInputChange={filterProps?.onInputChange}
                isSearchable={filterProps?.isSearchable}
                setValue={filterProps?.setValue}
                placeholder={filterProps?.placeholder ?? 'Buscar'}
                options={filterProps?.options}
              />
            </Box>
          ))}
        {hasCreate && (
          <Flex align="center" mt={[3, 0]} width={['100%', 'auto']}>
            <CreateButton
              onClick={() =>
                hasCreateUrl
                  ? navigate(`${hasCreateUrl}`, { replace: true })
                  : navigate(`${location.pathname}/create`, { replace: true })
              }
              variant="outline"
              width="100%" // Define a largura como 100% em telas pequenas
            >
              Criar
            </CreateButton>
          </Flex>
        )}
      </Flex>

      {!loading ? (
        <>
          {data?.length > 0 ? (
            <>
              {showScrollButtons !== false ? (
                <Flex mt={5} mb={5} justify="space-between" alignItems="center">
                  <Box>
                    <BiChevronLeft
                      fontSize={25}
                      size={25}
                      cursor="pointer"
                      onClick={handleScrollLeft}
                      style={scrollButtonStyle}
                    />
                  </Box>
                  <TableContainer
                    zIndex={99}
                    ref={tableRef}
                    whiteSpace="break-spaces"
                    background="theme.light.bodyInput"
                    borderRadius="20px"
                    boxShadow="0px 5px 10px #2D3748"
                  >
                    <Table variant="striped" colorScheme="blackAlpha">
                      <Thead>
                        <Tr>
                          {columns?.map((item: any) => (
                            <Th key={item.name}>
                              <Flex align="center">
                                {item.label}
                                {infoButton?.find(infoBut => infoBut.name === item.name)?.button}
                              </Flex>
                            </Th>
                          ))}
                          {(rowActions?.show || rowActions?.show || rowActions?.edit) && <Th>Ações</Th>}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {rows?.map((item: any, index: any) => (
                          <Tr key={isId ? item[`${isId}`] : item.id}>
                            {columns?.map((dataItem: any) => (
                              <Td key={dataItem.name}>
                                {formatColum.length > 0
                                  ? formatColum
                                      .find(value => value.field === dataItem.name)
                                      ?.formatField(_.get(item, dataItem.name), item) ??
                                    columnWithAction?.find(value => value.name === dataItem.name)?.columnAction(item) ??
                                    _.get(item, dataItem.name)
                                  : columnWithAction?.find(value => value.name === dataItem.name)?.columnAction(item) ??
                                    _.get(item, dataItem.name)}
                              </Td>
                            ))}
                            <Td>
                              {(rowActions?.show || rowActions?.show || rowActions?.edit) && (
                                <Flex>
                                  {rowActions?.show && (
                                    <>
                                      <ViewButton
                                        onClick={() => {
                                          navigate(
                                            item?.cng_booking_number
                                              ? `${location.pathname}/${item.cng_booking_number}`
                                              : item?.transaction_id
                                              ? `${location.pathname}/${item.transaction_id}`
                                              : item?.onwership_id
                                              ? `${location.pathname}/${index}`
                                              : `${location.pathname}/${
                                                  isId ? (isId ? item[`${isId}`] : item.id) : item.id
                                                }`,
                                            {
                                              replace: true,
                                              state: { data: data ? data : [] },
                                            },
                                          )
                                        }}
                                      />
                                    </>
                                  )}

                                  {rowActions?.edit && (
                                    <EditButton
                                      onClick={() => {
                                        navigate(`${location.pathname}/${isId ? item[`${isId}`] : item.id}/edit`, {
                                          replace: true,
                                        })
                                      }}
                                    />
                                  )}

                                  {rowActions?.delete && (
                                    <AlertModal
                                      alertMsg={deleteAlertMsg}
                                      msgStatus="warning"
                                      ButtonOpen={DeleteButton}
                                      ConfirmButton={
                                        <Button
                                          w="200px"
                                          boxShadow="0px 5px 10px #2D3748"
                                          borderRadius="full"
                                          onClick={() => handleDeleteItem(isId ? item[`${isId}`] : item.id)}
                                        >
                                          Confirmar exclusão
                                        </Button>
                                      }
                                    />
                                  )}
                                </Flex>
                              )}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Box>
                    <BiChevronRight
                      fontSize={25}
                      size={25}
                      cursor="pointer"
                      onClick={handleScrollRight}
                      style={scrollButtonStyle}
                    />
                  </Box>
                </Flex>
              ) : (
                <TableContainer
                  ref={tableRef}
                  whiteSpace="break-spaces"
                  background="theme.light.bodyInput"
                  borderRadius="20px"
                  boxShadow="0px 5px 10px #2D3748"
                >
                  <Table variant="striped" colorScheme="blackAlpha">
                    <Thead>
                      <Tr>
                        {columns?.map((item: any) => (
                          <Th key={item.name}>
                            <Flex align="center">
                              {item.label}
                              {infoButton?.find(infoBut => infoBut.name === item.name)?.button}
                            </Flex>
                          </Th>
                        ))}
                        {}
                        {(rowActions?.show || rowActions?.show || rowActions?.edit) && <Th>Ações</Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {rows?.map((item: any, index: any) => (
                        <Tr key={isId ? item[`${isId}`] : item.id}>
                          {columns?.map((dataItem: any) => (
                            <Td key={dataItem.name}>
                              {formatColum.length > 0
                                ? formatColum
                                    .find(value => value.field === dataItem.name)
                                    ?.formatField(_.get(item, dataItem.name), item) ??
                                  columnWithAction?.find(value => value.name === dataItem.name)?.columnAction(item) ??
                                  _.get(item, dataItem.name)
                                : columnWithAction?.find(value => value.name === dataItem.name)?.columnAction(item) ??
                                  _.get(item, dataItem.name)}
                            </Td>
                          ))}
                          <Td>
                            <Flex>
                              {rowActions?.show && (
                                <>
                                  <ViewButton
                                    onClick={() => {
                                      navigate(
                                        item?.cng_booking_number
                                          ? `${location.pathname}/${item.cng_booking_number}`
                                          : item?.transaction_id
                                          ? `${location.pathname}/${item.transaction_id}`
                                          : item?.onwership_id
                                          ? `${location.pathname}/${index}`
                                          : `${location.pathname}/${
                                              isId ? (isId ? item[`${isId}`] : item.id) : item.id
                                            }`,
                                        {
                                          replace: true,
                                          state: { data: data ? data : [] },
                                        },
                                      )
                                    }}
                                  />
                                </>
                              )}

                              {rowActions?.edit && (
                                <EditButton
                                  onClick={() => {
                                    navigate(`${location.pathname}/${isId ? item[`${isId}`] : item.id}/edit`, {
                                      replace: true,
                                    })
                                  }}
                                />
                              )}

                              {rowActions?.delete && (
                                <AlertModal
                                  alertMsg={deleteAlertMsg}
                                  msgStatus="warning"
                                  ButtonOpen={DeleteButton}
                                  ConfirmButton={
                                    <Button
                                      w="200px"
                                      boxShadow="0px 5px 10px #2D3748"
                                      borderRadius="full"
                                      onClick={() => handleDeleteItem(isId ? item[`${isId}`] : item.id)}
                                    >
                                      Confirmar exclusão
                                    </Button>
                                  }
                                />
                              )}
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}

              <Paginator pagination={pagination} queryParams={queryParams} setQueryParams={setQueryParams} />
            </>
          ) : (
            <Flex direction="column" align="center" justify="center">
              <VscEmptyWindow size="15em" opacity={0.5} />
              <Heading as="h1" size="lg" mt={7} textAlign="center">
                Não há dados a serem exibidos
              </Heading>
            </Flex>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  )
}
