/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { BiLeftArrow, BiSave } from 'react-icons/bi';
import { useFetchCPT } from '../../../hooks/useFetchCPT';

interface FormProps {
  defaultValues?: any;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string;
  yup: any;
}

export default function EnterprisesForm({ defaultValues = {} }: FormProps) {
  const baseUrl = 'v1/enterprises';
  const { requestApiCPT, error } = useFetchCPT(baseUrl);
  const { onOpen } = useDisclosure();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const images = '';
  const [uploading, setUploading] = useState<any>(false);
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const toast = useToast();

  const formInputs: FormInputsProps[] = [
    {
      name: 'enterprise_name',
      value: defaultValues.enterprise_name ?? '',
      placeholder: '',
      label: 'Nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_description',
      value: defaultValues.enterprise_description ?? '',
      placeholder: '',
      label: 'Descrição',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_pax',
      value: defaultValues?.enterprise_pax ?? '',
      placeholder: '',
      label: 'PAX',
      yup: Yup.string(),
    },
    {
      name: 'enterprise_status',
      value: defaultValues?.enterprise_status ?? 1,
      placeholder: '',
      label: 'Ativo?',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'enterprise_cnpj',
      value: defaultValues.enterprise_cnpj ?? '',
      placeholder: '',
      label: 'EX:. 00.000.000/0000-00',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          'CNPJ inválido',
        ),
    },
    {
      name: 'enterprise_order',
      value: defaultValues.enterprise_order ?? '999',
      placeholder: '',
      label: 'Ordem',
      yup: Yup.string().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'enterprise_address',
      value: defaultValues.enterprise_address ?? '',
      placeholder: '',
      label: 'Endereço',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_zipcode',
      value: defaultValues.enterprise_zipcode ?? '',
      placeholder: '',
      label: 'CEP',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/(\d{2}[.]?\d{3})[-]?(\d{3})/, 'CEP inválido'),
    },
    {
      name: 'enterprise_address_complement',
      value: defaultValues.enterprise_address_complement ?? '',
      placeholder: '',
      label: 'Complemento',
      yup: Yup.string(),
    },
    {
      name: 'enterprise_address_number',
      value: defaultValues.enterprise_address_number ?? '',
      placeholder: '',
      label: 'Numero',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'enterprise_address_neighborhood',
      value: defaultValues.enterprise_address_neighborhood ?? '',
      placeholder: '',
      label: 'Bairro',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_city',
      value: defaultValues.enterprise_address_city ?? '',
      placeholder: '',
      label: 'Cidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_state',
      value: defaultValues.enterprise_address_state ?? '',
      placeholder: '',
      label: 'Estado',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_country',
      value: defaultValues.enterprise_address_country ?? '',
      placeholder: '',
      label: 'País',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_longitude',
      value: defaultValues.enterprise_address_longitude ?? '',
      placeholder: '',
      label: 'Longitude',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_latitude',
      value: defaultValues.enterprise_address_latitude ?? '',
      placeholder: '',
      label: 'Latitude',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    reset,
    // watch,
    // control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const enterprise_name = formInputs.find(field => field.name === 'enterprise_name');
  const enterprise_cnpj = formInputs.find(field => field.name === 'enterprise_cnpj');
  const enterprise_description = formInputs.find(field => field.name === 'enterprise_description');
  const enterprise_pax = formInputs.find(field => field.name === 'enterprise_pax');
  const enterprise_zipcode = formInputs.find(field => field.name === 'enterprise_zipcode');
  const enterprise_address = formInputs.find(field => field.name === 'enterprise_address');
  const enterprise_address_complement = formInputs.find(field => field.name === 'enterprise_address_complement');
  const enterprise_address_neighborhood = formInputs.find(field => field.name === 'enterprise_address_neighborhood');
  const enterprise_address_country = formInputs.find(field => field.name === 'enterprise_address_country');
  const enterprise_address_number = formInputs.find(field => field.name === 'enterprise_address_number');
  const enterprise_address_city = formInputs.find(field => field.name === 'enterprise_address_city');
  const enterprise_address_state = formInputs.find(field => field.name === 'enterprise_address_state');
  const enterprise_address_longitude = formInputs.find(field => field.name === 'enterprise_address_longitude');
  const enterprise_address_latitude = formInputs.find(field => field.name === 'enterprise_address_latitude');
  const enterprise_status = formInputs.find(field => field.name === 'enterprise_status');
  const enterprise_order = formInputs.find(field => field.name === 'enterprise_order');

  const onSubmit = async (values: any) => {
    const response = await requestApiCPT(
      defaultValues.enterprise_name ? 'put' : 'post',
      { ...values, image: _ },
      defaultValues.enterprise_name ? `${baseUrl}/${itemId}` : null,
    );

    if (response.status < 299) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      if (!error && images) {
        setUploading(true);
        const apiUrlId = itemId ?? response?.data?.enterprise_id;
        await requestApiCPT('put', { file: images }, `${baseUrl}/${apiUrlId}/image`);
        setUploading(false);
        onOpen();
      } else {
        onOpen();
      }
      navigate('/enterprises');
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (defaultValues.enterprise_name) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);

  const dataActive = [
    { id: 0, name: 'Inativo' },
    { id: 1, name: 'Ativo' },
  ];
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          {/* <Text mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
            Foto
          </Text>
          <Image w="250px" mb={7} src="/assets/noImg.png" />
          <Box display="flex" flexDir="row" mb="20px">
            <Button colorScheme="red" mr="20px" rightIcon={<BiTrash />} variant="outline">
              Excluir foto
            </Button>
            <Button colorScheme="red" rightIcon={<BiUpload />} variant="outline">
              Upload de foto
            </Button>
          </Box> */}
          <Flex flexDir="column" mb="40px">
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações básicas da Propriedade
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_name && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Nome
                  </FormLabel>
                  <Input
                    id="enterprise_name"
                    placeholder={enterprise_name?.label}
                    isRequired
                    {...register('enterprise_name', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_name?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_description && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Descrição
                  </FormLabel>
                  <Input
                    id="enterprise_description"
                    placeholder={enterprise_description?.label}
                    isRequired
                    {...register('enterprise_description', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_description?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_cnpj && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CNPJ
                  </FormLabel>
                  <Input
                    id="enterprise_cnpj"
                    placeholder={enterprise_cnpj?.label}
                    isRequired
                    {...register('enterprise_cnpj', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_cnpj?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_status && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {enterprise_status?.label}
                  </FormLabel>
                  <Select isRequired {...register('enterprise_status', errors as any)}>
                    {dataActive.map((group, index) => (
                      <option key={index} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.enterprise_status?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_pax && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    PAX
                  </FormLabel>
                  <Input
                    id="enterprise_pax"
                    placeholder={enterprise_pax?.label}
                    isRequired
                    {...register('enterprise_pax', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_pax?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_order && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Ordem
                  </FormLabel>
                  <Input
                    id="enterprise_pax"
                    placeholder={enterprise_order?.label}
                    isRequired
                    {...register('enterprise_order', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_order?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações de endereço
            </Text>
            <SimpleGrid columns={4} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Endereço
                  </FormLabel>
                  <Input
                    id="enterprise_address"
                    placeholder={enterprise_address?.label}
                    isRequired
                    {...register('enterprise_address', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address_complement && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Complemento
                  </FormLabel>
                  <Input
                    id="enterprise_address_complement"
                    placeholder={enterprise_address_complement?.label}
                    isRequired
                    {...register('enterprise_address_complement', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_complement?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address_neighborhood && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Bairro
                  </FormLabel>
                  <Input
                    id="enterprise_address_neighborhood"
                    placeholder={enterprise_address_neighborhood?.label}
                    isRequired
                    {...register('enterprise_address_neighborhood', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_neighborhood?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_zipcode && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CEP
                  </FormLabel>
                  <Input
                    id="enterprise_zipcode"
                    placeholder={enterprise_zipcode?.label}
                    isRequired
                    {...register('enterprise_zipcode', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_zipcode?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_number && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Numero
                  </FormLabel>
                  <Input
                    id="enterprise_address_number"
                    placeholder={enterprise_address_number?.label}
                    isRequired
                    {...register('enterprise_address_number', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_number?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_city && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Cidade
                  </FormLabel>
                  <Input
                    id="enterprise_address_city"
                    placeholder={enterprise_address_city?.label}
                    isRequired
                    {...register('enterprise_address_city', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_city?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_state && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Estado
                  </FormLabel>
                  <Input
                    id="enterprise_address_state"
                    placeholder={enterprise_address_state?.label}
                    isRequired
                    {...register('enterprise_address_state', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_state?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_country && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    País
                  </FormLabel>
                  <Input
                    id="enterprise_address_country"
                    placeholder={enterprise_address_country?.label}
                    isRequired
                    {...register('enterprise_address_country', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_country?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_latitude && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Latitude
                  </FormLabel>
                  <Input
                    id="enterprise_address_latitude"
                    placeholder={enterprise_address_latitude?.label}
                    isRequired
                    {...register('enterprise_address_latitude', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_latitude?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_longitude && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Longitude
                  </FormLabel>
                  <Input
                    id="enterprise_address_longitude"
                    placeholder={enterprise_address_longitude?.label}
                    isRequired
                    {...register('enterprise_address_longitude', errors as any)}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_longitude?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/enterprises')}
            leftIcon={<BiLeftArrow />}
            colorScheme="gray"
            variant="outline"
          >
            Cancelar e voltar
          </Button>
          <Button
            isLoading={isSubmitting || uploading}
            type="submit"
            rightIcon={<BiSave />}
            colorScheme="gray"
            variant="outline"
          >
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  );
}
