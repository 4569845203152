/* eslint-disable consistent-return */
import moment from 'moment'
import { BiCurrentLocation, BiHotel, BiLocationPlus, BiMap, BiTrain } from 'react-icons/bi'
import { BsFillHouseDoorFill } from 'react-icons/bs'
import { FaBus, FaCity } from 'react-icons/fa'
import { GiModernCity } from 'react-icons/gi'
import { MdLocalAirport } from 'react-icons/md'

export const calcHops = rooms => {
  const rm1 = rooms[0]?.numberOfAdults
  const rm2 = typeof rooms[1]?.numberOfAdults == 'undefined' ? 0 : rooms[1]?.numberOfAdults
  const rm3 = typeof rooms[2]?.numberOfAdults == 'undefined' ? 0 : rooms[2]?.numberOfAdults
  const ct1 = typeof rooms[0]?.childrenAge?.length == 'undefined' ? 0 : rooms[0]?.childrenAge?.length
  const ct2 = typeof rooms[1]?.childrenAge?.length == 'undefined' ? 0 : rooms[1]?.childrenAge?.length
  const ct3 = typeof rooms[2]?.childrenAge?.length == 'undefined' ? 0 : rooms[2]?.childrenAge?.length
  return Math.round(rm1) + Math.round(rm2) + Math.round(rm3) + Math.round(ct1) + Math.round(ct2) + Math.round(ct3)
}

function checkIsAfter(date1, date2, today, yesterday) {
  if (moment(date1).isAfter(yesterday) && moment(date2).isAfter(today)) {
    return true
  } else {
    return false
  }
}

export const calcDate = selectedDates => {
  const format = 'YYYY/MM/DD'
  const day1 = selectedDates && moment(selectedDates[0])?.format(format)
  const day2 = selectedDates && moment(selectedDates[1])?.format(format)
  const diff = moment(day2, format)?.diff(moment(day1, format))
  const dias = moment?.duration(diff)?.asDays()
  const today = moment()?.format(format)
  const yesterday = moment()?.subtract(1, 'day')?.format(format)
  const isValid = checkIsAfter(day1, day2, today, yesterday)
  return isValid ? dias : -1
}

export const typeDestination = hotel => {
  if (hotel) {
    if (hotel?.type == 'Multi-Region (within a country)') {
      return (
        <BiMap
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Airport') {
      return (
        <MdLocalAirport
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Multi-City (Vicinity)') {
      return (
        <GiModernCity
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Train') {
      return (
        <BiTrain
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'BusStation') {
      return (
        <FaBus
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Hotel') {
      return (
        <BiHotel
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'City') {
      return (
        <FaCity
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Neighborhood') {
      return (
        <BsFillHouseDoorFill
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Point of Interest') {
      return (
        <BiLocationPlus
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    if (hotel?.type == 'Point of Interest Shadow') {
      return (
        <BiCurrentLocation
          style={{
            marginRight: 8,
          }}
        />
      )
    }
    return (
      <BiMap
        style={{
          marginRight: 8,
        }}
      />
    )
  }
}

export const typeDstn = hotel => {
  if (hotel) {
    if (hotel == 'Multi-Region (within a country)') {
      return 'Multi-região (dentro de um país)'
    }
    if (hotel == 'Airport') {
      return 'Aeroporto'
    }
    if (hotel == 'Multi-City (Vicinity)') {
      return 'Multi-Cidade (Proximidades)'
    }
    if (hotel == 'Train') {
      return 'Trem'
    }
    if (hotel == 'BusStation') {
      return 'Estação de ônibus'
    }
    if (hotel == 'Hotel') {
      return 'Hotel'
    }
    if (hotel == 'City') {
      return 'Cidade'
    }
    if (hotel == 'Neighborhood') {
      return 'Vizinhança'
    }
    if (hotel == 'Point of Interest') {
      return 'Ponto de interesse'
    }
    if (hotel == 'Country') {
      return 'País'
    }
    if (hotel == 'Point of Interest Shadow') {
      return 'Ponto de interesse sombra'
    }
    return hotel
  }
}
export const typeDstnPT = hotel => {
  if (hotel) {
    if (hotel == 'Multi-Region (within a country)') {
      return 'Multi-região (dentro de um país)'
    }
    if (hotel == 'Airport') {
      return 'Aeroporto'
    }
    if (hotel == 'Multi-City (Vicinity)') {
      return 'Multi-Cidade (Proximidades)'
    }
    if (hotel == 'Train') {
      return 'Trem'
    }
    if (hotel == 'BusStation') {
      return 'Estação de ônibus'
    }
    if (hotel == 'Hotel') {
      return 'Hotel'
    }
    if (hotel == 'City') {
      return 'Cidade'
    }
    if (hotel == 'Neighborhood') {
      return 'Vizinhança'
    }
    if (hotel == 'Point of Interest') {
      return 'Ponto de interesse'
    }
    if (hotel == 'Country') {
      return 'País'
    }
    if (hotel == 'Point of Interest Shadow') {
      return 'Ponto de interesse sombra'
    }
    return hotel
  }
}
export const typeDstnEN = hotel => {
  if (hotel) {
    if (hotel == 'Multi-Region (within a country)') {
      return 'Multi-Region (within a country)'
    }
    if (hotel == 'Airport') {
      return 'Airport'
    }
    if (hotel == 'Multi-City (Vicinity)') {
      return 'Multi-City (Vicinity)'
    }
    if (hotel == 'Train') {
      return 'Train'
    }
    if (hotel == 'BusStation') {
      return 'Bus Station'
    }
    if (hotel == 'Hotel') {
      return 'Hotel'
    }
    if (hotel == 'City') {
      return 'City'
    }
    if (hotel == 'Neighborhood') {
      return 'Neighborhood'
    }
    if (hotel == 'Point of Interest') {
      return 'Point of Interest'
    }
    if (hotel == 'Country') {
      return 'Country'
    }
    if (hotel == 'Point of Interest Shadow') {
      return 'Point of Interest Shadow'
    }
    return hotel
  }
}
export const typeDstnES = hotel => {
  if (hotel) {
    if (hotel == 'Multi-Region (within a country)') {
      return 'Multi-Región (dentro de un país)'
    }
    if (hotel == 'Airport') {
      return 'Aeropuerto'
    }
    if (hotel == 'Multi-City (Vicinity)') {
      return 'Multi-Ciudad (Alrededores)'
    }
    if (hotel == 'Train') {
      return 'Tren'
    }
    if (hotel == 'BusStation') {
      return 'Estación de autobuses'
    }
    if (hotel == 'Hotel') {
      return 'Hotel'
    }
    if (hotel == 'City') {
      return 'Ciudad'
    }
    if (hotel == 'Neighborhood') {
      return 'Vecindario'
    }
    if (hotel == 'Point of Interest') {
      return 'Punto de interés'
    }
    if (hotel == 'Country') {
      return 'País'
    }
    if (hotel == 'Point of Interest Shadow') {
      return 'Sombra de punto de interés'
    }
    return hotel
  }
}

export const selectTypeDstn = (language, hotel) => {
  if (language == 'pt-BR') {
    return typeDstnPT(hotel)
  }
  if (language == 'en') {
    return typeDstnEN(hotel)
  }
  if (language == 'es') {
    return typeDstnES(hotel)
  }
  return typeDstnPT(hotel)
}

export const filterRooms = sR =>
  sR?.map(item => ({
    NumAdults: item?.numberOfAdults[0]?.toString(),
    ChildAges: item?.childrenAge,
    Quantity: item?.quantity?.toString(),
  }))
