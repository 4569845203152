/* eslint-disable no-nested-ternary */
import React from 'react'
import {
  Box,
  Grid,
  Text,
  CircularProgress,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Menu,
  Select,
  Center,
  Stack,
} from '@chakra-ui/react'
import { BiChevronDown } from 'react-icons/bi'
import { rWidthS } from '../../utils/responsiveSizes'
import TabFilter from '../../components/TabFilter'
import Filters from '../../components/Filters'
import PackageCardH from '../../components/PackageCardH'
import { useSearch } from '../../providers/SearchProvider'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from '@ajna/pagination'

export default function Search() {
  const { hotels, search, loading, filterPrice, setFilterPrice } = useSearch()
  const { language } = useLanguage()

  // constants
  const outerLimit = 2
  const innerLimit = 2

  const { pages, pagesCount, currentPage, setCurrentPage, pageSize, setPageSize } = usePagination({
    total: hotels?.Hotels?.length ?? 0,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 50,
      isDisabled: false,
      currentPage: 1,
    },
  })

  // handlers
  const handlePageChange = (nextPage: number): void => {
    // -> request new data using the page number
    setCurrentPage(nextPage)
    // console.log('request new data with ->', nextPage)
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const pageSize = Number(event.target.value)
    setPageSize(pageSize)
  }

  const initialIndexItem = pageSize * currentPage - pageSize
  const lastIndexItem = pageSize * currentPage

  return (
    <Box align="center" w="100%">
      <Box align="center" display="flex" flexDir={['column', 'column']} w={rWidthS}>
        <TabFilter />
        <Box align="center" display="flex" flexDir={['column', 'row']} w={rWidthS}>
          <Filters hotels={hotels ?? []} />
          {hotels !== null && (
            <Box align="center" w="100%" pl={[0, '25px']} pr={[0, '25px']}>
              {(hotels && hotels?.TotalHotelResults > 0) ||
                (hotels.Hotels && (
                  <Box display="flex" flexDir="row" align="center" justifyContent="space-between">
                    <Text width="650px" fontSize={['17px', '22px']} fontWeight="bolder">
                      {search !== '' &&
                        !loading &&
                        `${search}: ${hotels.Hotels.length > 0 && hotels?.Hotels?.length} ${
                          translates(language)?.search?.item3
                        }`}
                    </Text>
                    <Box display="flex" flexDir={['column', 'row']} alignItems="center">
                      <Text verticalAlign="center" fontSize={['16px']} mr="10px" fontWeight="bolder">
                        {translates(language)?.search?.item4}
                      </Text>
                      <Menu>
                        <MenuButton
                          h="25px"
                          color="white"
                          style={{ backgroundColor: '#000' }}
                          as={Button}
                          rightIcon={<BiChevronDown />}
                        >
                          {filterPrice != null && filterPrice === 'asc'
                            ? `${translates(language)?.search?.item5}`
                            : `${translates(language)?.search?.item6}`}
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => setFilterPrice('asc')}>
                            {translates(language)?.search?.item5}
                          </MenuItem>
                          <MenuItem onClick={() => setFilterPrice('desc')}>
                            {translates(language)?.search?.item6}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Box>
                ))}
              {hotels?.TotalHotelResults > 0 && (
                <Box display="flex" flexDir="row" align="center" justifyContent="space-between">
                  <Text fontSize={['17px', '22px']} fontWeight="bolder">
                    {search !== '' &&
                      !loading &&
                      `${search}: ${hotels?.Hotels.length > 0 && hotels?.TotalHotelResults} ${
                        translates(language)?.search?.item3
                      }`}
                  </Text>
                  <Box display="flex" visibility={['hidden', 'visible']} alignItems="center" flexDir="row">
                    <Text verticalAlign="center" fontSize={['16px']} mr="10px" fontWeight="bolder">
                      {translates(language)?.search?.item4}
                    </Text>
                    <Menu>
                      <MenuButton h="25px" colorScheme="red" as={Button} rightIcon={<BiChevronDown />}>
                        {filterPrice != null && filterPrice === 'asc'
                          ? `${translates(language)?.search?.item5}`
                          : `${translates(language)?.search?.item6}`}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => setFilterPrice('asc')}>{translates(language)?.search?.item5}</MenuItem>
                        <MenuItem onClick={() => setFilterPrice('desc')}>
                          {translates(language)?.search?.item6}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
              )}
              <Box align="center" w={['100%', '100%']} mt={['15px', '25px']}>
                {(hotels?.Hotels?.length === 0 || hotels?.Hotels === null) && !loading ? (
                  <Text>{translates(language)?.search?.item2}</Text>
                ) : loading ? (
                  <CircularProgress
                    mt="150px"
                    mb={['150px', '150px', '0px', '0px']}
                    isIndeterminate
                    color="red"
                    thickness="12px"
                  />
                ) : (
                  // <Box height="880px" overflowY="scroll">
                  <Box minH="880px">
                    <Grid mb="30px" templateColumns="repeat(1, 1fr)" gap={6}>
                      {hotels?.Hotels?.filter(data => data?.IsRecommended)?.map((item, idx) => (
                        <PackageCardH
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${item?.HotelId}${idx}-isRecommended`}
                          itemId={item?.HotelId ?? ''}
                          data={item ?? []}
                          percentage={item?.percentage}
                          diaryDays={item?.diaryDays}
                          allIncluse={item?.allIncluse}
                          points={item?.points}
                          img={
                            item?.ThumbUrl ??
                            'https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1630/no-image.png'
                          }
                        />
                      ))}
                    </Grid>
                    <Grid mb="30px" templateColumns="repeat(1, 1fr)" gap={6}>
                      {hotels?.Hotels?.filter(data => !data?.IsRecommended)?.map(
                        (item, idx) =>
                          (currentPage > 1 ? idx + 1 : idx) >= initialIndexItem &&
                          idx <= lastIndexItem && (
                            <>
                              <PackageCardH
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${item?.HotelId}${idx}-notRecommended`}
                                itemId={item?.HotelId ?? ''}
                                data={item ?? []}
                                percentage={item?.percentage}
                                diaryDays={item?.diaryDays}
                                allIncluse={item?.allIncluse}
                                points={item?.points}
                                img={
                                  item?.ThumbUrl ??
                                  'https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1630/no-image.png'
                                }
                              />
                            </>
                          ),
                      )}
                    </Grid>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Stack>
          <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={handlePageChange}>
            <PaginationContainer align="center" justify="space-between" p={4} w="full">
              <Center w="full">
                <Text display={['none', 'none', 'flex', 'flex']}>Quantidade por página:</Text>
                <Text display={['flex', 'flex', 'none', 'none']}>por página:</Text>
                <Select ml={3} onChange={handlePageSizeChange} w={40}>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </Select>
              </Center>
              <PaginationPrevious
                _hover={{
                  bg: 'black.400',
                }}
                bg="black"
                // onClick={() => {}}
              >
                <Text display={['none', 'none', 'flex', 'flex']} color="white" fontWeight="black">
                  Anter.
                </Text>
                <Text display={['flex', 'flex', 'none', 'none']} color="white" fontWeight="black">
                  {'<'}
                </Text>
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                m="2"
                color="white"
                align="center"
                separator={
                  <PaginationSeparator
                    // onClick={() => {}}
                    bg="gray.300"
                    fontSize="sm"
                    w={7}
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w={7}
                    bg="balck"
                    key={`pagination_page_${page}`}
                    page={page}
                    // onClick={() => {}}
                    fontSize="sm"
                    _hover={{
                      bg: 'black.500',
                    }}
                    _current={{
                      bg: 'black',
                      fontSize: 'sm',
                      w: 7,
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: 'black.500',
                }}
                bg="black"
                // onClick={() => {}}
              >
                <Text display={['none', 'none', 'flex', 'flex']} color="white" fontWeight="black">
                  Próx.
                </Text>
                <Text display={['flex', 'flex', 'none', 'none']} color="white" fontWeight="black">
                  {'>'}
                </Text>
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Stack>
      </Box>
    </Box>
  )
}
