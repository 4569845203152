import axios from 'axios'
import React, { createContext, useState, useEffect, useContext } from 'react'
import { apiCPT } from '../services/api'

export const CotationContext = createContext(null)

export function CotationProvider({ children }) {
  const [cotation, setCotation] = useState([])
  const [isCotation, setIsCotation] = useState(JSON.parse(localStorage?.getItem('cotation')))
  const [points, setPoints] = useState([])
  const [markupC, setMarkupC] = useState(1)

  const getCotation = async () => {
    try {
      const resC = await apiCPT.get('/v1/prices_markup')
      await setPoints(resC?.data?.markups[0]?.CC_cotation)
      await setMarkupC(resC?.data?.markups[0]?.markup_currency)
      const res = await axios.get('https://economia.awesomeapi.com.br/json/last/USD-BRL')
      await setCotation(res?.data?.USDBRL?.bid)
    } catch (err) {
      await console.error(err)
    }
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem('cotation')) == null ||
      JSON.parse(localStorage?.getItem('cotation')) == undefined
    ) {
      localStorage.setItem('cotation', JSON.stringify('BRL'))
      // window.location.reload()
    }
  }, [])

  useEffect(() => {
    getCotation()
  }, [cotation == [], points == []])

  const value = React.useMemo(
    () => ({
      cotation,
      setCotation,
      isCotation,
      setIsCotation,
      points,
      setPoints,
      markupC,
    }),
    [cotation, setCotation, isCotation, setIsCotation, points, setPoints, markupC],
  )

  return <CotationContext.Provider value={value}>{children}</CotationContext.Provider>
}

export function useCotation() {
  const context = useContext(CotationContext)
  if (!context) {
    throw new Error('The context The Perfil must be within a valid provider')
  }
  return context
}
