/* eslint-disable */
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Box, Button, Flex, Heading, Input, List, ListItem, Text, useToast } from '@chakra-ui/react';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { useNavigate } from 'react-router-dom';
import IEnterprises from '../../interfaces/IEnterprises';
import ConfirmModal from '../../components/ConfirmModal';
import ReactTable from '../../components/ReactTable';
import moment from 'moment';
import { apiCPT } from '../../services/api';
import { BiFilter } from 'react-icons/bi';

const ExchangesAdm = () => {
  const baseUrl = '/exchanges';
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IEnterprises[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState<IEnterprises[]>([]);

  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const toast = useToast();

  const [onwership_id, setOnwership_id] = useState('');
  const [enterprise_name, setEnterprise_name] = useState('');
  const [dataV, setDataV] = useState<string>('');
  const [user_firstname, setUser_firstname] = useState('');
  const [uh, setUh] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<IEnterprises[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    getData();
  }, [pageNumber, perPage]);

  const getData = async () => {
    try {
      const getData = await apiCPT.post(`v1/filter/ownership?pageNumber=${pageNumber}&perPage=${perPage}`, {
        onwership_id: onwership_id,
        enterprise_name: enterprise_name,
        user_firstname: user_firstname,
        start_at: dataV.length === 10 ? moment(dataV, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      });

      if (getData.status === 200) {
        setData(getData.data.ownership);
        setPageSize(getData.data.paginateCount);
        setTotal(getData.data.count);

        // Armazena as opções de hotéis disponíveis
        const uniqueEnterprises = [
          // @ts-ignore @typescript-eslint/ban-ts-comment
          ...new Map(
            getData.data.ownership.map((item: any) => [item.enterprise.enterprise_name, item.enterprise]),
          ).values(),
        ];
        setEnterpriseOptions(uniqueEnterprises);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = async () => {
    try {
      setData([]);
      const getData = await apiCPT.post(`v1/exchanges/filter?pageNumber=${pageNumber}&perPage=${perPage}`, {
        id: onwership_id,
        hotel: enterprise_name,
        start_at: dataV.length === 10 ? moment(dataV, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        uh,
      });

      if (getData.status === 200) {
        setData(getData.data.users);
        setPageSize(getData.data.users);
        setTotal(getData.data.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRegister = async (id: number) => {
    try {
      const deleteData = await apiCPT.delete(`v1/ownership/${id}`);

      if (deleteData.status === 200) {
        getData();
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        });
      } else {
        toast({
          title: `Ocorreu um erro ao executar a ação! ${deleteData.data.message}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro ao executar a ação! ${error!.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      console.log(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEnterprise_name(value);
    if (value.length > 0) {
      const filtered = enterpriseOptions.filter(option =>
        option.enterprise_name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredOptions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleOptionClick = (option: string) => {
    setEnterprise_name(option);
    setShowSuggestions(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'Hotel',
            accessor: 'enterprise_name',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.enterprise.enterprise_name}`}</Text>;
            },
          },
          {
            Header: 'Usuário',
            accessor: 'user_firstname',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.users.user_firstname} ${row.original.users.user_lastname}`}</Text>;
            },
          },
          {
            Header: 'UH',
            accessor: 'room_type',
            Cell: ({ row }: any) => {
              return (
                <Text>{`${row.original.habitational_unity.room_type} ${row.original.habitational_unity.pax} acomodação(ões)`}</Text>
              );
            },
          },
          {
            Header: 'Iden',
            accessor: 'which_one_hu',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.which_one_hu}`}</Text>;
            },
          },
          {
            Header: 'Data Inicio',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.start_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>;
            },
          },
          {
            Header: 'Data Fim',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.end_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>;
            },
          },
          {
            Header: 'Aprovação Empresa',
            Cell: ({ row }: any) => {
              const {
                ownership_id,
                user_id,
                enterprise_id,
                which_one_hu,
                habitational_unity_id,
                user_review_cpt,
                user_review_enterprise,
                start_at,
                end_at,
              } = row.original;

              // Estado para controle de loading
              const [loading, setLoading] = useState(false);

              // Recupera os dados do usuário logado
              const storedUserData = localStorage.getItem('__user_data');
              const userData = storedUserData ? JSON.parse(storedUserData) : null; // Parse os dados armazenados
              const loggedInUserId = userData?.id; // Supondo que o ID do usuário esteja em 'id'

              // Função para lidar com a aprovação (fazer o update)
              const handleApproval = async () => {
                if (!loggedInUserId) {
                  alert('Erro: ID do usuário logado não encontrado.');
                  return;
                }

                setLoading(true); // Ativa o estado de carregamento

                const payload = {
                  onwership_id: ownership_id,
                  user_id: user_id,
                  enterprise_id: enterprise_id,
                  which_one_hu: which_one_hu,
                  habitational_unity_id: habitational_unity_id,
                  user_review_cpt: user_review_cpt,
                  user_review_enterprise: loggedInUserId, // Aqui vai o ID do usuário logado
                  start_at: start_at,
                  end_at: end_at,
                };

                try {
                  const response = await fetch(`/v1/ownership/${ownership_id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  });

                  if (!response.ok) {
                    throw new Error('Erro na aprovação');
                  }

                  alert('Aprovação realizada com sucesso!');
                } catch (error) {
                  console.error(error);
                  alert('Falha na aprovação');
                } finally {
                  setLoading(false); // Desativa o estado de carregamento
                }
              };

              if (user_review_enterprise === null) {
                return <Text>Não depositada / Aguardando</Text>;
              } else if (user_review_enterprise === 0) {
                return (
                  <Box display="flex" alignItems="center" gap={3}>
                    <Text>Aguardando aprovação</Text>
                    <Button
                      onClick={handleApproval}
                      colorScheme="green"
                      size="sm"
                      isLoading={loading}
                      loadingText="Aprovando..."
                      spinnerPlacement="end"
                      borderRadius="md"
                      boxShadow="md"
                      _hover={{ bg: 'green.500' }}
                      _active={{ bg: 'green.600' }}
                    >
                      Aprovar
                    </Button>
                  </Box>
                );
              } else if (user_review_enterprise !== 0 && user_review_enterprise !== null) {
                return <Text>Aprovado</Text>;
              } else {
                return <Text>Desconhecido</Text>;
              }
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Intercâmbios
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" mb="2">
                Nome do Hotel
              </Text>
              <Input placeholder="Digite o nome do Hotel" value={enterprise_name} onChange={handleInputChange} />
              {showSuggestions && (
                <List spacing={2} mt={2} borderRadius="md" boxShadow="md" bg="white" zIndex={1000} position="absolute">
                  {filteredOptions.map(option => (
                    <ListItem
                      key={option.enterprise_name}
                      onClick={() => handleOptionClick(option.enterprise_name)}
                      cursor="pointer"
                      p={2}
                      _hover={{ bg: 'gray.100' }}
                    >
                      {option.enterprise_name}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>

            <Box width="10vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" mb="2">
                Data do Intercâmbio
              </Text>
              <Input
                as={InputMask}
                mask="99/99/9999"
                placeholder="Data"
                value={dataV}
                onChange={e => setDataV(e.target.value)}
              />
            </Box>

            <Box width="10vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" mb="2">
                Nome do Usuário
              </Text>
              <Input
                placeholder="Digite o Nome do Usuário"
                value={user_firstname}
                onChange={e => setUser_firstname(e.target.value)}
              />
            </Box>

            <Box width="10vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" mb="2">
                UH
              </Text>
              <Input placeholder="Digite o nome da UH" value={uh} onChange={e => setUh(e.target.value)} />
            </Box>
          </Box>
        </Flex>

        <Flex flexDir="row" mb="20px">
          <Box mt="20px" mr="20px" display="flex" flexDir="row">
            <Box display="flex" alignContent="end" alignItems="end">
              <Button leftIcon={<BiFilter />} onClick={getData} colorScheme="red" variant="solid">
                Filtrar
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de intercâmbios
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e);
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.sPageSize);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ExchangesAdm;
