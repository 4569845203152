import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Loader from '../../components/Loader'
import NotData from '../../components/NotData'
import { useFetchCPT } from '../../hooks/useFetchCPT'

const OwnershipDetails = () => {
  const { id } = useParams()
  const [userData, setUserData] = useState<any>([])
  const [pageNumber] = useState(1)
  const [perPage] = useState(10)
  const [ownership, setOwnership] = useState<any>(null)
  const baseUrl = `v1/ownership/${userData.user_id}?pageNumber=${pageNumber}&perPage=${perPage}`
  const { requestApiCPT, data, loading } = useFetchCPT(`${baseUrl}`)
  const idx = id

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      const parsedUserData = JSON.parse(localStorage.getItem('__user_data') || '')
      if (parsedUserData.user_id) {
        setUserData(parsedUserData)
      } else {
        // Adicione alguma lógica para lidar com o caso em que user_id não está definido
        console.error('user_id não está definido.')
      }
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await requestApiCPT('get', null, `${baseUrl}`)
      setOwnership(result && result.data.ownership && result.data.ownership[idx ?? 0])
    }
    if (userData.user_id) {
      fetchData()
    }
  }, [id, baseUrl, userData.user_id, idx])

  if (loading) {
    return <Loader />
  }

  if (!data) {
    return <NotData />
  }

  const isStatus = (status: any) => {
    if (status !== 0) {
      return (
        <Box
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 'fit-content',
            borderRadius: 5,
            backgroundColor: 'green',
          }}
        >
          Aprovado
        </Box>
      )
    }
    if (status == 0) {
      return (
        <Box
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 'fit-content',
            borderRadius: 5,
            backgroundColor: 'yellow',
          }}
        >
          Aguardando
        </Box>
      )
    }
    if (status == null || status == false) {
      return (
        <Box
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 'fit-content',
            borderRadius: 5,
            backgroundColor: 'red',
          }}
        >
          Recusado
        </Box>
      )
    }
  }

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      {ownership !== null && (
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Flex>
            <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
              Detalhes da Propriedade
            </Text>
          </Flex>
          <Box>
            {/* <Text mb="2">
              <strong>ID da Posse:</strong> {ownership?.onwership_id}
            </Text> */}
            {/* <Text mb="2">
              <strong>ID do Usuário:</strong> {ownership?.user_id}
            </Text> */}
            {/* <Text mb="2">
              <strong>ID do Empreendimento:</strong> {ownership?.enterprise_id}
            </Text> */}
            <Text mb="2">
              <strong>Habitação Única:</strong> {ownership?.which_one_hu}
            </Text>
            {/* <Text mb="2">
              <strong>ID da Unidade Habitacional:</strong> {ownership?.habitational_unity_id}
            </Text> */}
            <Text mb="2">
              <strong>Data de Início:</strong> {moment(ownership?.start_at).format('DD/MM/yyyy')}
            </Text>
            <Text mb="2">
              <strong>Data de Término:</strong> {moment(ownership?.end_at).format('DD/MM/yyyy')}
            </Text>
            <Flex mb="2">
              <Text mr="2">
                <strong>Revisão do Empreendimento:</strong>
              </Text>
              {isStatus(ownership?.user_review_enterprise)}
            </Flex>
            <Flex mb="2">
              <Text mr="2">
                <strong>Revisão da clubshare:</strong>
              </Text>
              {isStatus(ownership?.user_review_cpt)}
            </Flex>
            <Text mb="2">
              <strong>Criado em:</strong> {moment(ownership?.created_at).format('DD/MM/yyyy hh:mm')}
            </Text>
            <Text mb="2">
              <strong>Atualizado em:</strong> {moment(ownership?.updated_at).format('DD/MM/yyyy hh:mm')}
            </Text>

            <Heading as="h2" size="md" mt="5">
              Detalhes do Empreendimento
            </Heading>
            <Text mb="2">
              <strong>Nome do Empreendimento:</strong> {ownership?.enterprise?.enterprise_name}
            </Text>

            <Heading as="h2" size="md" mt="5">
              Detalhes da Unidade Habitacional
            </Heading>
            <Text mb="2">
              <strong>Tipo de Quarto:</strong> {ownership?.habitational_unity.room_type}
            </Text>
            <Text mb="2">
              <strong>Número de Pessoas:</strong> {ownership?.habitational_unity?.pax}
            </Text>
          </Box>
        </Box>
      )}
    </Flex>
  )
}

export default OwnershipDetails
