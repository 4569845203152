/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Container, Flex, Heading, Text, Image } from '@chakra-ui/react'

import logo from '../../assets/logo.svg'
import { ResetPassEmailForm, ResetPassPasswordForm, ResetPassPinForm } from '../../components/forms/ResetPassForm'

const EmailForm = ({
  onChangeResetStatePin,
  setEmail,
}: {
  onChangeResetStatePin: () => void
  setEmail: (email: string) => void
}) => (
  <>
    <Heading fontSize={33} color="#FFFFFF" mb="6px">
      Redefinir senha
    </Heading>
    <Text textAlign="center" fontSize={20} color="#FFFFFF" mb="40px">
      Digite o e-mail ou celular cadastrado para validação
    </Text>
    <ResetPassEmailForm onChangeResetStatePin={onChangeResetStatePin} setEmail={setEmail} />
  </>
)

const PinForm = ({
  onChangeResetStatePin,
  onChangeResetStatePassword,
  setToken,
  email,
}: {
  onChangeResetStatePin: () => void
  onChangeResetStatePassword: () => void
  setToken: (token: string) => void
  email: string
}) => (
  <>
    <Heading fontSize={33} color="#FFFFFF" mb="6px">
      Redefinir senha
    </Heading>
    <Text textAlign="center" fontSize={20} color="#FFFFFF" mb="40px">
      Digite o código recebido no e-mail ou celular informado
    </Text>
    <ResetPassPinForm
      onChangeResetStatePin={onChangeResetStatePin}
      onChangeResetStatePassword={onChangeResetStatePassword}
      setToken={setToken}
      email={email}
    />
  </>
)

const PasswordForm = ({
  onChangeResetStatePassword,
  email,
  token,
}: {
  onChangeResetStatePassword: () => void
  email: string
  token: string
}) => (
  <>
    <Heading fontSize={33} color="#FFFFFF" mb="6px">
      Redefinir senha
    </Heading>
    <Text textAlign="center" fontSize={20} color="#FFFFFF" mb="40px">
      Digite sua nova senha
    </Text>
    <ResetPassPasswordForm onChangeResetStatePassword={onChangeResetStatePassword} email={email} token={token} />
  </>
)

export default function LoginPage() {
  const [email, setEmail] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [showResetPassPinForm, setShowResetPassPinForm] = useState<boolean>(false)
  const [showResetPassPasswordForm, setShowResetPassPasswordForm] = useState<boolean>(false)

  const onChangeResetStatePin = () => {
    setShowResetPassPinForm(old => !old)
  }

  const onChangeResetStatePassword = () => {
    setShowResetPassPasswordForm(old => !old)
  }

  return (
    <Flex
      minH="100vh"
      bg="linear-gradient(225deg, rgba(0,4,28,0.65) 0%, rgba(0,4,28,0.85) 15%, rgba(0,4,28,0.9) 50%, rgba(0,4,28,0.85) 85%, rgba(0,4,28,0.65) 100%)"
    >
      <Container maxW="540px" p="10px">
        <Flex align="center" justify="center" mt="50px" mb="30px">
          <Image maxH="200px" src={logo} />
        </Flex>
        <Flex
          maxW="500px"
          direction="column"
          align="center"
          justify="center"
          backgroundColor="rgba(0,0,0,0.4)"
          boxShadow="0px 20px 40px #2D3748"
          backdropFilter="blur(8px)"
          borderRadius={40}
          p="50px"
        >
          {showResetPassPinForm ? (
            <>
              {showResetPassPasswordForm ? (
                <PasswordForm onChangeResetStatePassword={onChangeResetStatePassword} token={token} email={email} />
              ) : (
                <PinForm
                  onChangeResetStatePin={onChangeResetStatePin}
                  onChangeResetStatePassword={onChangeResetStatePassword}
                  setToken={setToken}
                  email={email}
                />
              )}
            </>
          ) : (
            <EmailForm onChangeResetStatePin={onChangeResetStatePin} setEmail={setEmail} />
          )}
        </Flex>
      </Container>
    </Flex>
  )
}
