import React from 'react'
import { Button } from '@chakra-ui/react'

export function DatepickerBackBtns(props) {
  const { calendars, getBackProps } = props
  const customBtnProps = props.propsConfigs?.dateNavBtnProps
  return (
    <>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        {...customBtnProps}
      >
        {'<<'}
      </Button>
      <Button {...getBackProps({ calendars })} {...customBtnProps}>
        {'<'}
      </Button>
    </>
  )
}

export function DatepickerForwardBtns(props) {
  const { calendars, getForwardProps } = props
  const customBtnProps = props.propsConfigs?.dateNavBtnProps
  return (
    <>
      <Button
        {...getForwardProps({ calendars })}
        // {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {'>'}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        // {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {'>>'}
      </Button>
    </>
  )
}
