import React, { useState } from 'react'
import {
  Box,
  // IconButton,
  Text,
} from '@chakra-ui/react'
import {
  BiBed,
  // BiMinus, BiPlus
} from 'react-icons/bi'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'

export default function Room({
  rooms,
  // setRooms
}) {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [countRoom, setCountRoom] = useState(rooms.length ?? 1)
  const { language } = useLanguage()

  return (
    <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
      <Box margin="10px" display="flex" alignItems="center" flexDirection="row">
        <BiBed size={25} />
        <Text ml="5px" mr="15px" fontWeight="bold" fontSize="20px">
          {translates(language)?.searchbox?.default?.item7}
        </Text>
        {/* Comentado temporariamente */}
        {/* <IconButton
          onClick={() => {
            if (countRoom > 1) {
              setCountRoom(countRoom - 1)
              console.log('if 1')
            }
            if (countRoom === 2) {
              setRooms(old => [old[0]])
              console.log('if 2')
            }
            if (countRoom === 3) {
              setRooms(old => [old[0], old[1]])
              console.log('if 3')
            }
          }}
          colorScheme="red"
          size="sm"
          aria-label="Remove Room"
          icon={<BiMinus />}
        /> */}
        <Box ml="5px" mr="5px">
          <Text fontWeight="bold" fontSize="20px">
            {countRoom}
          </Text>
        </Box>
        {/* Comentado temporariamente */}
        {/* <IconButton
          onClick={() => {
            if (countRoom < 3) {
              setCountRoom(countRoom + 1)
              setRooms(old => [...old, { numberOfAdults: [1], childAges: [], quantity: 1 }])
            }
          }}
          colorScheme="red"
          size="sm"
          aria-label="Add Romm"
          icon={<BiPlus />}
        /> */}
      </Box>
    </Box>
  )
}
