import { apiCPT } from '../services/api';

export const getVoucher = async ({ book_id, user_id }) => {
  try {
    const res = await apiCPT.get(`/v1/sendvoucher/${book_id}/${user_id}`);
    console.log(res, 'voucher');

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTransaction = async dataTransactionJSON => {
  try {
    const res = await apiCPT.post(`v1/transactions`, dataTransactionJSON);
    console.log(res, 'transaction');
    if (res.status !== 201 && res.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res;
      } else {
        return res;
      }
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getReservation = async dataReservationJSON => {
  try {
    const res = await apiCPT.post(`v1/reservations`, dataReservationJSON);
    console.log(res, 'reservation');
    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res;
      } else {
        return res;
      }
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getPoints = async dataPointsJSON => {
  try {
    const res = await apiCPT.post(`v1/cashclubs`, dataPointsJSON);
    console.log(res, 'reservation');
    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res;
      } else {
        return res;
      }
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMyUser = async ({ id }) => {
  try {
    const res = await apiCPT.get(`v1/users/${id}`);
    console.log(res, 'res');
    localStorage.setItem('__user_data', JSON.stringify(res.data));
    if (res?.status !== 201 && res?.status !== 200) {
      if (window.location.origin.includes('localhost')) {
        return res;
      } else {
        return res;
      }
    }
    return res;
  } catch (error) {
    console.log(error);
  }
};
