/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  InputRightAddon,
  InputGroup,
  Checkbox,
} from '@chakra-ui/react';
import moment from 'moment';
import { useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import IEnterprises from '../../../interfaces/IEnterprises';
import MyTimer from './timer';
import { BiLeftArrow, BiRadar, BiSave, BiTrash, BiUpload } from 'react-icons/bi';

import { STORAGE_KEYS } from '../../../constants/Config';
import { useFetchCPT } from '../../../hooks/useFetchCPT';
import { apiCPT } from '../../../services/api';

interface FormProps {
  defaultValues?: any;
  defaultValues2?: any;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  yup?: any;
}

export default function EnterprisesAdmForm({ defaultValues = {}, defaultValues2 = {} }: FormProps) {
  const baseUrl = 'v1/enterprises';
  const { requestApiCPT, error, data } = useFetchCPT(baseUrl);
  const { onOpen } = useDisclosure();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [images, setImages] = useState<any>('');
  const t1 = new Date();
  t1.setSeconds(t1.getSeconds() + 600);
  const [time, setTime] = useState<Date>(t1);
  const [isTime, setIsTime] = useState(false);
  const [uploading, setUploading] = useState<any>(false);
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const toast = useToast();
  const [habitity, setHabitity] = useState<any>([]);
  const [hatIn, setHatIn] = useState<string>(defaultValues.habitational_unity_id ?? '');
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState<number[]>([]);
  const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH as string) as string);
  const formInputs: FormInputsProps[] = [
    {
      name: 'enterprise_name',
      value: defaultValues.enterprise_name ?? '',
      placeholder: '',
      label: 'Nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_description',
      value: defaultValues.enterprise_description ?? '',
      placeholder: '',
      label: 'Descrição',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'start_day_week',
      value: defaultValues.start_day_week ?? '',
      placeholder: '',
      label: 'Dia da semana',
      yup: Yup.number().typeError('Este campo é obrigatório!').required('Este campo é obrigatório!'),
    },
    {
      name: 'enterprise_pax',
      value: defaultValues?.enterprise_pax ?? '',
      placeholder: '',
      label: 'PAX',
      yup: Yup.string(),
    },
    {
      name: 'enterprise_status',
      value: defaultValues?.enterprise_status ?? 1,
      placeholder: '',
      label: 'Ativo?',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'enterprise_cnpj',
      value: defaultValues.enterprise_cnpj ?? '',
      placeholder: '',
      label: 'EX:. 00.000.000/0000-00',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          'CNPJ inválido',
        ),
    },
    {
      name: 'enterprise_order',
      value: defaultValues.enterprise_order ?? '999',
      placeholder: '',
      label: 'Ordem',
      yup: Yup.string().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'enterprise_address',
      value: defaultValues.enterprise_address ?? '',
      placeholder: '',
      label: 'Endereço',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_zipcode',
      value: defaultValues.enterprise_zipcode ?? '',
      placeholder: '',
      label: 'CEP',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/(\d{2}[.]?\d{3})[-]?(\d{3})/, 'CEP inválido'),
    },
    {
      name: 'enterprise_address_complement',
      value: defaultValues.enterprise_address_complement ?? '',
      placeholder: '',
      label: 'Complemento',
      yup: Yup.string(),
    },
    {
      name: 'enterprise_address_number',
      value: defaultValues.enterprise_address_number ?? '',
      placeholder: '',
      label: 'Numero',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'enterprise_address_neighborhood',
      value: defaultValues.enterprise_address_neighborhood ?? '',
      placeholder: '',
      label: 'Bairro',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_city',
      value: defaultValues.enterprise_address_city ?? '',
      placeholder: '',
      label: 'Cidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_state',
      value: defaultValues.enterprise_address_state ?? '',
      placeholder: '',
      label: 'Estado',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_country',
      value: defaultValues.enterprise_address_country ?? '',
      placeholder: '',
      label: 'País',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_longitude',
      value: defaultValues.enterprise_address_longitude ?? '',
      placeholder: '',
      label: 'Longitude',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'enterprise_address_latitude',
      value: defaultValues.enterprise_address_latitude ?? '',
      placeholder: '',
      label: 'Latitude',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'weight',
      value: defaultValues.enterprise_importance?.weight ?? 0,
      placeholder: '',
      label: 'Peso',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'warn_before',
      value: defaultValues.enterprise_importance?.warn_before ?? 0,
      placeholder: '',
      label: 'Aviso antes',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'renovation_discount',
      value: defaultValues.enterprise_importance?.renovation_discount ?? 0,
      placeholder: '',
      label: 'Renovação de desconto',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'cng_hotel_id',
      value: defaultValues.cng_hotel_id ?? '',
      placeholder: '',
      label: 'Cangoroo hotel id',
      yup: Yup.number(),
    },
    {
      name: 'quotation_dd_at',
      value: defaultValues.quotation_dd_at ?? moment('1900-01-01').format('YYYY-MM-DD'),
    },
    {
      name: 'dataTime_up',
      value: defaultValues2.dataTime ?? '',
    },
    {
      name: 'dataTime_up',
      value: defaultValues2.dataTime ?? '',
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const enterprise_name = formInputs.find(field => field.name === 'enterprise_name');
  const enterprise_cnpj = formInputs.find(field => field.name === 'enterprise_cnpj');
  const start_day_week = formInputs.find(field => field.name === 'start_day_week');
  const enterprise_description = formInputs.find(field => field.name === 'enterprise_description');
  const enterprise_pax = formInputs.find(field => field.name === 'enterprise_pax');
  const enterprise_zipcode = formInputs.find(field => field.name === 'enterprise_zipcode');
  const enterprise_address = formInputs.find(field => field.name === 'enterprise_address');
  const enterprise_address_complement = formInputs.find(field => field.name === 'enterprise_address_complement');
  const enterprise_address_neighborhood = formInputs.find(field => field.name === 'enterprise_address_neighborhood');
  const enterprise_address_country = formInputs.find(field => field.name === 'enterprise_address_country');
  const enterprise_address_number = formInputs.find(field => field.name === 'enterprise_address_number');
  const enterprise_address_city = formInputs.find(field => field.name === 'enterprise_address_city');
  const enterprise_address_state = formInputs.find(field => field.name === 'enterprise_address_state');
  const enterprise_address_longitude = formInputs.find(field => field.name === 'enterprise_address_longitude');
  const enterprise_address_latitude = formInputs.find(field => field.name === 'enterprise_address_latitude');
  const enterprise_status = formInputs.find(field => field.name === 'enterprise_status');
  const enterprise_order = formInputs.find(field => field.name === 'enterprise_order');
  const weight = formInputs.find(field => field.name === 'weight');
  const warn_before = formInputs.find(field => field.name === 'warn_before');
  const renovation_discount = formInputs.find(field => field.name === 'renovation_discount');
  const cng_hotel_id = formInputs.find(field => field.name === 'cng_hotel_id');
  const quotation_dd_at = formInputs.find(field => field.name === 'quotation_dd_at');
  const dataTime_up = formInputs.find(field => field.name === 'dataTime_up');

  const onSubmit = async (values: any) => {
    const vv: IEnterprises = values;
    const response = await requestApiCPT(
      defaultValues.enterprise_name ? 'put' : 'post',
      {
        ...vv,
        image: _,
        enterprise_cnpj: vv.enterprise_cnpj.replace(/\D/g, ''),
        habitational_unity_id: unidadesSelecionadas.join(';'),
      },
      defaultValues.enterprise_name ? `${baseUrl}/${itemId}` : null,
    );

    if (response.status < 299) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      if (!error && images) {
        setUploading(true);
        const apiUrlId = itemId ?? response?.data?.enterprise_id;
        await requestApiCPT('put', { file: images.toString() }, `${baseUrl}/${apiUrlId}/image`);
        setUploading(false);
        onOpen();
      } else {
        onOpen();
      }
      navigate('/enterprises');
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const dateDiff = (dt1: any, dt2: any) => {
    const ms = moment(dt1).diff(moment(dt2));
    const d = moment.duration(ms);
    const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    return s;
  };

  const cng_hotel_id_valid = () => {
    if (cng_hotel_id !== null && cng_hotel_id !== undefined) {
      if (Number(cng_hotel_id.value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dateDiff2 = (dt1: any, dt2: any) => {
    const today = moment(dt1);
    const day = moment(dt2);
    const duracao = moment.duration(today.diff(day));
    const segundos = duracao.asSeconds();
    return segundos;
  };

  useEffect(() => {
    if (defaultValues.enterprise_name) {
      const diff = dateDiff2(dataTime_up?.value, quotation_dd_at?.value);
      const diff2 = 600 - diff;
      const tt = new Date();
      tt.setSeconds(time.getSeconds() + diff2);
      setHatIn(defaultValues.habitational_unity_id);
      setTime(tt);
      reset(defaultValuesSchema);
      if (diff2 < 600 && diff2 > 0) {
        setIsTime(true);
      }
    }
  }, [defaultValues]);

  const dataActive = [
    { id: 0, name: 'Inativo' },
    { id: 1, name: 'Ativo' },
  ];

  function cotacao(ano: string) {
    const tt = new Date();
    tt.setSeconds(time.getSeconds() + 600);
    setTime(tt);
    setIsTime(true);
    const getQuotes = apiCPT.get(`v1/weekly_deposits/${itemId}/${ano}`);
  }

  async function onHab() {
    const getQuotes = apiCPT.get(`v1/habitational_unity`);
    getQuotes.then((response: any) => {
      setHabitity(response.data.habitational_unity);
      return response.data.habitational_unity;
    });
  }

  useEffect(() => {
    async function onHab1() {
      await onHab();
      const unidadesSelecionadasArray =
        hatIn !== '' || hatIn !== null || hatIn !== undefined ? hatIn?.split(';')?.map(Number) : [0];
      setUnidadesSelecionadas(unidadesSelecionadasArray);
    }
    onHab1();
  }, [hatIn]);

  const handleCheckboxChange: any = (id: number) => {
    // Adiciona ou remove o ID da unidade na lista de unidades selecionadas
    setUnidadesSelecionadas(prevUnidades => {
      if (prevUnidades?.includes(id)) {
        return prevUnidades?.filter(unidadeId => unidadeId !== id);
      } else {
        return [...prevUnidades, id];
      }
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Text mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
            Foto
          </Text>
          <Image w="250px" mb={7} src="/assets/noImg.png" />
          <Box display="flex" flexDir="row" mb="20px">
            <Button colorScheme="red" mr="20px" rightIcon={<BiTrash />} variant="outline">
              Excluir foto
            </Button>
            <Button mr="20px" colorScheme="red" rightIcon={<BiUpload />} variant="outline">
              Upload de foto
            </Button>
            {isTime ? (
              <MyTimer expiryTimestamp={time} />
            ) : (
              <Menu>
                <Button
                  as={MenuButton}
                  disabled={!cng_hotel_id_valid()}
                  colorScheme="red"
                  mr="20px"
                  rightIcon={<BiRadar />}
                  variant="solid"
                >
                  Realizar cotação
                </Button>
                <Portal>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        cotacao('2022');
                      }}
                    >
                      2022
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2023');
                      }}
                    >
                      2023
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2024');
                      }}
                    >
                      2024
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2025');
                      }}
                    >
                      2025
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2026');
                      }}
                    >
                      2026
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2027');
                      }}
                    >
                      2027
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2028');
                      }}
                    >
                      2028
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2029');
                      }}
                    >
                      2029
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2030');
                      }}
                    >
                      2030
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2031');
                      }}
                    >
                      2031
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2032');
                      }}
                    >
                      2032
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2033');
                      }}
                    >
                      2033
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cotacao('2034');
                      }}
                    >
                      2034
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            )}
            <Button
              onClick={() => navigate(`/quotes/${itemId}`)}
              colorScheme="red"
              mr="20px"
              rightIcon={<BiRadar />}
              variant="solid"
            >
              Ver cotações
            </Button>
          </Box>
          <Flex flexDir="column" mb="40px">
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações básicas
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_name && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Nome
                  </FormLabel>
                  <Input
                    id="enterprise_name"
                    placeholder={enterprise_name?.label}
                    isRequired
                    {...register('enterprise_name')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_name?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_description && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Descrição
                  </FormLabel>
                  <Input
                    id="enterprise_description"
                    placeholder={enterprise_description?.label}
                    isRequired
                    {...register('enterprise_description')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_description?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_cnpj && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CNPJ
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'99.999.999/9999-99'}
                    id="enterprise_cnpj"
                    placeholder={enterprise_cnpj?.label}
                    isRequired
                    {...register('enterprise_cnpj')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_cnpj?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_status && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {enterprise_status?.label}
                  </FormLabel>
                  <Select isRequired {...register('enterprise_status')}>
                    {dataActive.map((group, index) => (
                      <option key={index} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.enterprise_status?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.start_day_week && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {start_day_week?.label}
                  </FormLabel>
                  <Select isRequired {...register('start_day_week')}>
                    <option value={6}>Dom</option>
                    <option value={0}>Seg</option>
                    <option value={1}>Ter</option>
                    <option value={2}>Qua</option>
                    <option value={3}>Qui</option>
                    <option value={4}>Sex</option>
                    <option value={5}>Sab</option>
                  </Select>
                  <FormErrorMessage>{`${errors.start_day_week?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.cng_hotel_id && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Hotel id (Cangooroo)
                  </FormLabel>
                  <Input id="cng_hotel_id" placeholder={cng_hotel_id?.label} isRequired {...register('cng_hotel_id')} />
                  <FormErrorMessage>{`${errors.cng_hotel_id?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações de endereço
            </Text>
            <SimpleGrid columns={4} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Endereço
                  </FormLabel>
                  <Input
                    id="enterprise_address"
                    placeholder={enterprise_address?.label}
                    isRequired
                    {...register('enterprise_address')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address_complement && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Complemento
                  </FormLabel>
                  <Input
                    id="enterprise_address_complement"
                    placeholder={enterprise_address_complement?.label}
                    isRequired
                    {...register('enterprise_address_complement')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_complement?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_address_neighborhood && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Bairro
                  </FormLabel>
                  <Input
                    id="enterprise_address_neighborhood"
                    placeholder={enterprise_address_neighborhood?.label}
                    isRequired
                    {...register('enterprise_address_neighborhood')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_neighborhood?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.enterprise_zipcode && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CEP
                  </FormLabel>
                  <Input
                    id="enterprise_zipcode"
                    placeholder={enterprise_zipcode?.label}
                    isRequired
                    {...register('enterprise_zipcode')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_zipcode?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_number && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Numero
                  </FormLabel>
                  <Input
                    id="enterprise_address_number"
                    placeholder={enterprise_address_number?.label}
                    isRequired
                    {...register('enterprise_address_number')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_number?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_city && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Cidade
                  </FormLabel>
                  <Input
                    id="enterprise_address_city"
                    placeholder={enterprise_address_city?.label}
                    isRequired
                    {...register('enterprise_address_city')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_city?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_state && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Estado
                  </FormLabel>
                  <Input
                    id="enterprise_address_state"
                    placeholder={enterprise_address_state?.label}
                    isRequired
                    {...register('enterprise_address_state')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_state?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_country && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    País
                  </FormLabel>
                  <Input
                    id="enterprise_address_country"
                    placeholder={enterprise_address_country?.label}
                    isRequired
                    {...register('enterprise_address_country')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_country?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_latitude && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Latitude
                  </FormLabel>
                  <Input
                    id="enterprise_address_latitude"
                    placeholder={enterprise_address_latitude?.label}
                    isRequired
                    {...register('enterprise_address_latitude')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_latitude?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.enterprise_address_longitude && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Longitude
                  </FormLabel>
                  <Input
                    id="enterprise_address_longitude"
                    placeholder={enterprise_address_longitude?.label}
                    isRequired
                    {...register('enterprise_address_longitude')}
                  />
                  <FormErrorMessage>{`${errors.enterprise_address_longitude?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <>
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Informações habitacionais
              </Text>
              <SimpleGrid columns={3} spacing={10}>
                {habitity.map((group: any, index: number) => (
                  <Box key={index} maxW="sm" mr="1">
                    <Checkbox
                      isChecked={unidadesSelecionadas?.includes(group.habitational_unity_id)}
                      onChange={() => handleCheckboxChange(group.habitational_unity_id)}
                    >
                      {group.room_type} - acomoda {group.pax} pessoas
                    </Checkbox>
                  </Box>
                ))}
              </SimpleGrid>
            </>
            {user.user.user_type === '1' ? (
              <>
                <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                  Informações de parametros
                </Text>
                <SimpleGrid columns={3} spacing={10}>
                  <Box maxW="sm" mr="1">
                    <FormControl isInvalid={errors.weight && true}>
                      <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                        Peso de conversão
                      </FormLabel>
                      <Input id="weight" placeholder={weight?.label} isRequired {...register('weight')} />
                      <FormErrorMessage>{`${errors.weight?.message}`}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box maxW="sm" mr="1">
                    <FormControl isInvalid={errors.warn_before && true}>
                      <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                        Dias de aviso de antecedência
                      </FormLabel>
                      <Input
                        id="warn_before"
                        placeholder={warn_before?.label}
                        isRequired
                        {...register('warn_before')}
                      />
                      <FormErrorMessage>{`${errors.warn_before?.message}`}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box maxW="sm" mr="1">
                    <FormControl isInvalid={errors.renovation_discount && true}>
                      <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                        Desconto renovação
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          id="warn_before"
                          placeholder={renovation_discount?.label}
                          isRequired
                          {...register('renovation_discount')}
                        />
                        <InputRightAddon children="%" />
                      </InputGroup>
                      <FormErrorMessage>{`${errors.renovation_discount?.message}`}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </>
            ) : null}
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/enterprises')}
            leftIcon={<BiLeftArrow />}
            colorScheme="gray"
            variant="outline"
          >
            Cancelar e voltar
          </Button>
          <Button
            isLoading={isSubmitting || uploading}
            type="submit"
            rightIcon={<BiSave />}
            colorScheme="red"
            variant="solid"
          >
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  );
}
