// ExchangesDetails.tsx

import React, { useEffect } from 'react'
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Loader from '../../components/Loader'
import { useFetchCPT } from '../../hooks/useFetchCPT'
import NotData from '../../components/NotData'

const ExchangesDetails = () => {
  const { id } = useParams()
  const baseUrl = '/v1/reservations'
  const { requestApiCPT, data, loading } = useFetchCPT(`${baseUrl}/${id}`)

  useEffect(() => {
    requestApiCPT('get', null, `${baseUrl}/${id}`)
  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!data) {
    return <NotData />
  }

  const reservation = data

  const openVoucherTab = (bookId: any) => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`${process.env.REACT_APP_API_CPT_URL}/v1/viewvoucher1/${bookId}`, '_blank', 'noreferrer')
  }

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Detalhes da Reserva
          </Text>
        </Flex>
        <Box>
          <Text mb="2">
            <strong>Número da Reserva:</strong> {reservation.cng_booking_number}
          </Text>
          <Text mb="2">
            <strong>Número do Serviço:</strong> {reservation.cng_service_id}
          </Text>
          <Text mb="2">
            <strong>Data de Checkin:</strong> {moment(reservation.checkin_date).format('DD/MM/yyyy')}
          </Text>
          <Text mb="2">
            <strong>Data de Checkout:</strong> {moment(reservation.checkout_date).format('DD/MM/yyyy')}
          </Text>
          <Text mb="2">
            <strong>Status:</strong> {reservation.status == 1 ? 'Confirmado' : 'Recusado'}
          </Text>
          <Text mb="2">
            <strong>Observação:</strong> {reservation.observation}
          </Text>
          <Text mb="2">
            <strong>Criado em:</strong> {moment(reservation.created_at).format('DD/MM/yyyy hh:mm')}
          </Text>
          <Text mb="2">
            <strong>Atualizado em:</strong> {moment(reservation.updated_at).format('DD/MM/yyyy hh:mm')}
          </Text>

          <Heading as="h2" size="md" mt="5">
            Usuário Associado
          </Heading>
          <Text mb="2">
            <strong>Nome do Usuário:</strong> {reservation.users.user_firstname} {reservation.users.user_lastname}
          </Text>

          <Button onClick={() => openVoucherTab(reservation.cng_booking_number)} type="button" colorScheme="red">
            Visualizar voucher
          </Button>

          {/* <Heading as="h2" size="md" mt="5">
            Detalhes da Transação
          </Heading>
          <Text mb="2">
            <strong>ID da Transação:</strong> {reservation.transaction.transaction_id}
          </Text>
          <Text mb="2">
            <strong>TID:</strong> {reservation.transaction.tid}
          </Text>
          <Text mb="2">
            <strong>ID da Carga:</strong> {reservation.transaction.charge_id}
          </Text> */}
        </Box>
      </Box>
    </Flex>
  )
}

export default ExchangesDetails
