import { useToast } from '@chakra-ui/react'

export default function useCustomToast() {
  const toast = useToast()

  const toastGetwayToken = async () => {
    await toast.closeAll()
    await toast({
      title: 'Aguarde',
      description: 'Agora estamos criptografando seus dados de pagamento',
      status: 'info',
      duration: 11000,
      isClosable: false,
    })
  }

  const toastPayProfile = async () => {
    await toast.closeAll()
    return toast({
      title: 'Aguarde',
      description: 'Agora estamos validando seu pagamento',
      status: 'info',
      duration: 11000,
      isClosable: false,
    })
  }

  const toastSucessBook = async () => {
    await toast.closeAll()
    return toast({
      title: 'Parabéns! Reserva efetuada com sucesso',
      description: 'A Club Share agradece sua preferência!',
      status: 'success',
      duration: 4000,
      isClosable: false,
    })
  }

  const toastFailBook = async () => {
    await toast.closeAll()
    return toast({
      title: 'Reserva não efetuado',
      description: 'Verifique os dados ou entre em contato com a Club Share',
      status: 'error',
      duration: 4000,
      isClosable: false,
    })
  }

  const toastSucessPoints = async () => {
    await toast.closeAll()
    return toast({
      title: 'Transação em Viajex autorizada',
      description: 'Vamos continuar o procedimento de reserva',
      status: 'success',
      duration: 4000,
      isClosable: false,
    })
  }

  const toastFailPoints = async () => {
    await toast.closeAll()
    return toast({
      title: 'Transação em Viajex não autorizada',
      description: 'Verifique seu saldo, se necessário entre em contato com a Club Share',
      status: 'error',
      duration: 4000,
      isClosable: false,
    })
  }

  const toastPaymentSucess = async ({ api_msg }) => {
    await toast.closeAll()
    return toast({
      title: api_msg ?? 'Parabéns! Primeiro passo feito',
      description: 'Seu pagamento foi realizado com sucesso!',
      status: 'success',
      duration: 4000,
      isClosable: false,
    })
  }

  const toastPaymentFail = async ({ api_msg, api_description }) => {
    await toast.closeAll()
    return toast({
      title: api_msg ?? 'Transação não autorizada',
      description: api_description ?? 'Transação não autorizada, verifique os dados e o saldo do seu cartão!',
      status: 'error',
      duration: 4000,
      isClosable: false,
    })
  }
  return {
    toastGetwayToken,
    toastPayProfile,
    toastSucessBook,
    toastFailBook,
    toastSucessPoints,
    toastFailPoints,
    toastPaymentSucess,
    toastPaymentFail,
  }
}
