import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, PinInput, PinInputField } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

interface InputProps {
  name: string | undefined | any
  label: string | undefined
  styles?: any
  labelStyles?: any
  isRequired?: boolean
  numberOfFields: number
  control: any
  errors: any
}

export const PinInputForm = ({
  name,
  label,
  styles = {},
  labelStyles = {},
  isRequired = false,
  numberOfFields,
  control,
  errors,
}: InputProps) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={5}>
      <FormLabel style={labelStyles} mb={1} fontWeight="bold" htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { ...rest } }) => (
          <PinInput type="alphanumeric" {...rest}>
            {Array.from(Array(numberOfFields).keys()).map(item => {
              return <PinInputField borderColor={errors[name] ? 'red' : ''} key={item} mr="15px" style={styles} />
            })}
          </PinInput>
        )}
      />
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  )
}
