import React, { useState, useEffect } from 'react'
import { Box, Flex, Link, Text, Input, Button, Checkbox } from '@chakra-ui/react'
import moment from 'moment'

export default function InfoPessoais() {
  const [dados, setDados] = useState([])
  const userData = JSON.parse(localStorage.getItem('__user_data'))

  useEffect(() => {
    setDados({
      nome: userData.user_firstname,
      sobrenome: userData.user_lastname,
      email: userData.email,
      fone: userData.user_telephone,
      cpf: userData.user_cpf,
      pais: userData.user_country,
      dataNascimento: userData.user_birthdate,
    })
  }, [])

  return (
    <Box p={6} w="100%" h="100%">
      <Box>
        <Text fontWeight={700} fontSize="1.5rem">
          Perfil
        </Text>
        <Text>Queremos saber mais sobre você</Text>
      </Box>
      <Box mt={10}>
        <Flex w="100%">
          <Flex w="100%">
            <Box w="50%" p={2}>
              <Text>Nome</Text>
              <Input placeholder="Informe seu nome" value={dados.nome} readOnly />
            </Box>
            <Box w="50%" p={2}>
              <Text>Sobrenome</Text>
              <Input placeholder="Informe seu sobrenome" value={dados.sobrenome} readOnly />
            </Box>
          </Flex>
        </Flex>
        <Flex w="100%">
          <Box w="50%" p={2}>
            <Text>Data de Nascimento</Text>
            <Input value={dados.dataNascimento ? moment(dados.dataNascimento).format('L') : ''} />
          </Box>
          <Box w="50%" p={2}>
            <Text>Como gostaria que nós chamassemos você?</Text>
            <Input value={dados.nome} readOnly />
          </Box>
        </Flex>
        <Flex w="100%">
          <Box p={2} w="50%">
            <Text>CPF</Text>
            <Input value={dados.cpf} readOnly />
          </Box>
          <Box p={2} w="50%">
            <Text>País</Text>
            <Input value={dados.pais} readOnly />
          </Box>
        </Flex>
        <Flex w="100%">
          <Box p={2} w="100%">
            <Text fontWeight={700}>Contato & Privacidade</Text>
            <Text>
              Mantenha seus contatos atualizados para receber as informações das suas viagens e ofertas exclusivas
            </Text>
            <Box mt={6}>
              <Flex w="100%">
                <Box w="100%" p={2}>
                  <Text>E-mail</Text>
                  <Flex>
                    <Input
                      borderRadius="5px 0px 0px 5px"
                      placeholder="Informe seu e-mail"
                      value={dados.email}
                      readOnly
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Flex w="100%">
          <Flex w="100%">
            <Box p={2}>
              <Text>Telefone</Text>
              <Input placeholder="Fone.." value={dados.fone} readOnly />
            </Box>
          </Flex>
        </Flex>
        <Box w="100%" p={2}>
          <Text fontWeight={700}>Como prefere receber nossas comunicações?</Text>
          <Flex>
            <Checkbox defaultChecked>
              Quero receber ofertas, promoções exclusivas, cupons e novidades por e-mail
            </Checkbox>
          </Flex>
        </Box>
        <Box w="100%" p={2}>
          <Text fontWeight={700}>Segurança</Text>
          <Text>Não se preocupe! Todos os seus dados estão seguros e protegidos com a Club Share</Text>
        </Box>
        <Box w="100%" p={2}>
          <Text fontWeight={700}>Senha</Text>
          <Text>
            Deseja alterar sua senha?&nbsp;
            <Link href="/panel/dashboard" color="red.700">
              Crie uma nova senha
            </Link>{' '}
            e a mantenha em segredo! 😉
          </Text>
        </Box>
        <Box w="100%" p={2}>
          <Button colorScheme="red">Salvar Alterações</Button>
        </Box>
      </Box>
    </Box>
  )
}
