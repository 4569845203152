import { useEffect } from 'react';
import { useFetchCPT } from '../hooks/useFetchCPT';

export const useBalanceData = user_id => {
  // Defina o baseUrl como uma string vazia se user_id não for válido
  const baseUrl = user_id ? `v1/cashclubs/balance/${user_id}` : '';
  const { data, requestApiCPT, loading } = useFetchCPT(baseUrl);

  useEffect(() => {
    // Somente faça a requisição se user_id for válido
    if (user_id) {
      requestApiCPT('get');
    }
  }, [user_id]);

  return { data, requestApiCPT, loading };
};
