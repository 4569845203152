import { Button } from '@chakra-ui/react'
import React, { useMemo } from 'react'

const halfGap = 0.125 // default Chakra-gap-space-1 is 0.25rem

export function DayOfMonth({ dateObj, propsConfigs, isInRange, renderProps, onMouseEnter }) {
  const { date, selected, selectable, today } = dateObj
  const { getDateProps } = renderProps
  const { defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps } = propsConfigs?.dayOfMonthBtnProps || {}

  const styleBtnProps = useMemo(
    () => ({
      defaultBtnProps: {
        size: 'sm',
        variant: 'outline',
        background: 'transparent',
        borderColor: 'transparent',
        _after: {
          content: "''",
          position: 'absolute',
          top: `-${halfGap}rem`,
          left: `-${halfGap}rem`,
          bottom: `-${halfGap}rem`,
          right: `-${halfGap}rem`,
          borderWidth: `${halfGap}rem`,
          borderColor: 'transparent',
        },
        ...defaultBtnProps,
        _hover: selectable
          ? {
              bg: 'red.400',
              ...defaultBtnProps?._hover,
            }
          : undefined,
      },
      isInRangeBtnProps: {
        background: 'red.200',
        ...isInRangeBtnProps,
      },
      selectedBtnProps: {
        background: 'red.200',
        ...selectedBtnProps,
      },
      todayBtnProps: {
        borderColor: 'red.400',
        ...todayBtnProps,
      },
    }),
    [defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps, selectable],
  )

  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !selectable,
        onMouseEnter: onMouseEnter,
      })}
      disabled={!selectable}
      {...styleBtnProps.defaultBtnProps}
      {...(isInRange && selectable && styleBtnProps.isInRangeBtnProps)}
      {...(selected && selectable && styleBtnProps.selectedBtnProps)}
      {...(today && styleBtnProps.todayBtnProps)}
    >
      {date.getDate()}
    </Button>
  )
}
