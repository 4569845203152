import React, { createContext, useContext, useState } from 'react'
import { apiAirportAutocomplete } from '../services/api'

export const SearchDestinationVoosContext = createContext(null)

export function SearchDestinationVoosProvider({ children }) {
  //VOOS
  const [origin, setOrigin] = useState([])
  const [isLoadingOrigin, setisLoadingOrigin] = useState(false)
  const [idDestinationOrigin, setIDDestinationOrigin] = useState('')
  const [searchOrigin, setSearchOrigin] = useState('')
  const [destiny, setDestiny] = useState([])
  const [isLoadingDestiny, setisLoadingDestiny] = useState(false)
  const [idDestinationDestiny, setIDDestinationDestiny] = useState('')
  const [searchDestiny, setSearchDestiny] = useState('')

  //IFRAME
  const [urlIFrame, setUrlIFrame] = useState('')

  //AUTOCOMPLETE VOOS DESTINATION
  const onChangeOrigin = async src => {
    setisLoadingOrigin(true)
    try {
      const url = process.env.REACT_APP_AIRPORT_AUTOCOMPLETE.replace('{destination}', src)
      const { data } = await apiAirportAutocomplete.get(url)
      setOrigin(data)
    } catch (error) {
      setOrigin([])
      console.log(error)
    }
    setisLoadingOrigin(false)
  }

  const onChangeDestiny = async src => {
    setisLoadingOrigin(true)
    try {
      const url = process.env.REACT_APP_AIRPORT_AUTOCOMPLETE.replace('{destination}', src)
      const { data } = await apiAirportAutocomplete.get(url)
      setDestiny(data)
    } catch (error) {
      setDestiny([])
      console.log(error)
    }
    setisLoadingOrigin(false)
  }

  const value = React.useMemo(
    () => ({
      origin,
      setOrigin,
      onChangeOrigin,
      isLoadingOrigin,
      setisLoadingOrigin,
      idDestinationOrigin,
      setIDDestinationOrigin,
      searchOrigin,
      setSearchOrigin,
      destiny,
      setDestiny,
      isLoadingDestiny,
      setisLoadingDestiny,
      idDestinationDestiny,
      setIDDestinationDestiny,
      searchDestiny,
      setSearchDestiny,
      onChangeDestiny,
      urlIFrame,
      setUrlIFrame,
    }),
    [
      origin,
      setOrigin,
      onChangeOrigin,
      isLoadingOrigin,
      setisLoadingOrigin,
      idDestinationOrigin,
      setIDDestinationOrigin,
      searchOrigin,
      setSearchOrigin,
      destiny,
      setDestiny,
      isLoadingDestiny,
      setisLoadingDestiny,
      idDestinationDestiny,
      setIDDestinationDestiny,
      searchDestiny,
      setSearchDestiny,
      onChangeDestiny,
      urlIFrame,
      setUrlIFrame,
    ],
  )

  return (
    <SearchDestinationVoosContext.Provider value={value !== undefined && value}>
      {React.useMemo(() => children, [])}
    </SearchDestinationVoosContext.Provider>
  )
}

export function useSearchDestinationVoosProvider() {
  const context = useContext(SearchDestinationVoosContext)
  if (!context) {
    throw new Error('The context The Search must be within a valid provider')
  }
  return context
}
