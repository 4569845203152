import React, { useEffect } from 'react'
import { Stack, Flex, Center, Text, VStack, HStack, useBreakpointValue, Container, Image } from '@chakra-ui/react'
import SearchBox from '../../components/SearchBox'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'
import { useSearch } from '../../providers/SearchProvider'
import { CURRENT_PROJECT_VERSION } from '../../utils/projectVersion'

export default function Home() {
  const { language } = useLanguage()
  const { setDestinations } = useSearch()
  const project_version = localStorage.getItem('project_version')

  useEffect(() => {
    setDestinations([])
  }, [])

  useEffect(() => {
    const caching = async () => {
      if (typeof project_version !== 'undefined') {
        if (project_version?.toString() !== `"${CURRENT_PROJECT_VERSION?.toString()}"`) {
          if ('caches' in window) {
            await caches.keys().then(versions => {
              // Delete all the cache files
              versions.forEach(version => {
                caches.delete(version)
              })
            })
            console.info(
              `A sua versão atual da plataforma é : ${project_version?.toString()}, a versão mais recente é: "${CURRENT_PROJECT_VERSION?.toString()}"`,
            )
          }

          await localStorage.clear()
          await localStorage.setItem('project_version', JSON.stringify(CURRENT_PROJECT_VERSION))
          await localStorage.setItem('language', JSON.stringify('pt-BR'))
          await localStorage.setItem('cotation', JSON.stringify('BRL'))
          await window.location.reload()
        }
      }
    }
    caching()
  }, [project_version])

  return (
    <Stack spacing={-12}>
      <Flex
        w={'full'}
        h={['60vh', '40vh', '60vh']}
        backgroundImage={'/assets/bg/banner.png'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
      >
        <Flex
          w={'full'}
          px={useBreakpointValue({ base: 4, md: 8 })}
          bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
          height={'full'}
        >
          <Container display={'flex'} maxW={1280} justifyContent={'center'}>
            <VStack w={'full'} justifyContent={'center'} alignItems={'flex-start'}>
              <Stack maxW={'2xl'} align={'flex-start'} spacing={1}>
                <HStack align={'center'} spacing={3}>
                  <Image w="18px" src="/assets/icons/rain_icon.svg" />
                  <Text
                    maxW={400}
                    color={'gray.100'}
                    fontWeight={500}
                    lineHeight={1.25}
                    letterSpacing={'-0.027em'}
                    fontSize={useBreakpointValue({ base: '14px', md: '14px' })}
                    textTransform={'uppercase'}
                    mb={[4, 1]}
                  >
                    {translates(language)?.home?.banner01}
                  </Text>
                </HStack>

                <Text
                  display={'flex'}
                  flexDir={'row'}
                  flexWrap={'wrap'}
                  maxW={400}
                  color={'gray.50'}
                  fontWeight={600}
                  lineHeight={1.25}
                  letterSpacing={'-0.027em'}
                  fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}
                >
                  {translates(language)?.home?.banner02}
                  <Text mx={2} as={'span'} fontWeight={400} color={'gray.300'}>
                    {translates(language)?.home?.banner03}
                  </Text>
                  {translates(language)?.home?.banner04}
                  <Image
                    mb={[-2, -3]}
                    ml={2}
                    display={'flex'}
                    alignItems={'flex-end'}
                    w="12px"
                    src="/assets/icons/polygon.svg"
                  />
                </Text>
              </Stack>
            </VStack>
          </Container>
        </Flex>
      </Flex>
      <Center>
        <SearchBox />
      </Center>
    </Stack>
  )
}
