import Users from '../pages/Users'
import Exchanges from '../pages/Exchanges'
import Enterprises from '../pages/Enterprises'
// import UserEditPage from '../pages/UserEditPage'
import { BiBuildingHouse, BiHome, BiRepeat, BiUserPlus } from 'react-icons/bi'
import UsersCadastroPage from '../pages/Users/UsersCadastro'
import EnterprisesCadastroPage from '../pages/Enterprises/EnterprisesCadastro'
import Home from '../pages/Home'
import Balance from '../pages/Admin/Balance'
// import UsersCreatePage from '../pages/UserCreatePage'

export const PrivateRoutes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    componentCreate: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiHome,
  },
  {
    name: 'Usuários',
    path: '/users',
    component: Users,
    componentCreate: UsersCadastroPage,
    componentEdit: UsersCadastroPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiUserPlus,
  },
  {
    name: 'Intercâmbios',
    path: '/exchanges',
    component: Exchanges,
    // componentCreate: ExchangesCreatePage,
    // componentEdit: ExchangesEditPage,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: BiRepeat,
  },
  {
    name: 'Extrato de Viajex',
    path: '/balance/:itemId',
    component: Balance,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'none',
    icon: BiUserPlus,
  },
  {
    name: 'Empresas',
    path: '/enterprises',
    component: Enterprises,
    componentCreate: EnterprisesCadastroPage,
    componentEdit: EnterprisesCadastroPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiBuildingHouse,
  },
]
