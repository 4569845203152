import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Image,
  Center,
  Text,
  IconButton,
  Collapse,
  Flex,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import SideMenu from '../../pages/Admin/DashBoard/SideMenu';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import SideMenuDesktop from '../../pages/Admin/DashBoard/SideMenuDesktop';
import { BiRefresh } from 'react-icons/bi';

const PanelMenu = ({ menuOpen, handleMenuToggle }) => {
  const userData = JSON.parse(localStorage.getItem('__user_data'));
  const location = useLocation();
  const navigate = useNavigate();
  const [dashboardSelected, setDashboardSelected] = useState(
    location?.pathname.includes('panel/dashboard') ? true : false,
  );
  const [infoPessoaisSelected, setInfoPessoaisSelected] = useState(
    location?.pathname.includes(`panel/users/${userData?.user_id}/edit`) ? true : false,
  );
  const [pedidosSelected, setPedidosSelected] = useState(location?.pathname.includes('panel/reservas') ? true : false);
  const [extratoPontosSelected, setExtratoPontosSelected] = useState(
    location?.pathname.includes('panel/extrato-cashclubs') ? true : false,
  );
  const [empreendimentosSelected, setEmpreendimentosSelected] = useState(
    location?.pathname.includes('panel/propriedades') ? true : false,
  );
  const [exchangesSelected, setExchangesSelected] = useState(
    location?.pathname.includes('panel/intercambios') ? true : false,
  );
  const [financesSelected, setFinancesSelected] = useState(
    location?.pathname.includes('panel/finances') ? true : false,
  );
  const [dados, setDados] = useState([]);

  useEffect(() => {
    setDados({
      nome: userData ? userData.user_firstname : '',
      sobrenome: userData ? userData.user_lastname : '',
      avatar: userData ? userData.user_image : '',
    });
  }, []);

  function clearSideMenuSelected() {
    setDashboardSelected(false);
    setInfoPessoaisSelected(false);
    setPedidosSelected(false);
    setExtratoPontosSelected(false);
    setEmpreendimentosSelected(false);
    setFinancesSelected(false);
    setExchangesSelected(false);
  }

  function handleClick(object) {
    navigate(`${object?.route}`);
    clearSideMenuSelected();
    if (object.route === 'panel/dashboard') setDashboardSelected(true);
    if (object.route === 'panel/finances') setFinancesSelected(true);
    if (object.route === 'panel/propriedades') setEmpreendimentosSelected(true);
    if (object.route === 'panel/extrato-cashclubs') setExtratoPontosSelected(true);
    if (object.route === `panel/users/${userData?.user_id}/edit`) setInfoPessoaisSelected(true);
    if (object.route === 'panel/reservas') setPedidosSelected(true);
    if (object.route === 'panel/intercambios') setExchangesSelected(true);
    handleMenuToggle(false);
  }

  useEffect(() => {
    if (!!location?.pathname && location?.pathname.includes('enterprises')) {
      clearSideMenuSelected();
      setEmpreendimentosSelected(true);
    }
    if (!!location?.pathname && location?.pathname.includes('finances')) {
      clearSideMenuSelected();
      setFinancesSelected(true);
    }
  }, [location?.pathname]);

  const toggleMenu = () => {
    handleMenuToggle(!menuOpen);
  };

  const menuRef = useRef();

  const isMobile = useMediaQuery('(max-width: 700px)');
  return (
    <Box ref={menuRef}>
      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems={isMobile ? 'center' : 'flex-start'}
      >
        <Box
          onClick={toggleMenu}
          ml="5"
          mt="5"
          display={{ base: 'block', md: 'none' }}
          w={isMobile ? '100%' : 'auto'}
          textAlign="center"
          verticalAlign="center"
        >
          <Flex align="center">
            <IconButton icon={menuOpen ? <CloseIcon /> : <FiMenu />} variant="ghost" />
            <Text fontWeight="bold" ml={2}>
              Painel do associado
            </Text>
          </Flex>
        </Box>
        {isMobile && (
          <Center flexDirection="column" mb={6} display={{ base: 'none', md: 'flex' }}>
            <Box p={4} w="100%">
              <Center flexDirection="column" mb={6}>
                <Box mt={5}>
                  <Image
                    w="64px"
                    mb={2}
                    src={dados?.avatar !== 'NULL' ? dados?.avatar : '/assets/icons/p-avatar.svg'}
                  />
                </Box>
                <Box>
                  <Text fontWeight={700} fontSize={16}>
                    {`${dados?.nome} ${dados?.sobrenome}`}
                  </Text>
                </Box>
              </Center>
              <SideMenuDesktop
                clicked={handleClick}
                actual={dashboardSelected}
                icon="/assets/icons/dashboard.svg"
                title="Início"
                route="panel/dashboard"
              />
              {Number(userData.user_type) <= 4 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={infoPessoaisSelected}
                  icon="/assets/icons/user.svg"
                  title="Meus Dados"
                  route={`panel/users/${userData?.user_id}/edit`}
                />
              )}
              {Number(userData.user_type) <= 3 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={pedidosSelected}
                  icon="/assets/icons/pedidos.svg"
                  title="Reservas"
                  route="panel/reservas"
                />
              )}
              {Number(userData.user_type) <= 3 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={extratoPontosSelected}
                  icon="/assets/icons/deposito-dias.svg"
                  title="Extrato de Viajex"
                  route="panel/extrato-cashclubs"
                />
              )}
              {Number(userData.user_type) <= 3 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={empreendimentosSelected}
                  icon="/assets/icons/empreendimentos.svg"
                  title="Intercâmbios"
                  route="panel/propriedades"
                />
              )}
              {Number(userData.user_type) != 3 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={exchangesSelected}
                  icon={<BiRefresh size={30} />}
                  title="Intercâmbios"
                  route="panel/intercambios"
                />
              )}

              {Number(userData.user_type) <= 3 && (
                <SideMenuDesktop
                  clicked={handleClick}
                  actual={financesSelected}
                  icon="/assets/icons/finances.svg"
                  title="Finanças"
                  route="panel/finances"
                />
              )}
            </Box>
          </Center>
        )}
      </Flex>
      <Collapse style={{ zIndex: 1000 }} in={menuOpen} animateOpacity>
        <Box
          p={4}
          style={{ top: menuOpen ? 0 : 0, position: 'relative', zIndex: 1001 }}
          bg={useColorModeValue('white', 'gray.800')}
          mt={2}
          borderRadius="md"
          overflow="hidden"
          boxShadow="md"
          position="fixed"
          top="60px"
          left={0}
          right={0}
          w="100vw"
          zIndex="modal"
          display={{ base: 'block', md: 'none' }}
        >
          <SideMenu
            clicked={handleClick}
            actual={dashboardSelected}
            icon="/assets/icons/dashboard.svg"
            title="Início"
            route="panel/dashboard"
          />
          {Number(userData.user_type) <= 4 && (
            <SideMenuDesktop
              clicked={handleClick}
              actual={infoPessoaisSelected}
              icon="/assets/icons/user.svg"
              title="Meus Dados"
              route={`panel/users/${userData?.user_id}/edit`}
            />
          )}
          {Number(userData.user_type) <= 3 && (
            <SideMenuDesktop
              clicked={handleClick}
              actual={pedidosSelected}
              icon="/assets/icons/pedidos.svg"
              title="Reservas"
              route="panel/reservas"
            />
          )}
          {Number(userData.user_type) <= 3 && (
            <SideMenuDesktop
              clicked={handleClick}
              actual={extratoPontosSelected}
              icon="/assets/icons/deposito-dias.svg"
              title="Extrato de Viajex"
              route="panel/extrato-cashclubs"
            />
          )}
          {Number(userData.user_type) <= 3 && (
            <SideMenuDesktop
              clicked={handleClick}
              actual={financesSelected}
              icon="/assets/icons/finances.svg"
              title="Finanças"
              route="panel/finances"
            />
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default PanelMenu;
