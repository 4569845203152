import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const NotData = () => {
  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box marginTop="20" display="flex" flexDirection="column" alignItems="center">
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: '25px',
            marginTop: '30px',
          }}
        >
          Nenhum dado encontrado!
        </Text>
      </Box>
    </Box>
  )
}

export default NotData
