/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Box,
  Divider,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { FaBed, FaUsers } from 'react-icons/fa'
import Room from './room'
import Adult from './adult'
import Children from './children'
import Age from './age'
import { calcHops } from '../../utils/hotelsSearch'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'

export default function CounterBox({ rooms, setRooms }) {
  const { language } = useLanguage()
  const [isOpen, setIsOpen] = React.useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      onOpen={open}
      placement="bottom"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button>
          <FaBed size={35} style={{ marginLeft: 8, marginRight: 8 }} />
          {`${translates(language)?.searchbox?.default?.item7}: ${rooms.length}`}
          <FaUsers size={35} style={{ marginLeft: 8, marginRight: 8 }} />
          {`${translates(language)?.searchbox?.default?.item11} ${calcHops(rooms)}`}
        </Button>
      </PopoverTrigger>
      <Portal>
        <Box zIndex="sticky">
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
                <Room rooms={rooms} setRooms={setRooms} />
                {rooms?.map((_, idx) => (
                  <span key={`${idx}-room`}>
                    <Divider h="5px" />
                    <Box display="flex" flexDirection="row">
                      <Adult idx={idx} rooms={rooms} setRooms={setRooms} />
                      <Children idx={idx} rooms={rooms} setRooms={setRooms} />
                    </Box>

                    {rooms[idx]?.childrenAge?.map((_a, id) => (
                      <Age key={`${id}-age`} idx={idx} id={id} rooms={rooms} setRooms={setRooms} />
                    ))}
                  </span>
                ))}
              </Box>
            </PopoverBody>
            <Button m="10px" colorScheme="red" onClick={close}>
              Confirmar
            </Button>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}
