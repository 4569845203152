import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  Image,
  Heading,
  HStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  VStack,
  Link,
  Icon,
  Divider,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import 'moment/locale/pt-br'
import ReactInputMask from 'react-input-mask'
import flags from 'react-phone-number-input/flags'
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'react-credit-cards/es/styles-compiled.css'
import { useNavigate } from 'react-router-dom'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useAuth } from '../../providers/Auth'
import { apiCPT } from '../../services/api'
import logo from '../../assets/logo.svg'
import { FiHelpCircle, FiInfo, FiCheckCircle } from 'react-icons/fi'
import NavBarAuth from '../../layout/NavBar'
import Footer from '../../layout/Footer'

export default function Association() {
  const { Login } = useAuth()
  const navigate = useNavigate()

  const [cepData, setCepData] = useState([])

  const [show, setShow] = React.useState(false)

  const handleClick = () => setShow(!show)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    shouldUseNativeValidation: true,
    shouldFocusError: true,
    criteriaMode: 'all',
  })

  const srcCep = async zipcode => {
    const options = { method: 'GET' }

    await fetch(`http://viacep.com.br/ws/${zipcode}/json/`, options)
      .then(response => response.json())
      .then(response => {
        setNewCepData(true)
        setCepData(response)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (cepData?.localidade) {
      setValue('user_address_city', cepData?.localidade ?? '')
    }
    if (cepData?.logradouro) {
      setValue('user_address', `${cepData?.logradouro ?? ''}`)
    }
    if (cepData?.bairro) {
      setValue('user_address_neighborhood', `${cepData?.bairro ?? ''}`)
    }
    if (cepData?.uf) {
      setValue('user_address_state', cepData?.uf ?? '')
    }
  }, [cepData])

  const errorStyle = { color: 'red', fontSize: 10 }

  const getRegister = async data => {
    try {
      const res = await apiCPT.post(`v1/users`, data)
      if (res?.status === 200 || res?.status === 201) {
        Login({ email: data?.email, password: data?.password, grant_type: 'password' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [roles, setRoles] = useState([])

  // const [enterprisesData, setEnterprisesData] = useState([])
  // const getEnterprises = async () => {
  //   try {
  //     const res = await apiCPT.get('v1/enterprises', { params: { perPage: 999999 } })

  //     if (res?.status === 200 || res?.status === 201) {
  //       setEnterprisesData(res?.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getRoles = async () => {
    try {
      const res = await apiCPT.get('v1/roles', { params: { perPage: 999999 } })

      if (res?.status === 200 || res?.status === 201) {
        setRoles(res?.data?.roles)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const onSubmit = async values => {
    getRegister({ ...values, user_type: 3, user_status: 0 })
  }

  const viewportHeight = window.innerHeight
  const secureHeight = viewportHeight - viewportHeight / 4.5

  return (
    <>
      <NavBarAuth />

      <Box w="full" minHeight={secureHeight}>
        <Flex as="header" w="100%" h="40px" bgColor="#272525" px="16px" justifyContent="flex-end">
          <Flex as="nav">
            <HStack spacing={16}>
              <Link display="flex" alignItems="center" textColor="white">
                <Icon as={FiHelpCircle} fontSize="16px"></Icon>
                <Text ml={2} fontSize="16px" fontWeight="medium" fontFamily="Poppins">
                  Ajuda
                </Text>
              </Link>
              <Link display="flex" alignItems="center" textColor="white">
                <Icon as={FiInfo} fontSize="16px"></Icon>
                <Text ml={2} fontSize="16px" fontWeight="medium" fontFamily="Poppins">
                  Privacidade
                </Text>
              </Link>
              <Link display="flex" alignItems="center" textColor="white">
                <Icon as={FiCheckCircle} fontSize="16px"></Icon>
                <Text ml={2} fontSize="16px" fontWeight="medium" fontFamily="Poppins">
                  Termos de Uso
                </Text>
              </Link>
            </HStack>
          </Flex>
        </Flex>
        <Flex w="100%" p="10" align="center" justify="center">
          <Flex flexDirection="column" justify="center" align="center" w="100%" maxWidth={1280}>
            <Heading size="md">
              <a href="/" style={{ cursor: 'pointer' }}>
                <Image w="250px" mb={7} src={logo} />
              </a>
            </Heading>

            <Box align="center" justify="center" mb="32px">
              <Heading fontSize="24px" mb="16px">
                Confirmação de dados cadastrais
              </Heading>
              <Text mb="16px" fontWeight="bold" fontSize="16px" fontFamily="Poppins" color={'gray.700'}>
                Por gentileza, verifique e valide todos os dados abaixo 😉
              </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="8" w="80%">
                <Box w="100%">
                  <Heading fontSize="18px" mb="4">
                    Dados pessoais
                  </Heading>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>Nome</FormLabel>
                      <Input
                        {...register('user_firstname', { required: true, maxLength: 20 })}
                        mb="8px"
                        fontSize="sm"
                        type="text"
                        placeholder="Seu nome"
                        size="md"
                      />

                      {errors.user_firstname?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Sobrenome</FormLabel>
                      <Input
                        {...register('user_lastname', { required: true, maxLength: 20 })}
                        mb="8px"
                        fontSize="sm"
                        type="text"
                        placeholder="Seu sobrenome"
                        size="md"
                      />
                      {errors.user_lastname?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>CPF</FormLabel>
                      <Input
                        as={ReactInputMask}
                        mask="999.999.999-99"
                        {...register('user_cpf', { required: true })}
                        placeholder="Digite o CPF"
                        mb="8px"
                        fontSize="sm"
                        type="text"
                        size="md"
                      />

                      {errors.user_cpf?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                  </SimpleGrid>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>Data de nascimento</FormLabel>
                      <Input
                        type="date"
                        {...register('user_birth_date', {
                          required: true,
                          valueAsDate: true,
                          onChange: e => {
                            setValue('age', `${moment().diff(moment(e.currentTarget.value, 'YYYY-MM-DD'), 'years')}`)
                          },
                          validate: date =>
                            moment().diff(moment(date, 'YYYY-MM-DD'), 'years') >= 18 || dateRangeErrorMessage,
                        })}
                        placeholder="Exemplo: 15/09/1989"
                        mb="8px"
                        fontSize="sm"
                        size="md"
                      />

                      {errors['user_birth_date'] && (
                        <p role="alert" style={errorStyle}>
                          {dateRangeErrorMessage}
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Idade</FormLabel>
                      <Input
                        isDisabled
                        {...register('age', { required: true, min: 18, max: 99 })}
                        placeholder="Exemplo: 21"
                        mb="8px"
                        fontSize="sm"
                        type="text"
                        size="md"
                      />

                      {errors.age?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <Box maxW="md" mr="1">
                      <FormControl isInvalid={errors.user_type && true}>
                        <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                          Tipo de usuário associado
                        </FormLabel>
                        <Select
                          disabled
                          value={3}
                          defaultChecked={3}
                          defaultValue={3}
                          isRequired
                          {...register('user_type', errors)}
                        >
                          {roles?.map((group, index) => (
                            <option key={index} value={group.role_id}>
                              {group.role_name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{`${errors.user_type?.message}`}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                </Box>
                {/* 
              <Divider />
              <Box w="100%">
                <Heading fontSize="18px" mb="4">
                  Empreendimento
                </Heading>
                <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                  <Box maxW="md" mr="1">
                    <FormControl isInvalid={errors.user_type && true}>
                      <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                        Empresa Associada
                      </FormLabel>
                      <Select placeholder="Selecione um empreendimento" isRequired {...register('enterprise', errors)}>
                        {enterprisesData &&
                          enterprisesData.enterprises &&
                          enterprisesData?.enterprises?.map((enterprise, index) => (
                            <option key={index} value={enterprise.enterprise_id}>
                              {enterprise.enterprise_name}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>{`${errors.enterprise?.message}`}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </Box> 
              */}
                <Divider />
                <Box w="100%">
                  <Heading fontSize="18px" mb="4">
                    Contato
                  </Heading>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>E-mail</FormLabel>
                      <Input
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        type="email"
                        placeholder="Seu melhor email"
                        mb="8px"
                        fontSize="sm"
                        size="md"
                      />
                      {errors.email?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Número de telefone</FormLabel>

                      <InputGroup>
                        <Controller
                          name="user_telephone"
                          control={control}
                          rules={{
                            validate: value => isValidPhoneNumber(value),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              fontSize="sm"
                              size="md"
                              as={PhoneInputWithCountrySelect}
                              flags={flags}
                              value={value}
                              defaultValue="BR"
                              defaultCountry="BR"
                              onChange={onChange}
                              international
                              type="tel"
                              {...register('user_telephone', { required: true })}
                              placeholder=""
                            />
                          )}
                        />
                      </InputGroup>
                      {errors['user_telephone'] && (
                        <p role="alert" style={errorStyle}>
                          Campo inválido
                        </p>
                      )}
                    </FormControl>
                  </SimpleGrid>
                </Box>
                <Divider />
                <Box w="100%">
                  <Heading fontSize="18px" mb="4">
                    Endereço
                  </Heading>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>CEP</FormLabel>
                      <Input
                        as={ReactInputMask}
                        mask="99999-999"
                        {...register('user_address_zipcode', {
                          required: true,
                          onChange: e => {
                            if (e.currentTarget.value.replace(/\D/g, '').length >= 8) {
                              srcCep(e.currentTarget.value)
                            }
                          },
                        })}
                        mb="8px"
                        type="text"
                        placeholder="Seu CEP"
                        size="md"
                        fontSize="sm"
                      />
                      {errors.user_address_zipcode?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Endereço</FormLabel>
                      <Input
                        type="text"
                        {...register('user_address', { required: true })}
                        placeholder="Digite seu endereço"
                        mb="8px"
                        size="md"
                        fontSize="sm"
                      />
                      {errors.user_address?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Número</FormLabel>
                      <Input
                        type="number"
                        {...register('user_address_number', { required: true })}
                        placeholder="999"
                        mb="8px"
                        size="md"
                        fontSize="sm"
                      />
                      {errors.user_address_number?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                  </SimpleGrid>
                  <SimpleGrid columns={[2, null, 2]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>Complemento do Endereço</FormLabel>
                      <Input
                        {...register('user_address_complement', { required: true })}
                        placeholder="Qd. 99, Lt. 99"
                        mb="8px"
                        type="text"
                        size="md"
                        fontSize="sm"
                      />
                      {errors.user_address_complement?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Bairro</FormLabel>
                      <Input
                        {...register('user_address_neighborhood', { required: true })}
                        placeholder="Ex.: Setor Central"
                        mb="8px"
                        type="text"
                        size="md"
                        fontSize="sm"
                      />
                      {errors.user_address_neighborhood?.type === 'required' && (
                        <p role="alert" style={errorStyle}>
                          Este campo é Obrigatório
                        </p>
                      )}
                    </FormControl>
                  </SimpleGrid>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <FormControl>
                      <FormLabel>Estado</FormLabel>
                      <Input
                        {...register('user_address_state', { required: true })}
                        placeholder="Ex: Goiás"
                        mb="8px"
                        type="text"
                        size="md"
                        fontSize="sm"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Cidade</FormLabel>
                      <Input
                        {...register('user_address_city', { required: true })}
                        placeholder="Ex: Goiânia"
                        size="md"
                        mb="8px"
                        type="text"
                        fontSize="sm"
                      />
                    </FormControl>
                  </SimpleGrid>
                </Box>
                <Divider />
                <Box w="100%">
                  <Heading fontSize="18px" mb="4">
                    Senha
                  </Heading>
                  <Text mb="16px" fontWeight="regular" fontSize="16px" fontFamily="Poppins" color={'gray.700'}>
                    Digite uma senha para confirmar as alterações
                  </Text>
                  <SimpleGrid columns={[3, null, 3]} spacing="32px" mb={4}>
                    <InputGroup size="md">
                      <Input
                        {...register('password', { required: true, maxLength: 20 })}
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        placeholder="Insira a senha"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? (
                            <ViewIcon w="22px" h="22px" color="text.gray" />
                          ) : (
                            <ViewOffIcon w="22px" h="22px" color="text.gray" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <InputGroup size="md">
                      <Input
                        {...register('passwordConfirmation', {
                          required: true,
                          validate: value => value === watch('password') || 'As senhas não coincidem',
                        })}
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        placeholder="Confirme a senha"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? (
                            <ViewIcon w="22px" h="22px" color="text.gray" />
                          ) : (
                            <ViewOffIcon w="22px" h="22px" color="text.gray" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </SimpleGrid>

                  {errors.password?.type === 'required' && (
                    <p role="alert" style={errorStyle}>
                      Este campo é Obrigatório
                    </p>
                  )}
                  {errors.passwordConfirmation?.type === 'required' && (
                    <p role="alert" style={errorStyle}>
                      Este campo é Obrigatório
                    </p>
                  )}
                  {errors.passwordConfirmation?.type === 'validate' && (
                    <p role="alert" style={errorStyle}>
                      {errors.passwordConfirmation.message}
                    </p>
                  )}
                </Box>
              </VStack>

              <Flex
                w="80%"
                mt="100px"
                justifySelf="flex-end"
                display="flex"
                flexDir="row"
                justifyContent="space-between"
              >
                <Button onClick={() => navigate('/')} variant="outline">
                  <BsArrowLeft style={{ marginRight: 10 }} />
                  Voltar
                </Button>

                <Button
                  disabled={Object.keys(errors)?.length !== 0 || Object.values(watch()).includes('')}
                  type="submit"
                  colorScheme="red"
                >
                  Associar
                  <BsArrowRight style={{ marginLeft: 10 }} />
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
        <Footer />
      </Box>
    </>
  )
}
