import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { SearchFilter } from '../../../components/inputs/filters/search';
import { DateFilter } from '../../../components/inputs/filters/date';
import { IconGeneralButton } from '../../../components/Buttons/IconGeneralButton';
import { VscChecklist } from 'react-icons/vsc';
import { DataGrid } from '../../../components/DataGrid';
import { useNavigate } from 'react-router-dom';
import { useBalanceData } from '../../../fetchs/balance';
import { apiCPT } from '../../../services/api';
import { formatAbrangency, formatCashclubs, formatDate, formatTransactionType } from '../../../utils/FormatFunctions';
import { ReturnButton } from '../../../components/Buttons/ReturnButton';

const Balance: React.FC = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [cashClubs, setCashClubs] = useState<any>({});
  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  });
  const [requestMade, setRequestMade] = useState(false);

  // Carregar os dados do usuário do localStorage
  useEffect(() => {
    const storedUserData = localStorage.getItem('__user_data');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);

  // Chamar a API de balance apenas se o user_id estiver disponível
  const { data, requestApiCPT, loading } = useBalanceData(userData?.user_id);

  useEffect(() => {
    const fetchBalanceData = async () => {
      if (userData?.user_id && !requestMade && !loading) {
        await requestApiCPT('get');
        setRequestMade(true);
      }
    };

    fetchBalanceData();
  }, [userData, requestMade, loading]);

  // Chamar a API de CashClubs apenas se o user_id estiver disponível
  useEffect(() => {
    const fetchData = async () => {
      if (userData?.user_id) {
        try {
          console.log('Antes da chamada do CashClubs API', userData.user_id);
          const getCashClubs = await apiCPT.get(`v1/cashclubs/pointsbalance/${userData.user_id}`);
          setCashClubs(getCashClubs?.data);
          console.log('Depois da chamada do CashClubs API', getCashClubs.data);
        } catch (error) {
          console.log('Erro ao buscar dados do CashClubs:', error);
        }
      } else {
        console.error('userData ou userData.user_id está vazio ou nulo');
      }
    };

    fetchData();
  }, [userData]);

  console.log(userData, 'userData');

  const columns = [
    { name: 'cc_id', label: 'Id da transação' },
    { name: 'deposited_at', label: 'Data de lançamento' },
    { name: 'expire_at', label: 'Data de vencimento' },
    { name: 'transaction_type', label: 'Tipo' },
    { name: 'abrangency', label: 'Abrangência' },
    { name: 'cashclubs', label: 'Viajex' },
  ];

  return (
    <Flex flexDir="column" ml={isMobile ? '0' : '4vw'} w={isMobile ? '100%' : '73vw'}>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <ReturnButton previousPage="panel/dashboard" />
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Extrato de Viajex
          </Text>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <Box
              w="190px"
              h="160px"
              mr="20px"
              boxShadow="xl"
              bg="#FFFFFF"
              borderRadius="15px"
              display="flex"
              flexDir="column"
              alignSelf="center"
              alignContent="center"
              alignItems="center"
              justifySelf="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Text fontSize="42px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                {Math.floor(cashClubs.saldoN)}
              </Text>
              <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                Nacional
              </Text>
            </Box>
            <Box
              w="190px"
              h="160px"
              mr="70px"
              boxShadow="xl"
              bg="#FFFFFF"
              borderRadius="15px"
              display="flex"
              flexDir="column"
              alignSelf="center"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Text fontSize="42px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                {Math.floor(cashClubs.saldoI)}
              </Text>
              <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                Internacional
              </Text>
            </Box>
          </Flex>
          <DataGrid
            isId={null}
            columns={columns}
            requestApiCPT={requestApiCPT}
            rows={data?.cashClubs || []}
            error={'Error'}
            baseUrl={userData?.user_id ? `v1/cashclubs/balance/${userData.user_id}` : ''}
            data={data?.cashClubs || []}
            hasCreate={false}
            loading={loading}
            columnWithAction={[
              {
                name: 'steps',
                columnAction: (rowItem: {
                  id: any;
                  total_comment:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <Flex align="center">
                    <IconGeneralButton
                      Icon={VscChecklist}
                      color="#1890FF"
                      fontSize="25px"
                      onClick={() => navigate(`${rowItem?.id}`)}
                    />
                    <Text fontWeight="bold">{rowItem?.total_comment}</Text>
                  </Flex>
                ),
              },
            ]}
            rowActions={{ show: false, edit: false, delete: false }}
            formatColum={[
              {
                field: 'deposited_at',
                formatField: formatDate,
              },
              {
                field: 'expire_at',
                formatField: formatDate,
              },
              {
                field: 'transaction_type',
                formatField: formatTransactionType,
              },
              {
                field: 'abrangency',
                formatField: formatAbrangency,
              },
              {
                field: 'cashclubs',
                formatField: (row: any, item: any) => formatCashclubs(row, item),
              },
            ]}
            headerFilters={[
              {
                name: 'search',
                filterComponent: SearchFilter,
              },
              {
                name: 'startDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data inicial',
              },
              {
                name: 'endDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data final',
              },
            ]}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Balance;
