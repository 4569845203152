/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Container, Flex, Image, Stack, Text } from '@chakra-ui/react'

import logo from '../../assets/logo.svg'
import { BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function ContactPage() {
  return (
    <Flex
      minH="100vh"
      bg="linear-gradient(225deg, rgba(236, 236, 236) 0%, rgba(236, 236, 236) 15%, rgba(236, 236, 236) 50%, rgba(236, 236, 236) 85%, rgba(236, 236, 236) 100%)"
    >
      <Container maxW="540px" p="10px">
        <Flex align="center" justify="center" mt="50px" mb="30px">
          <Link to="/">
            <Image maxH="200px" src={logo} />
          </Link>
        </Flex>
        <Flex
          maxW="500px"
          direction="column"
          align="center"
          mt="200px"
          justify="center"
          backgroundColor="rgba(236, 236, 236)"
          boxShadow="0px 20px 40px #2D3748"
          backdropFilter="blur(8px)"
          borderRadius={40}
          p="50px"
        >
          <Stack mt="20px" cursor="pointer" p="10px 20px" direction="row" align="center">
            <a target="_blank" href="https://api.whatsapp.com/send?phone=556230892500" rel="noreferrer">
              <Flex>
                <BsWhatsapp size={30} color="#25D366" />
                <Text fontWeight="black" pl="10px">
                  Clique aqui e entre em contato conosco
                </Text>
              </Flex>
            </a>
          </Stack>
        </Flex>
      </Container>
    </Flex>
  )
}
