import { Flex, Box, Divider, Text, Container } from '@chakra-ui/react';

export default function Whois() {
  return (
    <Flex w={'full'} h={'auto'}>
      <Container display={'flex'} justifyContent={'center'} maxW="100vw" color="black">
        <Box padding={['5', '10', '15']} color="black" maxW={['90vw', '80vw', '70vw']}>
          <Text as={'h1'} mt={4} mb={4} fontSize={'larger'} fontWeight={700} color={'back'}>
            Bem-vindo à Club Share!
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Quem Somos
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            Somos a Club Share, uma referência no mercado de multipropriedade, com uma história rica e inovadora com
            raízes desde 2010. Nossos fundadores e sócios são conhecidos por iniciarem o mercado de multipropriedade no
            Brasil, iniciando em cidade de Caldas Novas, Goiás. Hoje, com sede em Goiânia, GO, a Club Share atende
            empreendimentos de multipropriedade em todo o país, conectando o mercado nacional com empreendimentos
            turísticos ao redor do mundo.
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Nossa Missão
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            Na Club Share, reconhecemos que sua multipropriedade é um ativo inestimável. Por isso, nossa missão é
            valorizar nossos clientes, proporcionando acesso a um intercâmbio de férias 5.0. Nossos associados desfrutam
            de experiências de turismo global incomparáveis, indo além da simples troca de semanas para criar memórias
            inesquecíveis em destinos do mundo.
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Visão
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            Expandir globalmente nossa referência em soluções inovadoras de multipropriedade, consolidando nossa
            liderança já estabelecida no Brasil. Transformar a forma como as pessoas vivenciam o turismo, promovendo
            experiências inesquecíveis e sustentáveis, e criando valor excepcional para nossos associados, parceiros e
            comunidades.
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Valores
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            <p>
              <strong>Inovação Contínua:</strong> Estamos comprometidos em oferecer soluções pioneiras e tecnológicas
              para melhorar constantemente a experiência dos nossos associados.
            </p>
            <p>
              <strong>Excelência no Atendimento:</strong> Valorizamos nossos clientes, proporcionando um atendimento de
              alta qualidade e personalizado para atender suas necessidades e superar expectativas.
            </p>
            <p>
              <strong>Transparência e Integridade:</strong> Operamos com honestidade e clareza em todas as nossas
              relações, garantindo confiança e credibilidade junto aos nossos associados e parceiros.
            </p>
            <p>
              <strong>Experiência e Qualidade:</strong> Nos dedicamos a proporcionar experiências de turismo memoráveis
              e de alta qualidade, criando valor duradouro para nossos associados e suas famílias.
            </p>
            <p>
              <strong>Sustentabilidade:</strong> Promovemos práticas sustentáveis em todas as nossas operações, visando
              o bem-estar das futuras gerações.
            </p>
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Como Realizamos o Intercâmbio de Férias
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            Ao adquirir uma fração de uma multipropriedade, nossos clientes garantem o direito a uma semana ou mais por
            ano naquele hotel. Se o cliente perceber que não poderá viajar para o hotel onde possui a cota imobiliária
            no próximo ano, ou desejar explorar um novo destino, ele pode acessar nosso site e realizar o Depósito de
            Semana. Com o Depósito de Semana, a Club Share monetiza aquela semana específica através de Viajex. Através
            da Viajex, o cliente pode realizar o intercâmbio 5.0 de maneira fácil e flexível diretamente pelo nosso
            site. O sistema 5.0 da Club Share permite escolher entre uma diária, voos de ida ou de ida e volta, alugueis
            de carros, ingressos para parques e viagens de cruzeiro proporcionando uma experiência única e flexível no
            mercado de multipropriedade.
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            O Que Oferecemos
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            <p>Com a Viajex, você pode explorar todo o mundo do turismo:</p>
            <ul style={{ paddingLeft: '40px' }}>
              <li>Passagens aéreas para qualquer destino que desejar</li>
              <li>Aluguel de carros para maior mobilidade e conforto</li>
              <li>Cruzeiros incríveis pelos mais belos mares em todos os continentes</li>
              <li>Ingressos para parques temáticos de todo mundo</li>
            </ul>
            <p>
              Estamos presentes em todos os países, em inúmeros hotéis, prontos para transformar suas férias em uma
              experiência inesquecível.
            </p>
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Nosso Diferencial
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            A Club Share não é apenas uma empresa de intercâmbio. Oferecemos um clube de viagens exclusivo, com
            atendimento personalizado e especializado em turismo. Seja para destinos nacionais ou internacionais, nossa
            equipe capacitada está sempre pronta para esclarecer qualquer informação e transformar suas férias em
            realidade.
          </Text>
          <Text as={'h2'} mt={2} mb={2} fontSize={'larger'} fontWeight={700} color={'back'}>
            Por Que Escolher a Club Share?
          </Text>
          <Text as={'p'} fontWeight={400} color={'back'} align={'justify'}>
            No mercado de multipropriedade, a intercambiadora é essencial para o sucesso. Todas as salas de vendas no
            Brasil dependem de intercambiadora. Os clientes podem se interessar pela fração das multipropriedade, mas é
            a liberdade de viajar pelo mundo que realmente os empolga e fecha o negócio. Optar pela Club Share como sua
            intercambiadora é garantir o sucesso do seu empreendimento de multipropriedade. Nossa experiência, alcance
            global e compromisso com o cliente nos destacam como a melhor opção para você e seus clientes. Tenha o
            sucesso da sua propriedade em multipropriedade com o apoio da Club Share e leve seus clientes a explorar o
            mundo. Junte-se a nós e descubra como podemos tornar suas férias e seu negócio uma experiência única e
            lucrativa.
          </Text>
        </Box>
      </Container>
    </Flex>
  );
}
