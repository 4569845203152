import React, { useState } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

interface InputInterface {
  name: string | undefined | any
  label: string | undefined
  iconLeft?: JSX.Element
  styles?: any
  labelStyles?: any
  buttonStyles?: any
  isRequired?: boolean
  placeholder?: string | undefined
  register: any
  errors: any
}

export const PasswordInputForm = ({
  name,
  label,
  iconLeft,
  styles = {},
  labelStyles = {},
  buttonStyles = {},
  isRequired = false,
  placeholder,
  register,
  errors,
  ...props
}: InputInterface) => {
  const [show, setShow] = useState<any>(false)

  const handleClick = () => setShow(!show)
  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={2}>
      <FormLabel style={labelStyles} fontWeight="bold" mb={1} htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup alignItems="center" alignContent="center">
        {iconLeft && (
          <InputLeftElement pointerEvents="none" fontSize="1.2em">
            {iconLeft}
          </InputLeftElement>
        )}
        <Input
          id={name}
          placeholder={placeholder}
          type={show ? 'text' : 'password'}
          boxShadow="0px 3px 6px #2D3748"
          background="theme.light.bodyInput"
          borderRadius="full"
          style={styles}
          {...register(name)}
          {...props}
        />
        <InputRightElement fontSize="1.2em">
          <IconButton
            zIndex={1}
            aria-label=""
            style={buttonStyles}
            _focus={{ outline: 'none' }}
            variant="outline"
            border="none"
            colorScheme="none"
            onClick={handleClick}
          >
            {show ? (
              <ViewIcon w="22px" h="22px" color="text.gray" />
            ) : (
              <ViewOffIcon w="22px" h="22px" color="text.gray" />
            )}
          </IconButton>
        </InputRightElement>
      </InputGroup>
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  )
}
