import React from 'react'
import { Box, CircularProgress, Image, Text } from '@chakra-ui/react'

const LoaderCheckout = () => {
  return (
    <Box
      bg="#000000"
      width="100%"
      minHeight="100%"
      h="800px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box marginTop="100px" display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="white" isIndeterminate size={150} />
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: '25px',
            marginTop: '30px',
            color: 'white',
          }}
        >
          Confirmando sua reserva.
        </Text>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            marginTop: '30px',
            color: 'white',
          }}
        >
          Aguarde...
        </Text>
        <Image src={'../../assets/logoreserva.svg'} w="400px" h="200px" />
      </Box>
    </Box>
  )
}

export default LoaderCheckout
