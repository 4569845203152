/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useForm, useController } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { BiLeftArrow, BiSave } from 'react-icons/bi';
import ConfirmExport from './ConfirmExport';
import { useFetchCPT } from '../../../hooks/useFetchCPT';
import { apiCPT } from '../../../services/api';
import moment from 'moment';
import 'moment/locale/pt-br';

interface UserFormProps {
  defaultValues?: any;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string;
  yup: any;
}

export default function UserForm({ defaultValues = {} }: UserFormProps) {
  const baseUrl = 'v1/users';
  const { requestApiCPT, error, data } = useFetchCPT(baseUrl);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const storedUserData = localStorage.getItem('__user_data');
  const [itemId, setItemId] = useState<string | null>(null); // Inicializa com null

  useEffect(() => {
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setItemId(parsedData.user_id); // Extrai o ID do usuário e define no estado
      } catch (e) {
        console.error('Erro ao parsear o JSON do localStorage', e);
      }
    }
  }, [storedUserData]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [images, setImages] = useState<any>('');
  const [uploading, setUploading] = useState<any>(false);
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const toast = useToast();
  const [idExport, setIdExport] = useState<string | null>(null);
  const [valuesExports, setValuesExports] = useState({});
  console.log(setImages);
  const formInputs: FormInputsProps[] = [
    {
      name: 'user_firstname',
      value: defaultValues.user_firstname ?? '',
      placeholder: '',
      label: 'Primeiro Nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'saldoN',
      value: defaultValues.saldoN ?? 0,
      placeholder: '',
      label: 'Saldo Viajex Nacionais',
      yup: Yup.string(),
    },
    {
      name: 'saldoI',
      value: defaultValues.saldoI ?? 0,
      placeholder: '',
      label: 'Saldo Viajex Internacionais',
      yup: Yup.string(),
    },
    {
      name: 'user_lastname',
      value: defaultValues.user_lastname ?? '',
      placeholder: '',
      label: 'Segundo nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_type',
      value: defaultValues?.user_type ?? 1,
      placeholder: '',
      label: 'Tipo de usuário',
      yup: Yup.number().required('Este campo é obrigatório!').min(1, 'Este campo é obrigatório'),
    },
    {
      name: 'user_active',
      value: defaultValues?.user_active ?? 1,
      placeholder: '',
      label: 'Ativo?',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'user_cpf',
      value: defaultValues.user_cpf ?? '',
      placeholder: '',
      label: 'EX:. 000.000.000-00',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          'CPF inválido',
        ),
    },
    {
      name: 'user_telephone',
      value: defaultValues.user_telephone ?? '',
      placeholder: '',
      label: 'EX:. (00) 00000-0000',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
          'Telefone inválido',
        ),
    },
    {
      name: 'email',
      value: defaultValues.email ?? '',
      placeholder: '',
      label: 'E-mail',
      yup: Yup.string().required('Este campo é obrigatório!').email('E-mail inválido'),
    },
    {
      name: 'user_birthdate',
      value: defaultValues.user_birthdate ? moment(defaultValues.user_birthdate).format('DD/MM/yyyy') : null,

      placeholder: '',
      label: 'Data de nascimento',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'user_address',
      value: defaultValues.user_address ?? '',
      placeholder: '',
      label: 'Endereço',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_zipcode',
      value: defaultValues.user_address_zipcode ?? '',
      placeholder: '',
      label: 'CEP',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/(\d{2}[.]?\d{3})[-]?(\d{3})/, 'CEP inválido'),
    },
    {
      name: 'user_address_complement',
      value: defaultValues.user_address_complement ?? '',
      placeholder: '',
      label: 'Complemento',
      yup: Yup.string(),
    },
    {
      name: 'user_address_number',
      value: defaultValues.user_address_number ?? '',
      placeholder: '',
      label: 'Numero',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'user_address_neighborhood',
      value: defaultValues.user_address_neighborhood ?? '',
      placeholder: '',
      label: 'Bairro',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_city',
      value: defaultValues.user_address_city ?? '',
      placeholder: '',
      label: 'Cidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_state',
      value: defaultValues.user_address_state ?? '',
      placeholder: '',
      label: 'Estado',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_country',
      value: defaultValues.user_address_country ?? '',
      placeholder: '',
      label: 'Pais',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_country',
      value: defaultValues.user_country ?? '',
      placeholder: '',
      label: 'Nacionalidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const formatDate = (date: string | Date): string => {
    return typeof date === 'string' ? moment(date).format('DD/MM/YYYY') : moment(date).format('DD/MM/YYYY');
  };

  const user_firstname = formInputs.find(field => field.name === 'user_firstname');
  const saldoN = formInputs.find(field => field.name === 'saldoN');
  const saldoI = formInputs.find(field => field.name === 'saldoI');
  const user_lastname = formInputs.find(field => field.name === 'user_lastname');
  const user_type = formInputs.find(field => field.name === 'user_type');
  const user_cpf = formInputs.find(field => field.name === 'user_cpf');
  const user_telephone = formInputs.find(field => field.name === 'user_telephone');
  const email = formInputs.find(field => field.name === 'email');
  const user_birthdate = formInputs.find(field => field.name === 'user_birthdate');
  const user_address = formInputs.find(field => field.name === 'user_address');
  const user_address_complement = formInputs.find(field => field.name === 'user_address_complement');
  const user_address_number = formInputs.find(field => field.name === 'user_address_number');
  const user_address_neighborhood = formInputs.find(field => field.name === 'user_address_neighborhood');
  const user_address_city = formInputs.find(field => field.name === 'user_address_city');
  const user_address_state = formInputs.find(field => field.name === 'user_address_state');
  const user_address_country = formInputs.find(field => field.name === 'user_address_country');
  const user_address_zipcode = formInputs.find(field => field.name === 'user_address_zipcode');
  const user_active = formInputs.find(field => field.name === 'user_active');
  const user_country = formInputs.find(field => field.name === 'user_country');

  const onSubmit = async (values: any) => {
    if (!itemId) {
      toast({
        title: `Erro ao enviar dados.`,
        description: 'ID do usuário não encontrado.',
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    // Formata o campo user_birthdate
    const formattedValues = {
      ...values,
      user_birthdate: values.user_birthdate ? moment(values.user_birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
    };

    const response = await requestApiCPT(
      defaultValues.user_firstname ? 'put' : 'post',
      { ...formattedValues, image: _ },
      defaultValues.user_firstname ? `${baseUrl}/${itemId}` : null,
    );

    if (response.status < 203) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      if (!error && images) {
        setUploading(true);
        const apiUrlId = itemId ?? response?.data?.user_id;
        await requestApiCPT('put', { file: images.toString() }, `${baseUrl}/${apiUrlId}/image`);
        setUploading(false);
      }
      navigate('/panel/dashboard');
    } else if (response.status === 203) {
      setIdExport(response.data.message.id);
      setValuesExports(values);
      onOpen();
    } else {
      toast({
        title: `Ocorreu um erro ao executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleExport = async () => {
    const data = {
      ...valuesExports,
      user_id: idExport,
      user_type: 3,
      user_active: 1,
    };

    const response = await requestApiCPT('post', data, `/v1/export`);

    if (response.status < 203) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      navigate('/users');
    } else {
      toast({
        title: `Ocorreu um erro ao executar a ação!`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (defaultValues.user_firstname) {
      reset(defaultValuesSchema);
    }
    const getInfos = async () => {
      if (itemId) {
        await requestApiCPT('get', null, `v1/cashclubs/pointsbalance/${itemId}`);
      }
    };
    getInfos();
  }, [defaultValues, itemId]);

  const [roles, setRoles] = useState([] as any);

  const getRoles = async () => {
    try {
      const res = await apiCPT.get('v1/roles', { params: { perPage: 999999 } });

      if (res?.status === 200 || res?.status === 201) {
        setRoles(res?.data?.roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const dataActive = [
    { id: 0, name: 'Inativo' },
    { id: 1, name: 'Ativo' },
  ];

  const { field: userCpfField, fieldState: userCpfFieldState } = useController({
    name: 'user_cpf',
    control,
    defaultValue: '',
  });
  const { field: userTelephoneField, fieldState: userTelephoneFieldState } = useController({
    name: 'user_telephone',
    control,
    defaultValue: '',
  });
  const { field: emailField, fieldState: emailFieldState } = useController({
    name: 'email',
    control,
    defaultValue: '',
  });
  const { field: userBirthdateField, fieldState: userBirthdateFieldState } = useController({
    name: 'user_birthdate',
    control,
    defaultValue: '',
  });
  const { field: userAddressZipcodeField, fieldState: userAddressZipcodeFieldState } = useController({
    name: 'user_address_zipcode',
    control,
    defaultValue: '',
  });

  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  });

  const formatPhoneNumber = (phoneNumber: any) => {
    const numericValue = phoneNumber.replace(/\D/g, ''); // Remover caracteres não numéricos

    if (numericValue.startsWith('55')) {
      // Se começar com 55, assumimos que é DDI do Brasil
      return `+${numericValue.slice(0, 2)} (${numericValue.slice(2, 4)}) ${numericValue.slice(
        4,
        9,
      )}-${numericValue.slice(9)}`;
    } else if (numericValue.length === 13) {
      // Se tiver 13 dígitos, assumimos que já inclui DDI e DDD
      return `+${numericValue.slice(0, 2)} (${numericValue.slice(2, 4)}) ${numericValue.slice(
        4,
        9,
      )}-${numericValue.slice(9)}`;
    } else if (numericValue.length >= 11) {
      // Se tiver 11 ou mais dígitos, assumimos que é DDD + número
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7)}`;
    } else {
      // Caso contrário, mantemos o formato padrão
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 6)}-${numericValue.slice(6)}`;
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex p="20px" flexDir="column" ml={isMobile ? '0' : '4vw'} w={isMobile ? '100%' : '73vw'}>
        <ConfirmExport isOpen={isOpen} event={handleExport} onOpen={onOpen} onClose={onClose} />
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb={isMobile ? '20px' : '100px'}>
          {isMobile ? (
            formInputs.map((input, index) => (
              <FormControl key={index} mb="4">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  {input.label}
                </FormLabel>
                <Input
                  id={input.name}
                  placeholder={input.label}
                  isRequired
                  {...register(input.name, { ...input.yup })}
                />
                <FormErrorMessage>{`${errors[input.name]?.message}`}</FormErrorMessage>
              </FormControl>
            ))
          ) : (
            <Flex flexDir="column" mb="40px">
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Informações básicas
              </Text>

              <SimpleGrid columns={3} spacing={1}>
                <Box maxW="md" mr="1">
                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Saldo de Viajex Nacionais
                    </FormLabel>
                    <Input
                      id="saldoN"
                      placeholder={`${saldoN}`}
                      {...register('saldoN', errors as any)}
                      value={Math.floor(data?.saldoN) ?? 0}
                      isInvalid={errors.saldoN && true}
                      isRequired
                      disabled
                    />
                  </FormControl>
                </Box>
                <Box maxW="md" ml="1">
                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Saldo de Viajex Internacionais
                    </FormLabel>
                    <Input
                      id="saldoI"
                      placeholder={`${saldoI}`}
                      value={Math.floor(data?.saldoI) ?? 0}
                      {...register('saldoI', errors as any)}
                      isInvalid={errors.saldoI && true}
                      isRequired
                      disabled
                    />
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_active && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      {user_active?.label}
                    </FormLabel>
                    <Select isRequired disabled {...register('user_active', errors as any)}>
                      {dataActive.map((group, index) => (
                        <option key={index} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{`${errors.user_type?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
              </SimpleGrid>
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Informações pessoais e de contato
              </Text>
              <SimpleGrid columns={3} spacing={1}>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_firstname && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Primeiro nome
                    </FormLabel>
                    <Input
                      id="user_firstname"
                      placeholder={user_firstname?.label}
                      isRequired
                      {...register('user_firstname', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_firstname?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_lastname && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Segundo nome
                    </FormLabel>
                    <Input
                      id="user_lastname"
                      placeholder={user_lastname?.label}
                      isRequired
                      {...register('user_lastname', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_lastname?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_type && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      {user_type?.label}
                    </FormLabel>
                    <Select disabled value={3} defaultValue={3} isRequired {...register('user_type', errors as any)}>
                      {roles?.map((group: any, index: any) => (
                        <option key={index} value={group.role_id}>
                          {group.role_name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{`${errors.user_type?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={3} spacing={1} style={{ marginTop: 15 }}>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_country && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Nacionalidade
                    </FormLabel>
                    <Input
                      id="user_country"
                      placeholder={user_country?.label}
                      isRequired
                      {...register('user_country', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_country?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={userCpfFieldState.invalid}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      CPF
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="999.999.999-99"
                      id="user_cpf"
                      placeholder={user_cpf?.label}
                      isRequired
                      {...userCpfField}
                    />
                    <FormErrorMessage>{`${userCpfFieldState.error?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={userTelephoneFieldState.invalid}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Telefone
                    </FormLabel>
                    <Input
                      id="user_telephone"
                      placeholder={user_telephone?.label}
                      isRequired
                      value={formatPhoneNumber(userTelephoneField.value)}
                      onChange={e => {
                        const formattedValue = formatPhoneNumber(e.target.value);
                        userTelephoneField.onChange(formattedValue);
                      }}
                    />
                    <FormErrorMessage>{`${userTelephoneFieldState.error?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={emailFieldState.invalid}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      E-mail
                    </FormLabel>
                    <Input id="email" placeholder={email?.label} isRequired {...emailField} />
                    <FormErrorMessage>{`${emailFieldState.error?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={userBirthdateFieldState.invalid}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Data de nascimento
                    </FormLabel>

                    <Input
                      id="user_birthdate"
                      placeholder={user_birthdate?.label}
                      isRequired
                      as={InputMask}
                      //@ts-ignore
                      mask={(e: string | Date) => formatDate(e)}
                      {...userBirthdateField}
                    />
                    <FormErrorMessage>{`${userBirthdateFieldState.error?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
              </SimpleGrid>
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Informações de endereço
              </Text>
              <SimpleGrid columns={4} spacing={2}>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_address && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Endereço
                    </FormLabel>
                    <Input
                      id="user_address"
                      placeholder={user_address?.label}
                      isRequired
                      {...register('user_address', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_address_complement && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Complemento
                    </FormLabel>
                    <Input
                      id="user_address_complement"
                      placeholder={user_address_complement?.label}
                      isRequired
                      {...register('user_address_complement', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_complement?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={errors.user_address_neighborhood && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Bairro
                    </FormLabel>
                    <Input
                      id="user_address_neighborhood"
                      placeholder={user_address_neighborhood?.label}
                      isRequired
                      {...register('user_address_neighborhood', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_neighborhood?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="md" mr="1">
                  <FormControl isInvalid={userAddressZipcodeFieldState.invalid}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      CEP
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="99.999-999"
                      id="user_address_zipcode"
                      placeholder={user_address_zipcode?.label}
                      isRequired
                      {...userAddressZipcodeField}
                    />
                    <FormErrorMessage>{`${userAddressZipcodeFieldState.error?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="sm" mr="1">
                  <FormControl isInvalid={errors.user_address_number && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Numero
                    </FormLabel>
                    <Input
                      id="user_address_number"
                      placeholder={user_address_number?.label}
                      isRequired
                      {...register('user_address_number', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_number?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="sm" mr="1">
                  <FormControl isInvalid={errors.user_address_city && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Cidade
                    </FormLabel>
                    <Input
                      id="user_address_city"
                      placeholder={user_address_city?.label}
                      isRequired
                      {...register('user_address_city', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_city?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="sm" mr="1">
                  <FormControl isInvalid={errors.user_address_state && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Estado
                    </FormLabel>
                    <Input
                      id="user_address_state"
                      placeholder={user_address_state?.label}
                      isRequired
                      {...register('user_address_state', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_state?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box maxW="sm" mr="1">
                  <FormControl isInvalid={errors.user_address_country && true}>
                    <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                      País
                    </FormLabel>
                    <Input
                      id="user_address_country"
                      placeholder={user_address_country?.label}
                      isRequired
                      {...register('user_address_country', errors as any)}
                    />
                    <FormErrorMessage>{`${errors.user_address_country?.message}`}</FormErrorMessage>
                  </FormControl>
                </Box>
              </SimpleGrid>
            </Flex>
          )}
        </Box>
        <Box mb={isMobile ? '20px' : '200px'} display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/panel/dashboard')}
            leftIcon={<BiLeftArrow />}
            colorScheme="red"
            variant="outline"
          >
            Cancelar e voltar
          </Button>
          <Button
            isLoading={isSubmitting || uploading}
            type="submit"
            rightIcon={<BiSave />}
            colorScheme="red"
            variant="solid"
          >
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  );
}
