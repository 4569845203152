import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
// import { fonts } from './fonts'

export const theme = extendTheme({
  styles: {
    global: {
      body: { backgroundColor: 'gray.50' },
    },
  },
  breakpoints: {
    sm: '48em',
    md: '48em',
    lg: '62em',
    xl: '96em',
  },
  colors,
  fonts: { body: 'Inter, sans-serif', heading: 'Poppins, sans-serif' },
  components: {
    Button: {
      colors: {
        brand: {
          50: '#171923',
          100: '#171923',
          200: '#171923',
          300: '#171923',
          900: '#171923',
        },
      },
      baseStyle: {
        fontWeight: 'medium',
      },
    },
    Container: {
      sizes: {
        xl: {
          maxW: '1280px',
        },
        x2: {
          maxW: '800px',
        },
      },
    },
  },
})
