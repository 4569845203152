/* eslint-disable radix */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Flex, Text } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FormButton } from '../../Buttons/FormButton'
import Timer from '../../Timer'
import { UsernameInputForm } from '../../inputs/UsernameInputForm'
import { PinInputForm } from '../../inputs/PinInputForm'
import { PasswordInputForm } from '../../inputs/PasswordInputForm'
import { useFetchCPT } from '../../../hooks/useFetchCPT'

export const ResetPassEmailForm = ({
  onChangeResetStatePin: onChangePin,
  setEmail,
}: {
  onChangeResetStatePin: () => void
  setEmail: (email: string) => void
}) => {
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const { requestApiCPT } = useFetchCPT('v1/public/recover_pass?type_token=hash_code')
  const navigate = useNavigate()

  const validateEmail = (email: string | undefined) => {
    return Yup.string().email().isValidSync(email)
  }

  const validatePhone = (phone: string | undefined) => {
    return Yup.string()
      .matches(/^\([1-9]{2}\) (9[1-9])[0-9]{3}-[0-9]{4}/)
      .isValidSync(phone)
  }

  const formInputs = [
    {
      name: 'username',
      value: '',
      placeholder: '',
      label: 'E-mail ou celular',
      yup: Yup.string()
        .required('Campo obrigatório.')
        .test('username', 'Email/Telefone é inválido', value => {
          return validateEmail(value) || validatePhone(value)
        }),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const onSubmit = async (values: any) => {
    const response = await requestApiCPT('post', values)
    if (response.status === 200 && response?.data?.data?.email) {
      await setEmail(response?.data?.data?.email)
      onChangePin()
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {formInputs.map(i => (
        // eslint-disable-next-line react/jsx-key
        <UsernameInputForm
          name={i?.name}
          label={i?.label}
          styles={{ color: 'white', borderRadius: '150px' }}
          labelStyles={{ color: 'white' }}
          placeholder={i?.placeholder}
          control={control}
          errors={errors}
          isRequired
        />
      ))}

      <Flex align="center" justify="space-between" mt={20}>
        <FormButton
          onClick={() => navigate('/login')}
          bgColor="transparent"
          isLoading={isSubmitting}
          variant="link"
          boxShadow="none"
          color="text.light.yellow"
          isDisabled={isSubmitting}
        >
          Voltar
        </FormButton>

        <FormButton borderRadius="full" w="150px" isLoading={isSubmitting} type="submit">
          Enviar
        </FormButton>
      </Flex>
    </form>
  )
}

export const ResetPassPinForm = ({
  onChangeResetStatePin: onChangePin,
  onChangeResetStatePassword: onChangePassword,
  setToken,
  email,
}: {
  onChangeResetStatePin: () => void
  onChangeResetStatePassword: () => void
  setToken: (token: string) => void
  email: string
}) => {
  const { requestApiCPT } = useFetchCPT('v1/public/recover_pass/code')
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const [minutes, setMinutes] = useState(3)
  const [seconds, setSeconds] = useState(0)

  const formInputs = [
    {
      name: 'code',
      value: '',
      placeholder: '',
      label: 'Código',
      yup: Yup.string().required('Campo obrigatório.'),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    control,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const onSubmit = async (values: any) => {
    const response = await requestApiCPT('post', {
      ...values,
      email,
    })
    if (response.status === 200 && response?.data?.data?.token_update) {
      await setToken(response?.data?.data?.token_update)
      onChangePassword()
    }
    if (response?.response?.data?.msg === 'Invalid code') {
      setError('code', { message: 'Código inválido. Tente novamente' })
    }
    if (response?.response?.data?.msg === 'Expired validation code') {
      setError('code', { message: 'Código expirado. Tente novamente' })
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {formInputs.map(i => (
        <PinInputForm
          key={i?.name}
          name={i?.name}
          label={i?.label}
          styles={{ color: 'white' }}
          labelStyles={{ color: 'white' }}
          numberOfFields={5}
          control={control}
          errors={errors}
          isRequired
        />
      ))}

      <Flex align="center" mt={10}>
        <Text color="text.light.tertiary" fontWeight="bold" mr="10px">
          {!!minutes || !!seconds ? 'Código expira em:' : 'Código expirado: '}
        </Text>
        <Timer minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds} />
      </Flex>

      <Flex align="center" justify="space-between" mt={10}>
        <FormButton
          onClick={onChangePin}
          bgColor="transparent"
          isLoading={isSubmitting}
          variant="link"
          boxShadow="none"
          color="text.light.yellow"
          isDisabled={isSubmitting}
        >
          Voltar
        </FormButton>

        <FormButton
          borderRadius="full"
          w="150px"
          isDisabled={watch('code').length < 5}
          isLoading={isSubmitting}
          type="submit"
        >
          Enviar
        </FormButton>
      </Flex>
    </form>
  )
}

export const ResetPassPasswordForm = ({
  onChangeResetStatePassword: onChangePassword,
  email,
  token,
}: {
  onChangeResetStatePassword: () => void
  email: string
  token: string
}) => {
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const { requestApiCPT } = useFetchCPT('v1/public/recover_pass/reset?type_token=hash_code')
  const navigate = useNavigate()

  const formInputs = [
    {
      name: 'password',
      value: '',
      placeholder: '',
      label: 'Nova senha',
      yup: Yup.string()
        .required('Campo obrigatório.')
        .min(6, 'Senha deve conter pelo menos 6 caracteres.')
        .max(20, 'Senha deve conter no máximo 20 caracteres.'),
    },
    {
      name: 'confirmPassword',
      value: '',
      placeholder: '',
      label: 'Digite novamente',
      yup: Yup.string()
        .required('Campo obrigatório.')
        .oneOf([Yup.ref('password'), null], 'As senhas não são iguais'),
    },
  ]

  formInputs.map(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
    return true
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const onSubmit = async (values: any) => {
    const response = await requestApiCPT(
      'post',
      {
        ...values,
        email,
        token_update: token,
        confirmPassword: _,
      },
      null,
    )
    if (response.status === 200 && response?.data?.msg === 'Password change successfully') {
      navigate('/login', {
        state: { isBacktoHotel: false },
      })
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {formInputs.map(i => (
        <PasswordInputForm
          key={i?.name}
          name={i?.name}
          label={i?.label}
          styles={{ color: 'white', background: 'none' }}
          labelStyles={{ color: 'white' }}
          buttonStyles={{ color: 'white' }}
          placeholder={i?.placeholder}
          register={register}
          errors={errors}
          isRequired
        />
      ))}

      <Flex align="center" justify="space-between" mt={20}>
        <FormButton
          onClick={onChangePassword}
          bgColor="transparent"
          isLoading={isSubmitting}
          variant="link"
          boxShadow="none"
          color="text.light.yellow"
          isDisabled={isSubmitting}
        >
          Voltar
        </FormButton>

        <FormButton borderRadius="full" w="150px" isLoading={isSubmitting} type="submit">
          Enviar
        </FormButton>
      </Flex>
    </form>
  )
}
