import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useColorModeValue,
  useToast,
  Input,
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Image,
  FormControl,
} from '@chakra-ui/react'
import { apiCPT } from '../../services/api'
import logo from '../../assets/logo.svg'

export default function Register() {
  const navigate = useNavigate()
  const toast = useToast()
  const titleColor = useColorModeValue('gray.900', 'teal.200')
  const textColor = useColorModeValue('gray.700', 'white')
  const [email, setEmail] = useState(null)
  const [cpf, setCPf] = useState(null)

  function handleClick() {
    if (!(email || cpf)) {
      toast({
        title: 'Aviso',
        description: 'Informe o seu e-mail cadastrado',
        status: 'error',
        duration: 4000,
        isClosable: false,
      })
      return
    }

    toast({
      title: 'Aviso',
      description: 'Processando credenciais..',
      status: 'info',
      duration: 4000,
      isClosable: false,
    })

    apiCPT
      .post('/v1/login', {
        email: email == '' || email == null ? null : email,
        password: 'randompassword:pwd',
        grant_type: 'password',
        cpf: cpf == '' || cpf == null ? null : cpf?.replace(/\D/g, ''),
      })
      .then(res => {
        if (res?.status == 200) {
          localStorage?.setItem('token', JSON.stringify(res?.data))
          apiCPT.get(`v1/users/${res?.data?.user?.user_id}`).then(payload => {
            toast({
              title: 'Aviso',
              description: 'Acesso Permitido! Redirecionando..',
              status: 'success',
              duration: 500,
              isClosable: false,
            })
            localStorage?.setItem('__user_data', JSON?.stringify(payload?.data))
            navigate('/', { replace: true })
          })
        }
        if (res?.status == 203) {
          toast({
            title: 'Usuário encontrado',
            description: 'Vamos atualizar seus dados agora!',
            status: 'info',
            duration: 4000,
            isClosable: false,
          })
          navigate('/updateOldClient', { state: { data_user: res?.data?.user, update_user: true } })
        } else {
          toast({
            title: 'Nenhuma conta registrada',
            description: 'Entre em contato conosco',
            status: 'info',
            duration: 4000,
            isClosable: false,
          })
          navigate('/', { replace: true })
        }
      })
      .catch(res => {
        console.log(res)
        toast({
          title: 'Aviso',
          description: 'Credenciais Inválidas!',
          status: 'error',
          duration: 4000,
          isClosable: false,
          render: console.log(res),
        })
      })
  }

  function handleAssociationClick() {
    navigate('/association')
  }

  const startsWithNumbers = value => /^\d/.test(value)

  const formatCPF = value => {
    const formattedValue = value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') // Aplica a máscara do CPF

    return formattedValue
  }

  return (
    <Flex position="relative" mb="40px">
      <Flex w="100%" maxW="1044px" mx="auto" justifyContent="space-between" mb="40px">
        <Flex alignItems="center" justifyContent="start" w={{ base: '100%', md: '50%', lg: '42%' }}>
          <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: '150px', lg: '80px' }}>
            <Heading size="md">
              <a href="/" style={{ cursor: 'pointer' }}>
                <Image w="250px" mb={7} src={logo} />
              </a>
            </Heading>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Boas-vindas
            </Heading>
            <Text mb="36px" color={textColor} fontWeight="bold" fontSize="16px" fontFamily="Poppins">
              Insira um email para que possamos verificar se já existe algum cadastro realizado na nossa antiga
              plataforma.
            </Text>
            <FormControl>
              <FormControl mb="20px">
                <Input
                  onKeyPress={e => e.key === 'Enter' && handleClick()}
                  onChange={e => setEmail(e.target.value)}
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type={'text'}
                  placeholder={`Seu Email cadastrado`}
                  size="lg"
                />
                <Input
                  onKeyPress={e => e.key === 'Enter' && handleClick()}
                  value={cpf}
                  onChange={p => {
                    const inputValue = p.target.value

                    if (startsWithNumbers(inputValue) && inputValue.length === 11) {
                      // Se começa com números e tem o mesmo comprimento de um CPF
                      setCPf(formatCPF(inputValue))
                    } else {
                      setCPf(inputValue)
                    }
                  }}
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type={'text'}
                  placeholder={`Seu CPF cadastrado`}
                  size="lg"
                />
              </FormControl>

              <Button
                onClick={() => handleClick()}
                fontSize="16px"
                type="submit"
                bg="red.500"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: 'red.600',
                }}
                _active={{
                  bg: 'red.700',
                }}
              >
                Verificar
              </Button>
              <Button
                onClick={() => handleAssociationClick()}
                fontSize="16px"
                type="button"
                bg="blue.500"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                _active={{
                  bg: 'blue.700',
                }}
              >
                Solicitar Associação
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={'../../assets/imglogin.png'}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  )
}
