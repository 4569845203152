import React, { useState, useEffect } from 'react'
// eslint-disable-next-line
import { useCookies } from 'react-cookie'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Container,
  Divider,
  Heading,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookie-consent'])
  const [showBanner, setShowBanner] = useState(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  useEffect(() => {
    if (!cookies['cookie-consent']) {
      setShowBanner(true)
    }
  }, [cookies])

  const acceptCookies = () => {
    setCookie('cookie-consent', true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    })
    setShowBanner(false)
  }

  return (
    <>
      {showBanner && (
        <Box position="fixed" bottom={0} left={0} width="100%" py={4} zIndex={1000}>
          <Container
            maxW={1280}
            mb={isLargerThan768 ? 0 : '50px'}
            display="flex"
            alignItems="flex-start"
            flexDir={{ base: 'row', md: 'row' }}
          >
            <Card shadow="dark-lg">
              <CardBody>
                <Stack>
                  <Heading size="sm">Política de cookies</Heading>
                  <Text fontSize="sm">
                    Este site utiliza cookies para melhorar sua experiência. Ao prosseguir esta consentindo!
                  </Text>
                </Stack>
              </CardBody>
              <Divider />
              <CardFooter alignContent="space-between">
                <Container>
                  <Text color="blue.400" fontSize="sm" textAlign="left">
                    &copy; {new Date().getFullYear()} clubshare. Todos os direitos reservados.
                  </Text>
                  <Text color="blue.400" fontSize="sm" textAlign="left" mt="5px">
                    <Link color="brandBlue.200" href="/politica-de-privacidade">
                      Política de Privacidade
                    </Link>{' '}
                    |{' '}
                    <Link color="brandBlue.200" href="/terms">
                      Termos de Uso
                    </Link>
                  </Text>
                </Container>
                <ButtonGroup spacing="2">
                  <Button onClick={acceptCookies} variant="solid" colorScheme="blue">
                    Prosseguir
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </Container>
        </Box>
      )}
    </>
  )
}

export default CookieConsent
