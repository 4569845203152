/* eslint-disable react/jsx-key */
import { useEffect } from 'react'
import { Box, Button, Center, GridItem, Text, HStack, VStack, List, ListIcon, ListItem, Image } from '@chakra-ui/react'
import { FaCalendarDay, FaGift, FaUsers } from 'react-icons/fa'
import { GoLocation } from 'react-icons/go'
import { BiHeart } from 'react-icons/bi'
import { BsStarFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../providers/LanguageProvider'
import { translates } from '../../utils/languague'
import { useCotation } from '../../providers/CotationProvider'
import { useSearch } from '../../providers/SearchProvider'
import { calcHops } from '../../utils/hotelsSearch'

export default function PackageCardH({
  data = [],
  allIncluse = false,
  // points = '',
  img = '',
}) {
  const sliptName = data?.Name?.split('(', 2)
  const { language } = useLanguage()
  const { nNights, rooms } = useSearch()
  const { cotation, isCotation, points, markupC } = useCotation()

  const typeCotation = () => {
    if (isCotation == 'USD') {
      const base = data?.Rooms[0]?.TotalSellingPrice?.Value
      const cot = cotation
      const calc = base / cot
      const cotMarkup = calc * (markupC / 100)
      const result = Math.ceil(cotMarkup + calc)
      return result?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
    if (isCotation == 'BRL') {
      return data?.Rooms[0]?.TotalSellingPrice?.Value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
    if (isCotation == 'POINTS') {
      const base = data?.Rooms[0]?.TotalSellingPrice?.Value
      const cot = points
      // eslint-disable-next-line prettier/prettier
      const calc = Math.ceil(base * cot)
      return calc
    }
    return data?.Rooms[0]?.TotalSellingPrice?.Value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  useEffect(() => {
    typeCotation()
  }, [isCotation])
  return (
    <Link to={`/${data?.HotelId}/details`} state={{ itemId: data?.HotelId }}>
      <GridItem
        display="flex"
        alignItems="flex-start"
        borderRadius="12px"
        borderWidth={data?.IsRecommended ? 2 : 0}
        borderColor={data?.IsRecommended ? 'red' : 'transparent'}
        align="center"
        w={['100%', '100%']}
        flexDirection={['column', 'row']}
        height={['620px', '288px']}
      >
        {data?.Rooms[0] && (
          <>
            <Box
              display="flex"
              p={['10px', '20px']}
              bgSize="cover"
              justifyContent="start"
              borderRadius={['10px 10px 0px 0px', '10px 0px 0px 10px']}
              w={['100%', '50%']}
              h={['400px', '100%']}
              bgImg={img}
              bgRepeat="no-repeat"
            >
              {data?.Rooms[0]?.Promotions && (
                <Box align="start" h="45px" flexDir="row" borderRadius="5px" bg="orange" p="10px">
                  <Center>
                    <Text fontWeight="bold" color="theme.white" mr="10px">
                      {data?.Rooms[0]?.Promotions[0]}
                    </Text>
                  </Center>
                </Box>
              )}
            </Box>

            <Box
              height={['400px', '288px']}
              display="flex"
              flexDir={['column', 'row']}
              bg={data?.IsRecommended ? 'theme.null' : 'theme.white'}
              justifyContent="space-between"
              borderRadius={['0px 0px 10px 10px', '0px 10px 10px 0px']}
              w="100%"
              py="16px"
              px={'24px'}
            >
              <Box>
                <VStack spacing={2} alignItems="flex-start">
                  <HStack spacing={2}>
                    <GoLocation size={16} />
                    <Text fontSize={14} color={'gray.700'}>
                      {sliptName[1].replace(')', '')}
                    </Text>
                  </HStack>

                  <Text fontSize="20px" fontWeight="bold" color={'gray.800'}>
                    {sliptName[0] ? `${sliptName[0]}` : 'Sem nome registrado'}
                  </Text>
                  <HStack alignContent={'center'}>
                    <Text color="theme.grey">{translates(language)?.cards?.item5}:</Text>

                    {Array.from({ length: data?.Category }, () => (
                      <BsStarFill color="#EDCA4D" />
                    ))}
                  </HStack>
                  <List spacing={2}>
                    {data?.amenities?.map(amenitie => (
                      // eslint-disable-next-line react/jsx-key
                      <ListItem fontSize={20}>
                        <ListIcon mr="20px" as={iconsAmn(amentie.name[language])} />
                        {amenitie?.name[language]}
                      </ListItem>
                    ))}
                  </List>
                </VStack>

                {data?.Rooms[0]?.Quantity && (
                  <Box display="flex" flexDir={['row', 'column']}>
                    <Box display={['flex', '']} w="100%" flexDir="row">
                      <FaCalendarDay size={20} />
                      <Text ml="10px">{`${nNights ? nNights : '?'} ${translates(language)?.cards?.item1}`}</Text>
                    </Box>
                    <Box visibility={['visible', 'hidden']} ml="50%">
                      <BiHeart size={30} />
                    </Box>
                  </Box>
                )}
                <Box display="flex" flexDir="row" mt={['15px', -5]}>
                  <FaUsers size={20} />
                  <Text ml="10px">
                    {`${calcHops(rooms) ?? 0} ${translates(language)?.cards?.item2} ${
                      translates(language)?.cards?.item3
                    } ${rooms.length ?? 0} ${translates(language)?.cards?.item4}`}
                  </Text>
                </Box>

                {allIncluse && (
                  <Box display="flex" flexDir="row" mt="15px">
                    <FaGift color="green" size={20} />
                    <Text color="green" ml="10px">
                      Tudo incluído
                    </Text>
                  </Box>
                )}
              </Box>

              <Box
                alignItems={['flex-start', 'flex-end']}
                justifyContent="space-evenly"
                display="flex"
                flexDir="column"
              >
                <Box visibility={['hidden', 'visible']}>
                  <BiHeart size={30} />
                </Box>
                <Box display="flex" flexDir="column" alignItems={['flex-start', 'flex-end']}></Box>
                {data?.Rooms[0]?.TotalSellingPrice?.Value && (
                  <>
                    <Text color="theme.celta">{translates(language)?.cards?.item6}</Text>
                    <Box alignItems="center" display="flex" flexDir="row">
                      <Text fontWeight="bold" fontSize="22px">
                        {typeCotation()}
                      </Text>
                      {isCotation == 'POINTS' && <Image ml="5px" h="30px" src="/assets/icons/cash-club.png" />}
                    </Box>
                  </>
                )}
                <Button mt={['20px', '10px']} w={['100%', '150px']} variant="outline" colorScheme={'gray'}>
                  {translates(language)?.cards?.item7}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </GridItem>
    </Link>
  )
}
