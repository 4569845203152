/* eslint-disable react/no-children-prop */
import React from 'react'
import { Button, useColorModeValue } from '@chakra-ui/react'

export const FormButton = ({ children, ...props }: any) => {
  return (
    <Button
      h={46}
      _hover={{ opacity: 0.5 }}
      borderRadius={12}
      color={useColorModeValue('text.light.secondary', 'text.dark.secondary')}
      bgColor={useColorModeValue('theme.light.redInterm', 'theme.dark.redInterm')}
      boxShadow="0px 3px 3px #2D3748"
      {...props}
    >
      {children}
    </Button>
  )
}
