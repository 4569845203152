import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { apiCPT } from '../services/api'
import { getAuthStorage, removeAuthStorage, setAuthStorage } from '../services/storage'

interface UserImageType {
  large: string
  medium: string
  original: string
  small: string
}

interface UserType {
  id: number
  cpf: string
  cell_phone: string
  email: string
  genre: string
  name: string
  status: boolean
  image: UserImageType | null
}

interface ErrorType {
  status: string
  msg: string
}

interface AuthContextData {
  signed: boolean
  user: object | any
  loading: boolean
  error: ErrorType | string | null | unknown
  Login(user: object): Promise<void> | any
  Logout(): void
  getUsers(): any
}

interface AuxProps {
  children: ReactElement | ReactElement[]
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC<AuxProps> = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<UserType | object | null>(null)
  const [users, setUsers] = useState<object | null>(null)
  const [error, setError] = useState<ErrorType | string | null | unknown>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const verifyLogged = async () => {
      if (users == null) {
        const storageAuth = await getAuthStorage()
        if (storageAuth) {
          apiCPT.defaults.headers.common.Authorization = `Bearer ${storageAuth.token}`
          await getUsers()
        }
      }
      setLoading(false)
    }
    verifyLogged()
  }, [])

  useEffect(() => {
    // Interceptor para deslogar o usuário automaticamente quando o token expirar
    const interceptor = apiCPT.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          // Token expirado ou inválido
          Logout() // Desloga o usuário e limpa o estado
        }
        return Promise.reject(error)
      },
    )

    return () => {
      apiCPT.interceptors.response.eject(interceptor)
    }
  }, [])

  const getUsers: AuthContextData['getUsers'] = async () => {
    try {
      const res = await apiCPT.get(`/v1/users`)

      if (res.status !== 200) {
        return false
      }
      // set user
      setUsers(res.data)
      return users
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401) {
        // Token expirado ou inválido
        removeAuthStorage()
        setUser(null)
        setUsers(null)
        navigate('/login', { replace: true })
      } else {
        setError('Erro ao buscar usuário ou refresh token expirado!.')
      }
      setLoading(false)
    }
  }

  const Login: AuthContextData['Login'] = async data => {
    try {
      setLoading(true)
      setError(null)

      // request
      const res = await apiCPT.post('/v1/login', {
        grant_type: 'password',
        ...data,
      })

      if (res.status === 200) {
        // save token in storage
        await setAuthStorage(res.data.token)

        // set token in header
        apiCPT.defaults.headers.common.Authorization = `Bearer ${res.data.token}`

        // decode JWT to get user information
        await setUser(res.data.token)

        // get user
        await getUsers()

        setLoading(false)
        navigate('/', { replace: true })
      } else if (res.status === 203) {
        navigate('/updateOldClient', { state: { data_user: res?.data?.user, update_user: true } })
      } else if (res.status === 401) {
        setError(res.data)
        setLoading(false)
      }
    } catch (e) {
      const err = e as AxiosError
      setError(err.response?.data)
      setLoading(false)
    }
  }

  function Logout() {
    removeAuthStorage()
    setUser(null)
    setUsers(null)
    navigate('/login', { replace: true })
  }

  return (
    <AuthContext.Provider value={{ signed: Boolean(user != null), user, loading, error, Login, Logout, getUsers }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.')
  }

  return context
}
