export const nationalitys = [
  {
    id: 'ABW',
    iso2Code: 'AW',
    name: 'Aruba',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Oranjestad',
    longitude: '-70.0167',
    latitude: '12.5167',
  },
  {
    id: 'AFE',
    iso2Code: 'ZH',
    name: 'Africa Eastern and Southern',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFG',
    iso2Code: 'AF',
    name: 'Afghanistan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kabul',
    longitude: '69.1761',
    latitude: '34.5228',
  },
  {
    id: 'AFR',
    iso2Code: 'A9',
    name: 'Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFW',
    iso2Code: 'ZI',
    name: 'Africa Western and Central',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AGO',
    iso2Code: 'AO',
    name: 'Angola',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Luanda',
    longitude: '13.242',
    latitude: '-8.81155',
  },
  {
    id: 'ALB',
    iso2Code: 'AL',
    name: 'Albania',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tirane',
    longitude: '19.8172',
    latitude: '41.3317',
  },
  {
    id: 'AND',
    iso2Code: 'AD',
    name: 'Andorra',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Andorra la Vella',
    longitude: '1.5218',
    latitude: '42.5075',
  },
  {
    id: 'ARB',
    iso2Code: '1A',
    name: 'Arab World',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ARE',
    iso2Code: 'AE',
    name: 'United Arab Emirates',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Abu Dhabi',
    longitude: '54.3705',
    latitude: '24.4764',
  },
  {
    id: 'ARG',
    iso2Code: 'AR',
    name: 'Argentina',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Buenos Aires',
    longitude: '-58.4173',
    latitude: '-34.6118',
  },
  {
    id: 'ARM',
    iso2Code: 'AM',
    name: 'Armenia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Yerevan',
    longitude: '44.509',
    latitude: '40.1596',
  },
  {
    id: 'ASM',
    iso2Code: 'AS',
    name: 'American Samoa',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Pago Pago',
    longitude: '-170.691',
    latitude: '-14.2846',
  },
  {
    id: 'ATG',
    iso2Code: 'AG',
    name: 'Antigua and Barbuda',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: "Saint John's",
    longitude: '-61.8456',
    latitude: '17.1175',
  },
  {
    id: 'AUS',
    iso2Code: 'AU',
    name: 'Australia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Canberra',
    longitude: '149.129',
    latitude: '-35.282',
  },
  {
    id: 'AUT',
    iso2Code: 'AT',
    name: 'Austria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Vienna',
    longitude: '16.3798',
    latitude: '48.2201',
  },
  {
    id: 'AZE',
    iso2Code: 'AZ',
    name: 'Azerbaijan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Baku',
    longitude: '49.8932',
    latitude: '40.3834',
  },
  {
    id: 'BDI',
    iso2Code: 'BI',
    name: 'Burundi',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bujumbura',
    longitude: '29.3639',
    latitude: '-3.3784',
  },
  {
    id: 'BEA',
    iso2Code: 'B4',
    name: 'East Asia & Pacific (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEC',
    iso2Code: 'B7',
    name: 'Europe & Central Asia (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEL',
    iso2Code: 'BE',
    name: 'Belgium',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Brussels',
    longitude: '4.36761',
    latitude: '50.8371',
  },
  {
    id: 'BEN',
    iso2Code: 'BJ',
    name: 'Benin',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Porto-Novo',
    longitude: '2.6323',
    latitude: '6.4779',
  },
  {
    id: 'BFA',
    iso2Code: 'BF',
    name: 'Burkina Faso',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Ouagadougou',
    longitude: '-1.53395',
    latitude: '12.3605',
  },
  {
    id: 'BGD',
    iso2Code: 'BD',
    name: 'Bangladesh',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dhaka',
    longitude: '90.4113',
    latitude: '23.7055',
  },
  {
    id: 'BGR',
    iso2Code: 'BG',
    name: 'Bulgaria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sofia',
    longitude: '23.3238',
    latitude: '42.7105',
  },
  {
    id: 'BHI',
    iso2Code: 'B1',
    name: 'IBRD countries classified as high income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BHR',
    iso2Code: 'BH',
    name: 'Bahrain',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Manama',
    longitude: '50.5354',
    latitude: '26.1921',
  },
  {
    id: 'BHS',
    iso2Code: 'BS',
    name: 'Bahamas, The',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Nassau',
    longitude: '-77.339',
    latitude: '25.0661',
  },
  {
    id: 'BIH',
    iso2Code: 'BA',
    name: 'Bosnia and Herzegovina',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sarajevo',
    longitude: '18.4214',
    latitude: '43.8607',
  },
  {
    id: 'BLA',
    iso2Code: 'B2',
    name: 'Latin America & the Caribbean (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BLR',
    iso2Code: 'BY',
    name: 'Belarus',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Minsk',
    longitude: '27.5766',
    latitude: '53.9678',
  },
  {
    id: 'BLZ',
    iso2Code: 'BZ',
    name: 'Belize',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Belmopan',
    longitude: '-88.7713',
    latitude: '17.2534',
  },
  {
    id: 'BMN',
    iso2Code: 'B3',
    name: 'Middle East & North Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BMU',
    iso2Code: 'BM',
    name: 'Bermuda',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Hamilton',
    longitude: '-64.706',
    latitude: '32.3293',
  },
  {
    id: 'BOL',
    iso2Code: 'BO',
    name: 'Bolivia',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'La Paz',
    longitude: '-66.1936',
    latitude: '-13.9908',
  },
  {
    id: 'BRA',
    iso2Code: 'BR',
    name: 'Brazil',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Brasilia',
    longitude: '-47.9292',
    latitude: '-15.7801',
  },
  {
    id: 'BRB',
    iso2Code: 'BB',
    name: 'Barbados',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bridgetown',
    longitude: '-59.6105',
    latitude: '13.0935',
  },
  {
    id: 'BRN',
    iso2Code: 'BN',
    name: 'Brunei Darussalam',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bandar Seri Begawan',
    longitude: '114.946',
    latitude: '4.94199',
  },
  {
    id: 'BSS',
    iso2Code: 'B6',
    name: 'Sub-Saharan Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BTN',
    iso2Code: 'BT',
    name: 'Bhutan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Thimphu',
    longitude: '89.6177',
    latitude: '27.5768',
  },
  {
    id: 'BWA',
    iso2Code: 'BW',
    name: 'Botswana',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Gaborone',
    longitude: '25.9201',
    latitude: '-24.6544',
  },
  {
    id: 'CAA',
    iso2Code: 'C9',
    name: 'Sub-Saharan Africa (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CAF',
    iso2Code: 'CF',
    name: 'Central African Republic',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bangui',
    longitude: '21.6407',
    latitude: '5.63056',
  },
  {
    id: 'CAN',
    iso2Code: 'CA',
    name: 'Canada',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Ottawa',
    longitude: '-75.6919',
    latitude: '45.4215',
  },
  {
    id: 'CEA',
    iso2Code: 'C4',
    name: 'East Asia and the Pacific (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEB',
    iso2Code: 'B8',
    name: 'Central Europe and the Baltics',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEU',
    iso2Code: 'C5',
    name: 'Europe and Central Asia (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHE',
    iso2Code: 'CH',
    name: 'Switzerland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bern',
    longitude: '7.44821',
    latitude: '46.948',
  },
  {
    id: 'CHI',
    iso2Code: 'JG',
    name: 'Channel Islands',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHL',
    iso2Code: 'CL',
    name: 'Chile',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Santiago',
    longitude: '-70.6475',
    latitude: '-33.475',
  },
  {
    id: 'CHN',
    iso2Code: 'CN',
    name: 'China',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Beijing',
    longitude: '116.286',
    latitude: '40.0495',
  },
  {
    id: 'CIV',
    iso2Code: 'CI',
    name: "Cote d'Ivoire",
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Yamoussoukro',
    longitude: '-4.0305',
    latitude: '5.332',
  },
  {
    id: 'CLA',
    iso2Code: 'C6',
    name: 'Latin America and the Caribbean (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CME',
    iso2Code: 'C7',
    name: 'Middle East and North Africa (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CMR',
    iso2Code: 'CM',
    name: 'Cameroon',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Yaounde',
    longitude: '11.5174',
    latitude: '3.8721',
  },
  {
    id: 'COD',
    iso2Code: 'CD',
    name: 'Congo, Dem. Rep.',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kinshasa',
    longitude: '15.3222',
    latitude: '-4.325',
  },
  {
    id: 'COG',
    iso2Code: 'CG',
    name: 'Congo, Rep.',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Brazzaville',
    longitude: '15.2662',
    latitude: '-4.2767',
  },
  {
    id: 'COL',
    iso2Code: 'CO',
    name: 'Colombia',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Bogota',
    longitude: '-74.082',
    latitude: '4.60987',
  },
  {
    id: 'COM',
    iso2Code: 'KM',
    name: 'Comoros',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Moroni',
    longitude: '43.2418',
    latitude: '-11.6986',
  },
  {
    id: 'CPV',
    iso2Code: 'CV',
    name: 'Cabo Verde',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Praia',
    longitude: '-23.5087',
    latitude: '14.9218',
  },
  {
    id: 'CRI',
    iso2Code: 'CR',
    name: 'Costa Rica',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'San Jose',
    longitude: '-84.0089',
    latitude: '9.63701',
  },
  {
    id: 'CSA',
    iso2Code: 'C8',
    name: 'South Asia (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CSS',
    iso2Code: 'S3',
    name: 'Caribbean small states',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CUB',
    iso2Code: 'CU',
    name: 'Cuba',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Havana',
    longitude: '-82.3667',
    latitude: '23.1333',
  },
  {
    id: 'CUW',
    iso2Code: 'CW',
    name: 'Curacao',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Willemstad',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CYM',
    iso2Code: 'KY',
    name: 'Cayman Islands',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'George Town',
    longitude: '-81.3857',
    latitude: '19.3022',
  },
  {
    id: 'CYP',
    iso2Code: 'CY',
    name: 'Cyprus',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Nicosia',
    longitude: '33.3736',
    latitude: '35.1676',
  },
  {
    id: 'CZE',
    iso2Code: 'CZ',
    name: 'Czech Republic',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Prague',
    longitude: '14.4205',
    latitude: '50.0878',
  },
  {
    id: 'DEA',
    iso2Code: 'D4',
    name: 'East Asia & Pacific (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DEC',
    iso2Code: 'D7',
    name: 'Europe & Central Asia (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DEU',
    iso2Code: 'DE',
    name: 'Germany',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Berlin',
    longitude: '13.4115',
    latitude: '52.5235',
  },
  {
    id: 'DFS',
    iso2Code: 'D8',
    name: 'IDA countries classified as Fragile Situations',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DJI',
    iso2Code: 'DJ',
    name: 'Djibouti',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Djibouti',
    longitude: '43.1425',
    latitude: '11.5806',
  },
  {
    id: 'DLA',
    iso2Code: 'D2',
    name: 'Latin America & the Caribbean (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DMA',
    iso2Code: 'DM',
    name: 'Dominica',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Roseau',
    longitude: '-61.39',
    latitude: '15.2976',
  },
  {
    id: 'DMN',
    iso2Code: 'D3',
    name: 'Middle East & North Africa (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DNF',
    iso2Code: 'D9',
    name: 'IDA countries not classified as Fragile Situations',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DNK',
    iso2Code: 'DK',
    name: 'Denmark',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Copenhagen',
    longitude: '12.5681',
    latitude: '55.6763',
  },
  {
    id: 'DNS',
    iso2Code: 'N6',
    name: 'IDA countries in Sub-Saharan Africa not classified as fragile situations ',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DOM',
    iso2Code: 'DO',
    name: 'Dominican Republic',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Santo Domingo',
    longitude: '-69.8908',
    latitude: '18.479',
  },
  {
    id: 'DSA',
    iso2Code: 'D5',
    name: 'South Asia (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DSF',
    iso2Code: 'F6',
    name: 'IDA countries in Sub-Saharan Africa classified as fragile situations ',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DSS',
    iso2Code: 'D6',
    name: 'Sub-Saharan Africa (IDA-eligible countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'DZA',
    iso2Code: 'DZ',
    name: 'Algeria',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Algiers',
    longitude: '3.05097',
    latitude: '36.7397',
  },
  {
    id: 'EAP',
    iso2Code: '4E',
    name: 'East Asia & Pacific (excluding high income)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'EAR',
    iso2Code: 'V2',
    name: 'Early-demographic dividend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'EAS',
    iso2Code: 'Z4',
    name: 'East Asia & Pacific',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ECA',
    iso2Code: '7E',
    name: 'Europe & Central Asia (excluding high income)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ECS',
    iso2Code: 'Z7',
    name: 'Europe & Central Asia',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ECU',
    iso2Code: 'EC',
    name: 'Ecuador',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Quito',
    longitude: '-78.5243',
    latitude: '-0.229498',
  },
  {
    id: 'EGY',
    iso2Code: 'EG',
    name: 'Egypt, Arab Rep.',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Cairo',
    longitude: '31.2461',
    latitude: '30.0982',
  },
  {
    id: 'EMU',
    iso2Code: 'XC',
    name: 'Euro area',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ERI',
    iso2Code: 'ER',
    name: 'Eritrea',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Asmara',
    longitude: '38.9183',
    latitude: '15.3315',
  },
  {
    id: 'ESP',
    iso2Code: 'ES',
    name: 'Spain',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Madrid',
    longitude: '-3.70327',
    latitude: '40.4167',
  },
  {
    id: 'EST',
    iso2Code: 'EE',
    name: 'Estonia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Tallinn',
    longitude: '24.7586',
    latitude: '59.4392',
  },
  {
    id: 'ETH',
    iso2Code: 'ET',
    name: 'Ethiopia',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Addis Ababa',
    longitude: '38.7468',
    latitude: '9.02274',
  },
  {
    id: 'EUU',
    iso2Code: 'EU',
    name: 'European Union',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'FCS',
    iso2Code: 'F1',
    name: 'Fragile and conflict affected situations',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'FIN',
    iso2Code: 'FI',
    name: 'Finland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Helsinki',
    longitude: '24.9525',
    latitude: '60.1608',
  },
  {
    id: 'FJI',
    iso2Code: 'FJ',
    name: 'Fiji',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Suva',
    longitude: '178.399',
    latitude: '-18.1149',
  },
  {
    id: 'FRA',
    iso2Code: 'FR',
    name: 'France',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Paris',
    longitude: '2.35097',
    latitude: '48.8566',
  },
  {
    id: 'FRO',
    iso2Code: 'FO',
    name: 'Faroe Islands',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Torshavn',
    longitude: '-6.91181',
    latitude: '61.8926',
  },
  {
    id: 'FSM',
    iso2Code: 'FM',
    name: 'Micronesia, Fed. Sts.',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Palikir',
    longitude: '158.185',
    latitude: '6.91771',
  },
  {
    id: 'FXS',
    iso2Code: '6F',
    name: 'IDA countries classified as fragile situations, excluding Sub-Saharan Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'GAB',
    iso2Code: 'GA',
    name: 'Gabon',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Libreville',
    longitude: '9.45162',
    latitude: '0.38832',
  },
  {
    id: 'GBR',
    iso2Code: 'GB',
    name: 'United Kingdom',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'London',
    longitude: '-0.126236',
    latitude: '51.5002',
  },
  {
    id: 'GEO',
    iso2Code: 'GE',
    name: 'Georgia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tbilisi',
    longitude: '44.793',
    latitude: '41.71',
  },
  {
    id: 'GHA',
    iso2Code: 'GH',
    name: 'Ghana',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Accra',
    longitude: '-0.20795',
    latitude: '5.57045',
  },
  {
    id: 'GIB',
    iso2Code: 'GI',
    name: 'Gibraltar',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'GIN',
    iso2Code: 'GN',
    name: 'Guinea',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Conakry',
    longitude: '-13.7',
    latitude: '9.51667',
  },
  {
    id: 'GMB',
    iso2Code: 'GM',
    name: 'Gambia, The',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Banjul',
    longitude: '-16.5885',
    latitude: '13.4495',
  },
  {
    id: 'GNB',
    iso2Code: 'GW',
    name: 'Guinea-Bissau',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bissau',
    longitude: '-15.1804',
    latitude: '11.8037',
  },
  {
    id: 'GNQ',
    iso2Code: 'GQ',
    name: 'Equatorial Guinea',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Malabo',
    longitude: '8.7741',
    latitude: '3.7523',
  },
  {
    id: 'GRC',
    iso2Code: 'GR',
    name: 'Greece',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Athens',
    longitude: '23.7166',
    latitude: '37.9792',
  },
  {
    id: 'GRD',
    iso2Code: 'GD',
    name: 'Grenada',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: "Saint George's",
    longitude: '-61.7449',
    latitude: '12.0653',
  },
  {
    id: 'GRL',
    iso2Code: 'GL',
    name: 'Greenland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Nuuk',
    longitude: '-51.7214',
    latitude: '64.1836',
  },
  {
    id: 'GTM',
    iso2Code: 'GT',
    name: 'Guatemala',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Guatemala City',
    longitude: '-90.5328',
    latitude: '14.6248',
  },
  {
    id: 'GUM',
    iso2Code: 'GU',
    name: 'Guam',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Agana',
    longitude: '144.794',
    latitude: '13.4443',
  },
  {
    id: 'GUY',
    iso2Code: 'GY',
    name: 'Guyana',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Georgetown',
    longitude: '-58.1548',
    latitude: '6.80461',
  },
  {
    id: 'HIC',
    iso2Code: 'XD',
    name: 'High income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'HKG',
    iso2Code: 'HK',
    name: 'Hong Kong SAR, China',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '114.109',
    latitude: '22.3964',
  },
  {
    id: 'HND',
    iso2Code: 'HN',
    name: 'Honduras',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Tegucigalpa',
    longitude: '-87.4667',
    latitude: '15.1333',
  },
  {
    id: 'HPC',
    iso2Code: 'XE',
    name: 'Heavily indebted poor countries (HIPC)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'HRV',
    iso2Code: 'HR',
    name: 'Croatia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Zagreb',
    longitude: '15.9614',
    latitude: '45.8069',
  },
  {
    id: 'HTI',
    iso2Code: 'HT',
    name: 'Haiti',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Port-au-Prince',
    longitude: '-72.3288',
    latitude: '18.5392',
  },
  {
    id: 'HUN',
    iso2Code: 'HU',
    name: 'Hungary',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Budapest',
    longitude: '19.0408',
    latitude: '47.4984',
  },
  {
    id: 'IBB',
    iso2Code: 'ZB',
    name: 'IBRD, including blend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IBD',
    iso2Code: 'XF',
    name: 'IBRD only',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IBT',
    iso2Code: 'ZT',
    name: 'IDA & IBRD total',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IDA',
    iso2Code: 'XG',
    name: 'IDA total',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IDB',
    iso2Code: 'XH',
    name: 'IDA blend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IDN',
    iso2Code: 'ID',
    name: 'Indonesia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Jakarta',
    longitude: '106.83',
    latitude: '-6.19752',
  },
  {
    id: 'IDX',
    iso2Code: 'XI',
    name: 'IDA only',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IMN',
    iso2Code: 'IM',
    name: 'Isle of Man',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Douglas',
    longitude: '-4.47928',
    latitude: '54.1509',
  },
  {
    id: 'IND',
    iso2Code: 'IN',
    name: 'India',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'New Delhi',
    longitude: '77.225',
    latitude: '28.6353',
  },
  {
    id: 'INX',
    iso2Code: 'XY',
    name: 'Not classified',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'IRL',
    iso2Code: 'IE',
    name: 'Ireland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Dublin',
    longitude: '-6.26749',
    latitude: '53.3441',
  },
  {
    id: 'IRN',
    iso2Code: 'IR',
    name: 'Iran, Islamic Rep.',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tehran',
    longitude: '51.4447',
    latitude: '35.6878',
  },
  {
    id: 'IRQ',
    iso2Code: 'IQ',
    name: 'Iraq',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Baghdad',
    longitude: '44.394',
    latitude: '33.3302',
  },
  {
    id: 'ISL',
    iso2Code: 'IS',
    name: 'Iceland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Reykjavik',
    longitude: '-21.8952',
    latitude: '64.1353',
  },
  {
    id: 'ISR',
    iso2Code: 'IL',
    name: 'Israel',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '35.2035',
    latitude: '31.7717',
  },
  {
    id: 'ITA',
    iso2Code: 'IT',
    name: 'Italy',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Rome',
    longitude: '12.4823',
    latitude: '41.8955',
  },
  {
    id: 'JAM',
    iso2Code: 'JM',
    name: 'Jamaica',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Kingston',
    longitude: '-76.792',
    latitude: '17.9927',
  },
  {
    id: 'JOR',
    iso2Code: 'JO',
    name: 'Jordan',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Amman',
    longitude: '35.9263',
    latitude: '31.9497',
  },
  {
    id: 'JPN',
    iso2Code: 'JP',
    name: 'Japan',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Tokyo',
    longitude: '139.77',
    latitude: '35.67',
  },
  {
    id: 'KAZ',
    iso2Code: 'KZ',
    name: 'Kazakhstan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Astana',
    longitude: '71.4382',
    latitude: '51.1879',
  },
  {
    id: 'KEN',
    iso2Code: 'KE',
    name: 'Kenya',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Nairobi',
    longitude: '36.8126',
    latitude: '-1.27975',
  },
  {
    id: 'KGZ',
    iso2Code: 'KG',
    name: 'Kyrgyz Republic',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bishkek',
    longitude: '74.6057',
    latitude: '42.8851',
  },
  {
    id: 'KHM',
    iso2Code: 'KH',
    name: 'Cambodia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Phnom Penh',
    longitude: '104.874',
    latitude: '11.5556',
  },
  {
    id: 'KIR',
    iso2Code: 'KI',
    name: 'Kiribati',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Tarawa',
    longitude: '172.979',
    latitude: '1.32905',
  },
  {
    id: 'KNA',
    iso2Code: 'KN',
    name: 'St. Kitts and Nevis',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Basseterre',
    longitude: '-62.7309',
    latitude: '17.3',
  },
  {
    id: 'KOR',
    iso2Code: 'KR',
    name: 'Korea, Rep.',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Seoul',
    longitude: '126.957',
    latitude: '37.5323',
  },
  {
    id: 'KWT',
    iso2Code: 'KW',
    name: 'Kuwait',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Kuwait City',
    longitude: '47.9824',
    latitude: '29.3721',
  },
  {
    id: 'LAC',
    iso2Code: 'XJ',
    name: 'Latin America & Caribbean (excluding high income)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LAO',
    iso2Code: 'LA',
    name: 'Lao PDR',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Vientiane',
    longitude: '102.177',
    latitude: '18.5826',
  },
  {
    id: 'LBN',
    iso2Code: 'LB',
    name: 'Lebanon',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Beirut',
    longitude: '35.5134',
    latitude: '33.8872',
  },
  {
    id: 'LBR',
    iso2Code: 'LR',
    name: 'Liberia',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Monrovia',
    longitude: '-10.7957',
    latitude: '6.30039',
  },
  {
    id: 'LBY',
    iso2Code: 'LY',
    name: 'Libya',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tripoli',
    longitude: '13.1072',
    latitude: '32.8578',
  },
  {
    id: 'LCA',
    iso2Code: 'LC',
    name: 'St. Lucia',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Castries',
    longitude: '-60.9832',
    latitude: '14',
  },
  {
    id: 'LCN',
    iso2Code: 'ZJ',
    name: 'Latin America & Caribbean ',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LDC',
    iso2Code: 'XL',
    name: 'Least developed countries: UN classification',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LIC',
    iso2Code: 'XM',
    name: 'Low income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LIE',
    iso2Code: 'LI',
    name: 'Liechtenstein',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Vaduz',
    longitude: '9.52148',
    latitude: '47.1411',
  },
  {
    id: 'LKA',
    iso2Code: 'LK',
    name: 'Sri Lanka',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Colombo',
    longitude: '79.8528',
    latitude: '6.92148',
  },
  {
    id: 'LMC',
    iso2Code: 'XN',
    name: 'Lower middle income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LMY',
    iso2Code: 'XO',
    name: 'Low & middle income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LSO',
    iso2Code: 'LS',
    name: 'Lesotho',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Maseru',
    longitude: '27.7167',
    latitude: '-29.5208',
  },
  {
    id: 'LTE',
    iso2Code: 'V3',
    name: 'Late-demographic dividend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'LTU',
    iso2Code: 'LT',
    name: 'Lithuania',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Vilnius',
    longitude: '25.2799',
    latitude: '54.6896',
  },
  {
    id: 'LUX',
    iso2Code: 'LU',
    name: 'Luxembourg',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Luxembourg',
    longitude: '6.1296',
    latitude: '49.61',
  },
  {
    id: 'LVA',
    iso2Code: 'LV',
    name: 'Latvia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Riga',
    longitude: '24.1048',
    latitude: '56.9465',
  },
  {
    id: 'MAC',
    iso2Code: 'MO',
    name: 'Macao SAR, China',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '113.55',
    latitude: '22.1667',
  },
  {
    id: 'MAF',
    iso2Code: 'MF',
    name: 'St. Martin (French part)',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Marigot',
    longitude: '',
    latitude: '',
  },
  {
    id: 'MAR',
    iso2Code: 'MA',
    name: 'Morocco',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Rabat',
    longitude: '-6.8704',
    latitude: '33.9905',
  },
  {
    id: 'MCO',
    iso2Code: 'MC',
    name: 'Monaco',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Monaco',
    longitude: '7.41891',
    latitude: '43.7325',
  },
  {
    id: 'MDA',
    iso2Code: 'MD',
    name: 'Moldova',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Chisinau',
    longitude: '28.8497',
    latitude: '47.0167',
  },
  {
    id: 'MDE',
    iso2Code: 'M1',
    name: 'Middle East (developing only)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'MDG',
    iso2Code: 'MG',
    name: 'Madagascar',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Antananarivo',
    longitude: '45.7167',
    latitude: '-20.4667',
  },
  {
    id: 'MDV',
    iso2Code: 'MV',
    name: 'Maldives',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Male',
    longitude: '73.5109',
    latitude: '4.1742',
  },
  {
    id: 'MEA',
    iso2Code: 'ZQ',
    name: 'Middle East & North Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'MEX',
    iso2Code: 'MX',
    name: 'Mexico',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Mexico City',
    longitude: '-99.1276',
    latitude: '19.427',
  },
  {
    id: 'MHL',
    iso2Code: 'MH',
    name: 'Marshall Islands',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Majuro',
    longitude: '171.135',
    latitude: '7.11046',
  },
  {
    id: 'MIC',
    iso2Code: 'XP',
    name: 'Middle income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'MKD',
    iso2Code: 'MK',
    name: 'North Macedonia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Skopje',
    longitude: '21.4361',
    latitude: '42.0024',
  },
  {
    id: 'MLI',
    iso2Code: 'ML',
    name: 'Mali',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bamako',
    longitude: '-7.50034',
    latitude: '13.5667',
  },
  {
    id: 'MLT',
    iso2Code: 'MT',
    name: 'Malta',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Valletta',
    longitude: '14.5189',
    latitude: '35.9042',
  },
  {
    id: 'MMR',
    iso2Code: 'MM',
    name: 'Myanmar',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Naypyidaw',
    longitude: '95.9562',
    latitude: '21.914',
  },
  {
    id: 'MNA',
    iso2Code: 'XQ',
    name: 'Middle East & North Africa (excluding high income)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'MNE',
    iso2Code: 'ME',
    name: 'Montenegro',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Podgorica',
    longitude: '19.2595',
    latitude: '42.4602',
  },
  {
    id: 'MNG',
    iso2Code: 'MN',
    name: 'Mongolia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Ulaanbaatar',
    longitude: '106.937',
    latitude: '47.9129',
  },
  {
    id: 'MNP',
    iso2Code: 'MP',
    name: 'Northern Mariana Islands',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Saipan',
    longitude: '145.765',
    latitude: '15.1935',
  },
  {
    id: 'MOZ',
    iso2Code: 'MZ',
    name: 'Mozambique',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Maputo',
    longitude: '32.5713',
    latitude: '-25.9664',
  },
  {
    id: 'MRT',
    iso2Code: 'MR',
    name: 'Mauritania',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Nouakchott',
    longitude: '-15.9824',
    latitude: '18.2367',
  },
  {
    id: 'MUS',
    iso2Code: 'MU',
    name: 'Mauritius',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Port Louis',
    longitude: '57.4977',
    latitude: '-20.1605',
  },
  {
    id: 'MWI',
    iso2Code: 'MW',
    name: 'Malawi',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Lilongwe',
    longitude: '33.7703',
    latitude: '-13.9899',
  },
  {
    id: 'MYS',
    iso2Code: 'MY',
    name: 'Malaysia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Kuala Lumpur',
    longitude: '101.684',
    latitude: '3.12433',
  },
  {
    id: 'NAC',
    iso2Code: 'XU',
    name: 'North America',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'NAF',
    iso2Code: 'M2',
    name: 'North Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'NAM',
    iso2Code: 'NA',
    name: 'Namibia',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Windhoek',
    longitude: '17.0931',
    latitude: '-22.5648',
  },
  {
    id: 'NCL',
    iso2Code: 'NC',
    name: 'New Caledonia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: "Noum'ea",
    longitude: '166.464',
    latitude: '-22.2677',
  },
  {
    id: 'NER',
    iso2Code: 'NE',
    name: 'Niger',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Niamey',
    longitude: '2.1073',
    latitude: '13.514',
  },
  {
    id: 'NGA',
    iso2Code: 'NG',
    name: 'Nigeria',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Abuja',
    longitude: '7.48906',
    latitude: '9.05804',
  },
  {
    id: 'NIC',
    iso2Code: 'NI',
    name: 'Nicaragua',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Managua',
    longitude: '-86.2734',
    latitude: '12.1475',
  },
  {
    id: 'NLD',
    iso2Code: 'NL',
    name: 'Netherlands',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Amsterdam',
    longitude: '4.89095',
    latitude: '52.3738',
  },
  {
    id: 'NOR',
    iso2Code: 'NO',
    name: 'Norway',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Oslo',
    longitude: '10.7387',
    latitude: '59.9138',
  },
  {
    id: 'NPL',
    iso2Code: 'NP',
    name: 'Nepal',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kathmandu',
    longitude: '85.3157',
    latitude: '27.6939',
  },
  {
    id: 'NRS',
    iso2Code: '6X',
    name: 'Non-resource rich Sub-Saharan Africa countries',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'NRU',
    iso2Code: 'NR',
    name: 'Nauru',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Yaren District',
    longitude: '166.920867',
    latitude: '-0.5477',
  },
  {
    id: 'NXS',
    iso2Code: '6N',
    name: 'IDA countries not classified as fragile situations, excluding Sub-Saharan Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'NZL',
    iso2Code: 'NZ',
    name: 'New Zealand',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Wellington',
    longitude: '174.776',
    latitude: '-41.2865',
  },
  {
    id: 'OED',
    iso2Code: 'OE',
    name: 'OECD members',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'OMN',
    iso2Code: 'OM',
    name: 'Oman',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Muscat',
    longitude: '58.5874',
    latitude: '23.6105',
  },
  {
    id: 'OSS',
    iso2Code: 'S4',
    name: 'Other small states',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'PAK',
    iso2Code: 'PK',
    name: 'Pakistan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Islamabad',
    longitude: '72.8',
    latitude: '30.5167',
  },
  {
    id: 'PAN',
    iso2Code: 'PA',
    name: 'Panama',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Panama City',
    longitude: '-79.5188',
    latitude: '8.99427',
  },
  {
    id: 'PER',
    iso2Code: 'PE',
    name: 'Peru',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Lima',
    longitude: '-77.0465',
    latitude: '-12.0931',
  },
  {
    id: 'PHL',
    iso2Code: 'PH',
    name: 'Philippines',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Manila',
    longitude: '121.035',
    latitude: '14.5515',
  },
  {
    id: 'PLW',
    iso2Code: 'PW',
    name: 'Palau',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Koror',
    longitude: '134.479',
    latitude: '7.34194',
  },
  {
    id: 'PNG',
    iso2Code: 'PG',
    name: 'Papua New Guinea',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Port Moresby',
    longitude: '147.194',
    latitude: '-9.47357',
  },
  {
    id: 'POL',
    iso2Code: 'PL',
    name: 'Poland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Warsaw',
    longitude: '21.02',
    latitude: '52.26',
  },
  {
    id: 'PRE',
    iso2Code: 'V1',
    name: 'Pre-demographic dividend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'PRI',
    iso2Code: 'PR',
    name: 'Puerto Rico',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'San Juan',
    longitude: '-66',
    latitude: '18.23',
  },
  {
    id: 'PRK',
    iso2Code: 'KP',
    name: "Korea, Dem. People's Rep.",
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Pyongyang',
    longitude: '125.754',
    latitude: '39.0319',
  },
  {
    id: 'PRT',
    iso2Code: 'PT',
    name: 'Portugal',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Lisbon',
    longitude: '-9.13552',
    latitude: '38.7072',
  },
  {
    id: 'PRY',
    iso2Code: 'PY',
    name: 'Paraguay',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Asuncion',
    longitude: '-57.6362',
    latitude: '-25.3005',
  },
  {
    id: 'PSE',
    iso2Code: 'PS',
    name: 'West Bank and Gaza',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'PSS',
    iso2Code: 'S2',
    name: 'Pacific island small states',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'PST',
    iso2Code: 'V4',
    name: 'Post-demographic dividend',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'PYF',
    iso2Code: 'PF',
    name: 'French Polynesia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Papeete',
    longitude: '-149.57',
    latitude: '-17.535',
  },
  {
    id: 'QAT',
    iso2Code: 'QA',
    name: 'Qatar',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Doha',
    longitude: '51.5082',
    latitude: '25.2948',
  },
  {
    id: 'ROU',
    iso2Code: 'RO',
    name: 'Romania',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Bucharest',
    longitude: '26.0979',
    latitude: '44.4479',
  },
  {
    id: 'RRS',
    iso2Code: 'R6',
    name: 'Resource rich Sub-Saharan Africa countries',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'RUS',
    iso2Code: 'RU',
    name: 'Russian Federation',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Moscow',
    longitude: '37.6176',
    latitude: '55.7558',
  },
  {
    id: 'RWA',
    iso2Code: 'RW',
    name: 'Rwanda',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kigali',
    longitude: '30.0587',
    latitude: '-1.95325',
  },
  {
    id: 'SAS',
    iso2Code: '8S',
    name: 'South Asia',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'SAU',
    iso2Code: 'SA',
    name: 'Saudi Arabia',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Riyadh',
    longitude: '46.6977',
    latitude: '24.6748',
  },
  {
    id: 'SDN',
    iso2Code: 'SD',
    name: 'Sudan',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Khartoum',
    longitude: '32.5363',
    latitude: '15.5932',
  },
  {
    id: 'SEN',
    iso2Code: 'SN',
    name: 'Senegal',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dakar',
    longitude: '-17.4734',
    latitude: '14.7247',
  },
  {
    id: 'SGP',
    iso2Code: 'SG',
    name: 'Singapore',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Singapore',
    longitude: '103.85',
    latitude: '1.28941',
  },
  {
    id: 'SLB',
    iso2Code: 'SB',
    name: 'Solomon Islands',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Honiara',
    longitude: '159.949',
    latitude: '-9.42676',
  },
  {
    id: 'SLE',
    iso2Code: 'SL',
    name: 'Sierra Leone',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Freetown',
    longitude: '-13.2134',
    latitude: '8.4821',
  },
  {
    id: 'SLV',
    iso2Code: 'SV',
    name: 'El Salvador',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'San Salvador',
    longitude: '-89.2073',
    latitude: '13.7034',
  },
  {
    id: 'SMR',
    iso2Code: 'SM',
    name: 'San Marino',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'San Marino',
    longitude: '12.4486',
    latitude: '43.9322',
  },
  {
    id: 'SOM',
    iso2Code: 'SO',
    name: 'Somalia',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Mogadishu',
    longitude: '45.3254',
    latitude: '2.07515',
  },
  {
    id: 'SRB',
    iso2Code: 'RS',
    name: 'Serbia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Belgrade',
    longitude: '20.4656',
    latitude: '44.8024',
  },
  {
    id: 'SSA',
    iso2Code: 'ZF',
    name: 'Sub-Saharan Africa (excluding high income)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'SSD',
    iso2Code: 'SS',
    name: 'South Sudan',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Juba',
    longitude: '31.6',
    latitude: '4.85',
  },
  {
    id: 'SSF',
    iso2Code: 'ZG',
    name: 'Sub-Saharan Africa ',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'SST',
    iso2Code: 'S1',
    name: 'Small states',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'STP',
    iso2Code: 'ST',
    name: 'Sao Tome and Principe',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Sao Tome',
    longitude: '6.6071',
    latitude: '0.20618',
  },
  {
    id: 'SUR',
    iso2Code: 'SR',
    name: 'Suriname',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Paramaribo',
    longitude: '-55.1679',
    latitude: '5.8232',
  },
  {
    id: 'SVK',
    iso2Code: 'SK',
    name: 'Slovak Republic',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bratislava',
    longitude: '17.1073',
    latitude: '48.1484',
  },
  {
    id: 'SVN',
    iso2Code: 'SI',
    name: 'Slovenia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Ljubljana',
    longitude: '14.5044',
    latitude: '46.0546',
  },
  {
    id: 'SWE',
    iso2Code: 'SE',
    name: 'Sweden',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Stockholm',
    longitude: '18.0645',
    latitude: '59.3327',
  },
  {
    id: 'SWZ',
    iso2Code: 'SZ',
    name: 'Eswatini',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Mbabane',
    longitude: '31.4659',
    latitude: '-26.5225',
  },
  {
    id: 'SXM',
    iso2Code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Philipsburg',
    longitude: '',
    latitude: '',
  },
  {
    id: 'SXZ',
    iso2Code: 'A4',
    name: 'Sub-Saharan Africa excluding South Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'SYC',
    iso2Code: 'SC',
    name: 'Seychelles',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Victoria',
    longitude: '55.4466',
    latitude: '-4.6309',
  },
  {
    id: 'SYR',
    iso2Code: 'SY',
    name: 'Syrian Arab Republic',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Damascus',
    longitude: '36.3119',
    latitude: '33.5146',
  },
  {
    id: 'TCA',
    iso2Code: 'TC',
    name: 'Turks and Caicos Islands',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Grand Turk',
    longitude: '-71.141389',
    latitude: '21.4602778',
  },
  {
    id: 'TCD',
    iso2Code: 'TD',
    name: 'Chad',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: "N'Djamena",
    longitude: '15.0445',
    latitude: '12.1048',
  },
  {
    id: 'TEA',
    iso2Code: 'T4',
    name: 'East Asia & Pacific (IDA & IBRD countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TEC',
    iso2Code: 'T7',
    name: 'Europe & Central Asia (IDA & IBRD countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TGO',
    iso2Code: 'TG',
    name: 'Togo',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Lome',
    longitude: '1.2255',
    latitude: '6.1228',
  },
  {
    id: 'THA',
    iso2Code: 'TH',
    name: 'Thailand',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Bangkok',
    longitude: '100.521',
    latitude: '13.7308',
  },
  {
    id: 'TJK',
    iso2Code: 'TJ',
    name: 'Tajikistan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dushanbe',
    longitude: '68.7864',
    latitude: '38.5878',
  },
  {
    id: 'TKM',
    iso2Code: 'TM',
    name: 'Turkmenistan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Ashgabat',
    longitude: '58.3794',
    latitude: '37.9509',
  },
  {
    id: 'TLA',
    iso2Code: 'T2',
    name: 'Latin America & the Caribbean (IDA & IBRD countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TLS',
    iso2Code: 'TL',
    name: 'Timor-Leste',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Dili',
    longitude: '125.567',
    latitude: '-8.56667',
  },
  {
    id: 'TMN',
    iso2Code: 'T3',
    name: 'Middle East & North Africa (IDA & IBRD countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TON',
    iso2Code: 'TO',
    name: 'Tonga',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: "Nuku'alofa",
    longitude: '-175.216',
    latitude: '-21.136',
  },
  {
    id: 'TSA',
    iso2Code: 'T5',
    name: 'South Asia (IDA & IBRD)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TSS',
    iso2Code: 'T6',
    name: 'Sub-Saharan Africa (IDA & IBRD countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TTO',
    iso2Code: 'TT',
    name: 'Trinidad and Tobago',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Port-of-Spain',
    longitude: '-61.4789',
    latitude: '10.6596',
  },
  {
    id: 'TUN',
    iso2Code: 'TN',
    name: 'Tunisia',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tunis',
    longitude: '10.21',
    latitude: '36.7899',
  },
  {
    id: 'TUR',
    iso2Code: 'TR',
    name: 'Turkiye',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Ankara',
    longitude: '32.3606',
    latitude: '39.7153',
  },
  {
    id: 'TUV',
    iso2Code: 'TV',
    name: 'Tuvalu',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Funafuti',
    longitude: '179.089567',
    latitude: '-8.6314877',
  },
  {
    id: 'TWN',
    iso2Code: 'TW',
    name: 'Taiwan, China',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'TZA',
    iso2Code: 'TZ',
    name: 'Tanzania',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dodoma',
    longitude: '35.7382',
    latitude: '-6.17486',
  },
  {
    id: 'UGA',
    iso2Code: 'UG',
    name: 'Uganda',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kampala',
    longitude: '32.5729',
    latitude: '0.314269',
  },
  {
    id: 'UKR',
    iso2Code: 'UA',
    name: 'Ukraine',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Kiev',
    longitude: '30.5038',
    latitude: '50.4536',
  },
  {
    id: 'UMC',
    iso2Code: 'XT',
    name: 'Upper middle income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'URY',
    iso2Code: 'UY',
    name: 'Uruguay',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Montevideo',
    longitude: '-56.0675',
    latitude: '-34.8941',
  },
  {
    id: 'USA',
    iso2Code: 'US',
    name: 'United States',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Washington D.C.',
    longitude: '-77.032',
    latitude: '38.8895',
  },
  {
    id: 'UZB',
    iso2Code: 'UZ',
    name: 'Uzbekistan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Tashkent',
    longitude: '69.269',
    latitude: '41.3052',
  },
  {
    id: 'VCT',
    iso2Code: 'VC',
    name: 'St. Vincent and the Grenadines',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Kingstown',
    longitude: '-61.2653',
    latitude: '13.2035',
  },
  {
    id: 'VEN',
    iso2Code: 'VE',
    name: 'Venezuela, RB',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'INX',
      iso2code: 'XY',
      value: 'Not classified',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Caracas',
    longitude: '-69.8371',
    latitude: '9.08165',
  },
  {
    id: 'VGB',
    iso2Code: 'VG',
    name: 'British Virgin Islands',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Road Town',
    longitude: '-64.623056',
    latitude: '18.431389',
  },
  {
    id: 'VIR',
    iso2Code: 'VI',
    name: 'Virgin Islands (U.S.)',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Charlotte Amalie',
    longitude: '-64.8963',
    latitude: '18.3358',
  },
  {
    id: 'VNM',
    iso2Code: 'VN',
    name: 'Vietnam',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Hanoi',
    longitude: '105.825',
    latitude: '21.0069',
  },
  {
    id: 'VUT',
    iso2Code: 'VU',
    name: 'Vanuatu',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Port-Vila',
    longitude: '168.321',
    latitude: '-17.7404',
  },
  {
    id: 'WLD',
    iso2Code: '1W',
    name: 'World',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'WSM',
    iso2Code: 'WS',
    name: 'Samoa',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Apia',
    longitude: '-171.752',
    latitude: '-13.8314',
  },
  {
    id: 'XKX',
    iso2Code: 'XK',
    name: 'Kosovo',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Pristina',
    longitude: '20.926',
    latitude: '42.565',
  },
  {
    id: 'XZN',
    iso2Code: 'A5',
    name: 'Sub-Saharan Africa excluding South Africa and Nigeria',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'YEM',
    iso2Code: 'YE',
    name: 'Yemen, Rep.',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: 'MNA',
      iso2code: 'XQ',
      value: 'Middle East & North Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: "Sana'a",
    longitude: '44.2075',
    latitude: '15.352',
  },
  {
    id: 'ZAF',
    iso2Code: 'ZA',
    name: 'South Africa',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Pretoria',
    longitude: '28.1871',
    latitude: '-25.746',
  },
  {
    id: 'ZMB',
    iso2Code: 'ZM',
    name: 'Zambia',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Lusaka',
    longitude: '28.2937',
    latitude: '-15.3982',
  },
  {
    id: 'ZWE',
    iso2Code: 'ZW',
    name: 'Zimbabwe',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDB',
      iso2code: 'XH',
      value: 'Blend',
    },
    capitalCity: 'Harare',
    longitude: '31.0672',
    latitude: '-17.8312',
  },
]
