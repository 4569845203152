// Containers
export const rGrid2 = ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']
export const rGrid3 = ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
export const rGrid4 = ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']

// Containers
export const rWidth = ['400px', '600px', '800px', '1280px']

export const rWidthS = ['400px', '600px', '800px', '1280px']

// Fonte extra grande
export const rXXFont = [20, 30, 40, 50]

// Fonte grande
export const rXFont = [12, 14, 16, 18]
