// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Box,
  Flex,
  Spacer,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import CookieConsent from '../components/Cookieconsent';
import FloatingButton from '../components/FloatingButton';
import Loader from '../components/Loader';
import Footer from '../layout/Footer';
import NavBarAuth from '../layout/NavBar';
import PanelMenu from '../layout/PanelMenu';
import { useAuth } from '../providers/Auth';

export const RequireAuth = () => {
  const { loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const panel =
    location?.pathname !== undefined &&
    (location?.pathname.includes('panel') ||
      location?.pathname.includes('create') ||
      location?.pathname.includes('edit') ||
      location?.pathname.includes('weeks'));
  const header = location?.pathname !== undefined && location?.pathname !== '/login';
  const viewportHeight = window.innerHeight;
  const secureHeight = viewportHeight - viewportHeight / 4.5;
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const leastDestructiveRef = useRef(null);

  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  });

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      setUserData(JSON.parse(localStorage.getItem('__user_data') || ''));
    }
  }, []);

  useEffect(() => {
    if (window.location.host.includes('qa.') || window.location.host.includes('localhost')) {
      setShowPopup(true);
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Adicione o estado e a função
  const [menuOpen, setMenuOpen] = useState(false);

  // Função para atualizar o estado do menu
  const handleMenuToggle = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  type LocationType =
    | ' - Início'
    | ' - Finanças'
    | ' - Propriedades'
    | ' - Extrato de ClashClubs'
    | ' - Meus Dados'
    | ' - Reservas'
    | ' - Depositar Semanas'
    | ' - Intercâmbios'
    | '';

  const isLocation = (loc: any): LocationType => {
    if (loc == '/panel/dashboard') return ' - Início';
    if (loc == '/panel/finances') return ' - Finanças';
    if (loc == '/panel/propriedades') return ' - Propriedades';
    if (loc == '/panel/extrato-cashclubs') return ' - Extrato de ClashClubs';
    if (userData != undefined && loc.includes('/panel/users')) return ' - Meus Dados';
    if (userData != undefined && loc.includes('/panel/intercambios')) return ' - Intercâmbios';
    if (loc == '/panel/reservas') return ' - Reservas';
    if (userData != undefined && loc.includes('/weeks')) return ' - Depositar Semanas';
    else return '';
  };

  const handleGoBack = () => {
    navigate(-1); // Navega de volta para a página anterior
  };

  if (loading) {
    return <Loader />;
  }

  if (!userData) {
    return (
      <>
        <Navigate to="/login" state={{ from: location }} replace />
        {showPopup && (
          <AlertDialog
            leastDestructiveRef={leastDestructiveRef}
            isOpen={showPopup}
            onClose={handleClosePopup}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Ambiente de Teste</AlertDialogHeader>
                <AlertDialogBody>Este é um ambiente de teste e não oficial.</AlertDialogBody>
                <AlertDialogFooter>
                  <Button colorScheme="blue" onClick={handleClosePopup}>
                    Fechar
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
      </>
    );
  }

  return (
    <>
      <Box>
        {header && !isMobile ? <NavBarAuth /> : menuOpen ? <></> : <NavBarAuth />}
        {panel ? (
          <>
            <Flex align="center" justify="center" mt={[3, 0]} width={['100%', 'auto']} textAlign="center">
              <span style={{ fontWeight: 'bold', fontSize: '1rem', marginTop: '20px' }}>
                <ChevronLeftIcon cursor="pointer" onClick={handleGoBack} mr={2} /> Painel do Associado
                {String(isLocation(location.pathname))}
              </span>
            </Flex>
            <Flex
              pl={isMobile ? '0px' : '50px'}
              pr={isMobile ? '0px' : '50px'}
              mt={isMobile ? '0px' : '70px'}
              mb={isMobile ? '0px' : '70px'}
              justify={isMobile ? 'center' : 'space-between'}
              direction={isMobile ? 'column' : 'row'}
            >
              <PanelMenu menuOpen={menuOpen} handleMenuToggle={handleMenuToggle} />
              {isMobile ? null : <Spacer />}
              <Box w={isMobile ? '100%' : 'auto'}>
                <Outlet />
              </Box>
            </Flex>
          </>
        ) : (
          <Box w="full" minHeight={secureHeight}>
            <Outlet />
          </Box>
        )}
        <Footer />
        <CookieConsent />
        <FloatingButton />
      </Box>
      {showPopup && (
        <AlertDialog leastDestructiveRef={leastDestructiveRef} isOpen={showPopup} onClose={handleClosePopup} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Ambiente de Teste</AlertDialogHeader>
              <AlertDialogBody>Este é um ambiente de teste e não oficial.</AlertDialogBody>
              <AlertDialogFooter>
                <Button colorScheme="blue" onClick={handleClosePopup}>
                  Fechar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};
