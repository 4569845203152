import { STORAGE_KEYS } from '../constants/Config'

export const getAuthStorage = async () => {
  try {
    const jsonValue = await localStorage.getItem(STORAGE_KEYS.AUTH)
    const token = jsonValue != null ? JSON.parse(jsonValue) : null
    return token
  } catch (e) {
    console.error('Erro ao obter token:', e)
    return null
  }
}

export const setAuthStorage = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)

    await localStorage.setItem(STORAGE_KEYS.AUTH, jsonValue)
    return true
  } catch (e) {
    console.error('Error storing token:', e)
    return false
  }
}

export const removeAuthStorage = async () => {
  try {
    await localStorage.removeItem(STORAGE_KEYS.AUTH)
    return true
  } catch (e) {
    return false
  }
}
