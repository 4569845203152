// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react'

interface ConfirmExportProps {
  event: event
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const ConfirmExport = (props: ConfirmExportProps) => {
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dados duplicados</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text mb="1rem">
              Esses dados aparetemente encontra-se em nossa base de dados de confirmação de dados, caso deseje continuar
              essas Informações serão atualizadas
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={props.onClose}>
              Não
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                props.event()
                props.onClose()
              }}
            >
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmExport
