import React, { useEffect, useState } from 'react';
import { Box, Flex, Input, Text, Select } from '@chakra-ui/react';
import 'react-phone-number-input/style.css';
import 'react-credit-cards/es/styles-compiled.css';
import CardPay from 'react-credit-cards';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../../utils/payments';

import { apiCPT } from '../../../services/api';

export default function Pagamento({ applyFinishDeposit, setApplyFinishDeposit }) {
  const [productClubShareId, setProductClubShareId] = useState(null);
  const [productVindiId, setProductVindiId] = useState(null);
  const [typeOfDeposit, setTypeOfDeposit] = useState([]);
  const [amountDeposit, setAmountDeposit] = useState(0);
  const [cardPayInfo, setCardPayInfo] = useState({});
  const [cardInfo, setCardInfo] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
  });

  const handleInputFocus = name => {
    setCardInfo(old => ({
      ...old,
      focused: name,
    }));
  };

  const handleInputChange = (e, name) => {
    if (name === 'number') {
      e.target.value = formatCreditCardNumber(e.target.value);
    } else if (name === 'expiry') {
      e.target.value = formatExpirationDate(e.target.value);
    } else if (name === 'cvc') {
      e.target.value = formatCVC(e.target.value);
    }
    setCardInfo(old => ({
      ...old,
      [name]: e?.target?.value,
    }));
    localStorage.setItem('__client_card_payment', JSON.stringify(cardInfo));
    setApplyFinishDeposit(verifyCompletedFormPayment());
  };

  function verifyCompletedFormPayment() {
    if (
      cardInfo.number != '' &&
      cardInfo.name != '' &&
      cardInfo.expiry != '' &&
      cardInfo.cvc != '' &&
      productClubShareId != null &&
      productVindiId != null
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    apiCPT.get(`v1/products`).then(res => {
      if (res.data) {
        console.log(res.data?.products);
        setTypeOfDeposit(res.data?.products);
      }
    });
  }, []);

  useEffect(() => {
    setCardInfo({ ...cardInfo, issuer: cardPayInfo?.issuer });
  }, [cardPayInfo]);

  return (
    <Box p={6} w="100%" h="100%">
      <Box p={2} w="100%">
        <Text fontWeight={700}>É necessário pagar para que seja aprovado o depósito de semana</Text>
      </Box>
      <Flex w="100%">
        <Box p={2} w="100%">
          <Text>Tipo do Depósito</Text>
          <Select
            size="md"
            placeholder="Selecione"
            value={null}
            onChange={e => {
              const data = e.target.value.split(',');
              const dataDepositOfDays = JSON.parse(localStorage.getItem('__deposit_of_days'));

              console.log(dataDepositOfDays); // Verificar a estrutura dos dados de depósito

              // Armazena as informações sobre o tipo de depósito
              setProductClubShareId(data[0]);
              setProductVindiId(data[1]);
              setAmountDeposit(
                data[2].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) * dataDepositOfDays.length,
              );

              // Salvar o tipo de depósito no localStorage
              localStorage.setItem(
                '__client_type_of_deposit',
                JSON.stringify({ product_id: data[0], product_vindi_id: data[1], value: data[2] }),
              );

              // Definir abrangência com base na descrição do depósito
              const description = e.target.options[e.target.selectedIndex].text;
              const abrangency = description.includes('Nacional') ? 'N' : 'I';

              // Armazenar a abrangência no localStorage
              localStorage.setItem('__client_type_of_abrangency', JSON.stringify({ value: abrangency }));

              console.log('Tipo de Abrangência armazenado:', abrangency); // Verificar se está correto
            }}
          >
            {typeOfDeposit
              .filter(e => e.description.includes('Deposito'))
              .map((item, index) => (
                <option key={index} value={[item.product_id, item.product_vindi_id, item.value]}>
                  {item.description}
                </option>
              ))}
          </Select>
        </Box>
      </Flex>
      <Box p={2} w="100%">
        <Text>
          Valor a ser pago: <strong>R$ {amountDeposit}</strong>
        </Text>
      </Box>
      <Box display="flex" flexDir={['column', 'column', 'row', 'row']} w="100%" mt="40px" mb="40px" key="Payment">
        <Box
          display="flex"
          flexDir={['column', 'column']}
          w={['100%', '100%', '50%', '50%']} // Ajuste a largura conforme necessário
          key="Payment"
        >
          <Flex flexDir={['column', 'column', 'row', 'row']}>
            <Box m="5px">
              <Input
                autoComplete="off"
                type="text"
                name="name"
                className="form-control"
                placeholder="Nome no Cartão"
                required
                onChange={e => handleInputChange(e, 'name')}
                onFocus={() => handleInputFocus('name')}
              />
            </Box>
            <Box m="5px">
              <Input
                autoComplete="off"
                type="tel"
                name="number"
                className="form-control"
                placeholder="Número do Cartão"
                pattern="[\d| ]{16,22}"
                required
                onChange={e => handleInputChange(e, 'number')}
                onFocus={() => handleInputFocus('number')}
              />
            </Box>
          </Flex>
          <Flex flexDir={['column', 'column', 'row', 'row']}>
            <Box m="5px">
              <Input
                autoComplete="off"
                type="tel"
                name="expiry"
                className="form-control"
                placeholder="Validade"
                pattern="\d\d/\d\d"
                required
                onChange={e => handleInputChange(e, 'expiry')}
                onFocus={() => handleInputFocus('expiry')}
              />
            </Box>
            <Box m="5px">
              <Input
                autoComplete="off"
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                pattern="\d{3,4}"
                required
                onChange={e => handleInputChange(e, 'cvc')}
                onFocus={() => handleInputFocus('cvc')}
              />
            </Box>
          </Flex>
        </Box>
        <Box ml={['0', '0', '5px', '5px']} mt={['20px', '20px', '0px', '0px']} className="App-payment">
          <CardPay
            key="card_info_preview"
            number={cardInfo.number}
            name={cardInfo.name}
            expiry={cardInfo.expiry}
            cvc={cardInfo.cvc}
            focused={cardInfo.focused}
            callback={setCardPayInfo}
          />
        </Box>
      </Box>
    </Box>
  );
}
