import React from 'react'
import { InfoButton } from '../../components/Buttons/InfoButton'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { SearchFilter } from '../../components/inputs/filters/search'
import { DateFilter } from '../../components/inputs/filters/date'
import { useFetchCPT } from '../../hooks/useFetchCPT'
import { Box, Flex, Heading, Icon, Text, useMediaQuery } from '@chakra-ui/react'
import { VscChecklist } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { DataGrid } from '../../components/DataGrid'
import { isNull, formatPrice } from '../../utils/FormatFunctions'
import AlertModal from '../../components/AlertModal'

const Finances = () => {
  const baseUrl = '/v1/transactions'
  const { requestApiCPT, data, loading, error } = useFetchCPT(baseUrl)
  const navigate = useNavigate()

  const columns = [
    { name: 'product.description', label: 'Produto' },
    { name: 'transactioned_at', label: 'Data de criação' },
    { name: 'updated_at', label: 'Atualizado em' },
    { name: 'value', label: 'Valor' },
  ]

  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  })

  return (
    <Flex flexDir="column" ml={isMobile ? '0' : '4vw'} w={isMobile ? '100%' : '73vw'}>
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          <Box w="80px" />
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="panel/dashboard" />

            <Heading as="h1" size="lg">
              Finanças
            </Heading>
          </Flex>

          <DataGrid
            isId={null}
            columns={columns}
            rows={data != null && data?.transactions}
            requestApiCPT={requestApiCPT}
            error={error}
            baseUrl={baseUrl}
            data={data != null && data?.transactions}
            hasCreate={false}
            loading={loading}
            infoButton={[
              {
                name: 'steps',
                button: (
                  <AlertModal
                    alertMsg={
                      <Flex align="center">
                        <Icon as={VscChecklist} color="#1890FF" mr="5px" fontSize="25px" />
                        <Text>Clique no ícone para listar as etapas de financa</Text>
                      </Flex>
                    }
                    msgStatus="info"
                    ButtonOpen={InfoButton}
                  />
                ),
              },
            ]}
            columnWithAction={[
              {
                name: 'steps',
                columnAction: (rowItem: {
                  id: any
                  total_comment:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined
                }) => (
                  <Flex align="center">
                    <IconGeneralButton
                      Icon={VscChecklist}
                      color="#1890FF"
                      fontSize="25px"
                      onClick={() => navigate(`${rowItem.id}`)}
                    />
                    <Text fontWeight="bold">{rowItem.total_comment}</Text>
                  </Flex>
                ),
              },
            ]}
            rowActions={{ show: true, edit: false, delete: false }}
            formatColum={[
              {
                field: 'transactioned_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'updated_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'tid',
                formatField: isNull,
              },
              {
                field: 'value',
                formatField: formatPrice,
              },
              {
                field: 'charge_id',
                formatField: isNull,
              },
              {
                field: 'ownership_id',
                formatField: isNull,
              },
              {
                field: 'product_id',
                formatField: isNull,
              },
              {
                field: 'transaction_id',
                formatField: isNull,
              },
              {
                field: 'vindi_tid',
                formatField: isNull,
              },
              {
                field: 'vindi_tgw_id_token',
                formatField: isNull,
              },
            ]}
            headerFilters={[
              {
                name: 'search',
                filterComponent: SearchFilter,
              },
              {
                name: 'startDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data inicial',
              },
              {
                name: 'endDate',
                filterComponent: DateFilter,
                placeholder: 'Selecionar data final',
              },
            ]}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default Finances
