// TransactionsDetails.tsx

import { Box, Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Loader from '../../components/Loader'
import NotData from '../../components/NotData'
import { useFetchCPT } from '../../hooks/useFetchCPT'
import { useEffect } from 'react'

const TransactionsDetails = () => {
  const { id } = useParams()
  const baseUrl = '/v1/transactions'
  const { requestApiCPT, data, loading } = useFetchCPT(`${baseUrl}/${id}`)

  useEffect(() => {
    requestApiCPT('get', null, `${baseUrl}/${id}`)
  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!data) {
    return <NotData />
  }

  const transaction = data

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Detalhes da Transação
          </Text>
        </Flex>
        <Box>
          {/* <Text mb="2">
            <strong>ID da Transação:</strong> {transaction?.transaction_id}
          </Text> */}
          {/* <Text mb="2">
            <strong>Usuário:</strong> {transaction?.users?.user_firstname}
          </Text> */}
          {/* <Text mb="2">
            <strong>ID da Propriedade:</strong> {transaction?.ownership_id ?? 'N/A'}
          </Text> */}
          <Text mb="2">
            <strong>Produto:</strong> {transaction?.product?.description}
          </Text>
          <Text mb="2">
            <strong>Valor:</strong> R$ {transaction?.value.toFixed(2)}
          </Text>
          {/* <Text mb="2">
            <strong>TID:</strong> {transaction?.tid}
          </Text>
          <Text mb="2">
            <strong>ID Token Vindi:</strong> {transaction?.vindi_tgw_id_token}
          </Text>
          <Text mb="2">
            <strong>Vindi TID:</strong> {transaction?.vindi_tid}
          </Text>
          <Text mb="2">
            <strong>NSU:</strong> {transaction?.NSU}
          </Text>
          <Text mb="2">
            <strong>ID da Carga:</strong> {transaction?.charge_id ?? 'N/A'}
          </Text> */}
          <Text mb="2">
            <strong>Data da Transação:</strong> {moment(transaction?.transactioned_at).format('DD/MM/yyyy hh:mm')}
          </Text>
          <Text mb="2">
            <strong>Atualizado em:</strong> {moment(transaction?.updated_at).format('DD/MM/yyyy hh:mm')}
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

export default TransactionsDetails
