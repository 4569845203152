import React from 'react'
import { Box, Center, Image, Text } from '@chakra-ui/react'

export default function NoPage() {
  return (
    <Box w="100%">
      <Center>
        <Box marginTop="100px">
          <Image src="/assets/bg/404.svg" width={500} />
        </Box>
      </Center>
      <Center>
        <Box marginTop="100px" marginBottom="100px">
          <Text textAlign="center" fontWeight="bold" fontSize="30px">
            Página não encontrada!
          </Text>
          <Text textAlign="center" fontSize="20px">
            Certifique que o caminho esteja correto, ou volte mais tarde
          </Text>
        </Box>
      </Center>
    </Box>
  )
}
