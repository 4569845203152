/* eslint-disable default-case */
import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'

import SideBarCheckout from '../../components/SideBarCheckout'
import StepsCheckout from '../../components/StepsCheckout'
import { useLocation } from 'react-router-dom'
import Loader from '../../components/Loader'
import { useSearch } from '../../providers/SearchProvider'
import LoaderCheckout from '../../components/LoaderCheckout'

export default function Checkout() {
  const { state } = useLocation()
  const { loadingC, loadingB, doBookingPolice } = useSearch()
  const { itemCheckout, dataCangoroo, sR } = state
  const [stateStep, setStateStep] = useState({
    step: 1,
    formData: '',
  })

  const { step, formData } = stateStep
  const prevStep = data => {
    setStateStep({ ...stateStep, step: step - 1, formData: { ...formData, ...data } })
  }
  const nextStep = data => {
    setStateStep({ ...stateStep, step: step + 1, formData: { ...formData, ...data } })
  }

  // eslint-disable-next-line consistent-return
  const phases = () => {
    switch (step) {
      case 1:
        return 'assets/icons/step1.png'
      case 2:
        return 'assets/icons/step2.png'
      case 3:
        return 'assets/icons/step3.png'
    }
  }

  const phasesTxt = () => {
    switch (step) {
      case 1:
        return 'Verificação de disponibilidade'
      case 2:
        return 'Passageiros'
      case 3:
        return 'Pagamento e confirmação'
    }
  }

  // eslint-disable-next-line no-console

  if (loadingB) {
    return <LoaderCheckout />
  }

  return (
    <>
      {loadingC && typeof doBookingPolice?.Rooms === 'undefined' ? (
        <Loader />
      ) : (
        <Box display="flex" justifyContent="center" justify="center" alignContent="center">
          <Box display="flex" mt="80px" flexDir="column" w={['400px', '600px', '800px', '1280px']}>
            <Box display={['unset', 'none', 'none', 'none']} align="center" justifyContent="center">
              <Text fontWeight="black" fontSize="24px">
                {phasesTxt()}
              </Text>
            </Box>
            <Box
              display={['none', 'unset', 'unset', 'unset']}
              bgColor="white"
              h={['38px', '100px']}
              bgImage={phases()}
              bgRepeat="no-repeat"
              bgSize="contain"
              borderWidth={1}
              w={['98%', '100%', '100%', '100%']}
              borderRadius="10px"
            />
            <Box
              display="flex"
              flexDir={['column', 'row']}
              mt="80px"
              mb="80px"
              w={['400px', '600px', '800px', '1280px']}
            >
              <SideBarCheckout data={dataCangoroo ?? []} itemCheckout={itemCheckout ?? []} sR={sR ?? []} />
              <StepsCheckout
                dataCangooroo={dataCangoroo ?? []}
                step={step ?? 1}
                prevStep={prevStep}
                nextStep={nextStep}
                itemCheckout={itemCheckout ?? []}
                sR={sR ?? [0]}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
