import React, { useState } from 'react'
import { Box, Button } from '@chakra-ui/react'
import { BiBed } from 'react-icons/bi'
import { rWidth } from '../../utils/responsiveSizes'
import { useLanguage } from '../../providers/LanguageProvider'
import { translates } from '../../utils/languague'

export default function TabFilter() {
  const { language } = useLanguage()
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box display="flex" justifyItems="flex-start" alignItems="center" w={rWidth} h="70px">
      <Button
        onClick={() => setTabIndex(0)}
        leftIcon={<BiBed color="black" />}
        colorScheme={'brand'}
        variant={'outline'}
      >
        {translates(language)?.searchbox?.tabF}
      </Button>
      {/* <Button
        ml="30px"
        onClick={() => setTabIndex(1)}
        leftIcon={<CgAirplane color="black" />}
        colorScheme={tabIndex === 1 ? 'red' : 'black'}
        variant={tabIndex === 1 ? 'outline' : 'ghost'}
      >
        Voos
      </Button>
      <Button
        ml="30px"
        onClick={() => setTabIndex(2)}
        leftIcon={<BiPackage color="black" />}
        colorScheme={tabIndex === 2 ? 'red' : 'black'}
        variant={tabIndex === 2 ? 'outline' : 'ghost'}
      >
        Pacotes
      </Button>
      <Button
        ml="30px"
        onClick={() => setTabIndex(3)}
        leftIcon={<BiCar color="black" />}
        colorScheme={tabIndex === 3 ? 'red' : 'black'}
        variant={tabIndex === 3 ? 'outline' : 'ghost'}
      >
        Aluguel de carros
      </Button> */}
    </Box>
  )
}
