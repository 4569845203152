import React from 'react';
import { Box, Button, useMediaQuery } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const FloatingButton = () => {
  const location = useLocation();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const isRootPage = location.pathname !== '/';

  return (
    <Box
      display={isLargerThan768 ? 'none' : 'flex'}
      position="fixed"
      flexDir="column"
      bottom={isLargerThan768 ? '1rem' : '0.5rem'}
      left={isRootPage ? (isLargerThan768 ? '1rem' : '0.5rem') : 'auto'}
      right={isRootPage ? 'auto' : isLargerThan768 ? '1rem' : '0.5rem'}
      zIndex={1000}
    >
      <Button
        onClick={() => window.open(`https://api.whatsapp.com/send?phone=556230892500`, '_blank', 'noreferrer')}
        display="flex"
        color="white"
        w={isLargerThan768 ? '70px' : '50px'}
        h={isLargerThan768 ? '70px' : '50px'}
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        bg="whatsapp.600"
        marginRight={isLargerThan768 && isRootPage ? 70 : 0}
        mb={5}
        boxShadow="lg"
        _hover={{ bg: 'brandOrange.600' }}
      >
        <FaWhatsapp size={isLargerThan768 ? 50 : 30} color="white" />
      </Button>
    </Box>
  );
};

export default FloatingButton;
