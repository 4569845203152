import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import UserForm from '../../../components/forms/UserForm'
import { useFetchCPT } from '../../../hooks/useFetchCPT'

export default function UsersCadastroPage() {
  // const { itemId } = useParams()
  // TO DO: verificar depois
  const userData = JSON.parse(localStorage.getItem('__user_data') || '')
  const userId = userData?.user_id
  const { requestApiCPT, data } = useFetchCPT(`v1/users/${userId}`)

  useEffect(() => {
    const getInfos = async () => {
      if (userId !== undefined) {
        await requestApiCPT('get')
      }
    }
    getInfos()
  }, [])

  return <UserForm defaultValues={data ?? []} />
}
