import React from 'react'
import { useTimer } from 'react-timer-hook'
import { Button } from '@chakra-ui/react'
import { BiRadar } from 'react-icons/bi'

export default function MyTimer({ expiryTimestamp }: any) {
  const { seconds, minutes, start, resume } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => console.warn('onExpire called'),
  })

  return (
    <Button disabled colorScheme="red" mr="20px" rightIcon={<BiRadar />} variant="solid">
      A ultima cotação será concluida em: {minutes < 10 ? '0' : ''}
      {minutes}:{seconds < 10 ? '0' : ''}
      {seconds}
    </Button>
  )
}
