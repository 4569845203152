import React, { useEffect, useState } from 'react'
import { Box, Image, Text, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { BsStarFill } from 'react-icons/bs'
import { useSearch } from '../../providers/SearchProvider'
import { useCotation } from '../../providers/CotationProvider'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useLanguage } from '../../providers/LanguageProvider'
import { apiCPT } from '../../services/api'

export default function SideBarCheckout({ data, sR }) {
  moment.locale('pt-br')
  const { language } = useLanguage()
  const { selectedDates, nNights, loadingC, doBookingPolice } = useSearch()
  const { points, isCotation, cotation, markupC } = useCotation()
  const [hotelDetails, setHotelDetails] = useState(JSON.parse(localStorage.getItem('_hotelDetails')))
  const [cashClubs, setCashClubs] = useState({})
  const [userData, setUserData] = useState([])

  useEffect(() => {
    if (localStorage.getItem('_hotelDetails')) {
      setHotelDetails(JSON.parse(localStorage.getItem('_hotelDetails')))
    }
  }, [])

  const typeCotation = () => {
    if (isCotation == 'USD') {
      const base = data?.TotalSellingPrice?.Value
      const cot = cotation
      const calc = base / cot
      const cotMarkup = calc * (markupC / 100)
      const result = Math.ceil(cotMarkup + calc)
      return result?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
    if (isCotation == 'BRL') {
      return data?.TotalSellingPrice?.Value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
    if (isCotation == 'POINTS') {
      const base = data?.TotalSellingPrice?.Value
      const cot = points
      // eslint-disable-next-line prettier/prettier
      const calc = Math.ceil(base * cot)
      return calc
    }
    return data?.TotalSellingPrice?.Value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      setUserData(JSON.parse(localStorage.getItem('__user_data')))
    }
  }, [])

  const getBalance = async () => {
    try {
      const res = await apiCPT.get(`v1/cashclubs/pointsbalance/${userData?.user_id}`)
      if (res.status === 200) {
        setCashClubs(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (userData?.user_id !== undefined) {
      getBalance()
    }
  }, [userData?.user_id])

  const isNational =
    hotelDetails?.destination?.countryId === 'BR' ? Math.floor(cashClubs?.saldoN) : Math.floor(cashClubs?.saldoI)

  return (
    <>
      {!loadingC && doBookingPolice !== undefined && doBookingPolice?.Rooms?.length > 0 && (
        <Box
          alignSelf={['center']}
          p={['20px']}
          w="25vw"
          minW={['95%', '315px', '415px']}
          borderRadius="8px"
          borderWidth={2}
          h="100%"
          minH="700px"
        >
          <Text fontWeight="bold">Dados de sua reserva</Text>
          <Box mt="10px" display="flex" flexDir="row" h="70px">
            <Image resize="both" w="80px" src={hotelDetails?.urlThumb} />
            <Box ml="10px">
              <Box display="flex" flexDir="row">
                {Array.from({ length: hotelDetails?.category }, () => (
                  <BsStarFill key={`category${hotelDetails?.id}`} color="#EDCA4D" />
                ))}
              </Box>
              <Text fontWeight="bold">{hotelDetails?.name ?? ''}</Text>
              <Text>{`${hotelDetails?.destination?.name[language] ?? ''} - ${hotelDetails.zipCode ?? ''}`}</Text>
            </Box>
          </Box>
          <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
          <Box mt="50px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">Entrada</Text>

            <Text>{moment(selectedDates[0]).format('LL')}</Text>
          </Box>
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">Saída</Text>
            <Text>{moment(selectedDates[1]).format('LL')}</Text>
          </Box>
          <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">Hóspedes</Text>
            {sR?.map(item => (
              <Text key="a">
                {item?.numberOfAdults} {item?.numberOfAdults <= 1 ? 'adulto' : 'adultos'}
              </Text>
            ))}

            {sR?.map(
              item =>
                item?.childrenAge?.length > 0 && (
                  <>
                    <Text key="a">
                      {item?.childrenAge?.length} {item?.childAges?.length <= 1 ? 'criança' : 'crianças'}
                    </Text>
                  </>
                ),
            )}
          </Box>
          <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">Quartos:</Text>
            <Text>{sR.length}</Text>
          </Box>
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            {doBookingPolice?.Rooms[0]?.RoomDescription && (
              <>
                <Text fontWeight="bold">Quarto:</Text>
                <Box ml="20px" display="flex" justifySelf="flex-end">
                  <Text textAlign="right">{doBookingPolice?.Rooms[0]?.RoomDescription ?? ''}</Text>
                </Box>
              </>
            )}
          </Box>
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">Alimentação</Text>
            {data?.HasBreakfast ? <Text>Café da manhã</Text> : <Text>Sem Café da manhã</Text>}
          </Box>
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontWeight="bold">
              {nNights} {nNights == 1 ? 'Noite' : 'Noites'}
            </Text>
            <Box display="flex" flexDir="row">
              <Text>
                {typeCotation()}
                {isCotation == 'POINTS' && (hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I')}
              </Text>
              {isCotation == 'POINTS' && <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />}
            </Box>
          </Box>

          <>
            <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
              <Text fontWeight="bold">Meus CashClubs:</Text>
              <Box display="flex" flexDir="row">
                <Text>
                  {hotelDetails?.destination?.countryId === 'BR'
                    ? Math.floor(cashClubs?.saldoN)
                    : Math.floor(cashClubs?.saldoI)}
                </Text>
                <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />
              </Box>
            </Box>
            {/* <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
              <Text fontWeight="bold">Débitos:</Text>{' '}
              <Box display="flex" flexDir="row">
                <Text color="red">
                  -{typeCotation()}
                  {isCotation == 'POINTS' &&
                    (hotelDetails?.destination?.countryId === 'BR' ? ' CashClubs N' : ' CashClubs I')}
                </Text>
                {isCotation == 'POINTS' && <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />}
              </Box>
            </Box> */}
            {isCotation == 'POINTS' && (
              <Box display="flex" alignItems="center" mt="20px" justifyContent="space-between" flexDir="row">
                <Text fontWeight="bold">Após a transação:</Text>
                <Box display="flex" flexDir="row">
                  <Text color="red">
                    {hotelDetails?.destination?.countryId === 'BR'
                      ? Math.floor(cashClubs?.saldoN) - typeCotation()
                      : Math.floor(cashClubs?.saldoI) - typeCotation()}
                    {hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I'}
                  </Text>
                  <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />
                </Box>
              </Box>
            )}
          </>

          {isNational - typeCotation() < 0 && (
            <>
              <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
              <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                <Alert status="warning">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Viajex insuficientes!</AlertTitle>
                    <AlertDescription>
                      Seu saldo total de Viajex é insuficiente para abater o valor total desta operação
                    </AlertDescription>
                  </Box>
                </Alert>
              </Box>
            </>
          )}
          <>
            <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
            <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
              <Alert status="info">
                <AlertIcon />
                <Box>
                  <AlertTitle>Quer alterar a quantidade de Viajex?</AlertTitle>
                  <AlertDescription>
                    Ao fim do processo, você poderá dizer quantos Viajex deseja utilizar nesta operação
                  </AlertDescription>
                </Box>
              </Alert>
            </Box>
          </>
          <hr style={{ marginTop: '40px', marginBottom: '40px' }} />
          <Box mt="20px" display="flex" justifyContent="space-between" flexDir="row">
            <Text fontSize="26px" fontWeight="bold">
              Total
            </Text>
            <Box display="flex" flexDir="row">
              <Text fontSize="26px" fontWeight="bold">
                {typeCotation()}
                {isCotation == 'POINTS' && (hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I')}
              </Text>
              {isCotation == 'POINTS' && <Image ml="5px" h="35px" src="/assets/icons/cash-club.png" />}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
