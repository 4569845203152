/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */

import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import { Box, List, ListIcon, ListItem, Text } from '@chakra-ui/react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { MdCheckCircle } from 'react-icons/md'

export default function SliderBedroom({ images, title, textDescription = '' }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)
  const carouselItemsRef = useRef([])

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(0, images.length)

      setSelectedImageIndex(0)
      setSelectedImage(images[0])
    }
  }, [images])

  const handleSelectedImageChange = (newIdx: number) => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx])
      setSelectedImageIndex(newIdx)
    }
  }

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1
      if (newIdx >= images.length) {
        newIdx = 0
      }
      handleSelectedImageChange(newIdx)
    }
  }

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1
      if (newIdx < 0) {
        newIdx = images.length - 1
      }
      handleSelectedImageChange(newIdx)
    }
  }

  const amenidade = [
    'Ar-condicionado',
    'Serviço de TV a cabo',
    'Frigobar',
    'TV',
    'TV LCD',
    'Assento elevado para vaso sanitário',
    'Acesso a cadeira de rodas',
    'Para não fumantes',
    'Bancadas/pia de altura baixa',
    'Minibar',
    'Toalhas fornecidas',
    'Barra de apoio próxima ao vaso sanitário',
    'Serviço diário de arrumação',
    'Lençóis fornecidos',
    'Roupa de cama não disponível',
    'Banheiro privativo',
    'Sem serviço de arrumação',
    'Produtos de toalete grátis',
    'Sabonete',
    'Secador de cabelo',
    'Xampu',
    'Serviço de quarto (24 horas)',
    'Papel higiênico',
    'Escrivaninha',
    'Espaço de trabalho ideal para notebook',
  ]

  // eslint-disable-next-line consistent-return
  function column1(item, idx) {
    const divisor = Math.round(amenidade.length / 3)
    if (idx === 0 || idx <= divisor) {
      return (
        <ListItem key={idx}>
          <ListIcon as={MdCheckCircle} />
          {item}
        </ListItem>
      )
    }
  }
  // eslint-disable-next-line consistent-return
  function column2(item, idx) {
    const divisor = Math.round(amenidade.length / 3)
    if (idx > divisor && idx <= divisor * 2) {
      return (
        <ListItem key={idx}>
          <ListIcon as={MdCheckCircle} />
          {item}
        </ListItem>
      )
    }
  }
  // eslint-disable-next-line consistent-return
  function column3(item, idx) {
    const divisor = Math.round(amenidade.length / 3)
    if (idx > divisor * 2) {
      return (
        <ListItem key={idx}>
          <ListIcon as={MdCheckCircle} />
          {item}
        </ListItem>
      )
    }
  }
  const regex = /(<([^>]+)>)/gi
  return (
    <Box w="5xl" mb="100px">
      <Box w="100%" display="flex" flexDir={['column', 'row']}>
        <Box w={['40%', '60%']}>
          <div className="carousel-container">
            <div className="selected-image" style={{ backgroundImage: `url(${selectedImage?.url})` }}>
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <button
                    className="carousel__button carousel__button-left"
                    onClick={handleLeftClick}
                    style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                  >
                    <BiChevronLeft size={40} color="black" />
                  </button>
                </div>
                <div>
                  <button
                    className="carousel__button carousel__button-right"
                    onClick={handleRightClick}
                    style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                  >
                    <BiChevronRight size={40} color="black" />
                  </button>
                </div>
              </div>
            </div>
            <div className="carousel">
              <div className="carousel__images">
                {images &&
                  images.map((image, idx) => (
                    <div
                      onClick={() => handleSelectedImageChange(idx)}
                      style={{ backgroundImage: `url(${image.url})` }}
                      key={image.id}
                      className={`carousel__image ${selectedImageIndex === idx && 'carousel__image-selected'}`}
                      ref={el => (carouselItemsRef.current[idx] = el)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Box>
        <Box mt="20px" w={['30%', '60%']}>
          <Text fontSize="20px" color="theme.grey" fontWeight="bold">
            {title}
          </Text>
          <Text>{textDescription.replace(regex, '')}</Text>
        </Box>
      </Box>
      <Box w={['40%', '100%']} borderBottomWidth={1} mt="50px" mb="50px" />
      <Box>
        <strong>Amenidades</strong>
        <br />
        <br />
        <Box w="100%" justifyContent="space-between" paddingEnd="100px" display="flex" flexDir={['column', 'row']}>
          <List spacing={3}>{amenidade.map((item, idx) => column1(item, idx))}</List>
          <List spacing={3}>{amenidade.map((item, idx) => column2(item, idx))}</List>
          <List spacing={3}>{amenidade.map((item, idx) => column3(item, idx))}</List>
        </Box>
      </Box>
    </Box>
  )
}
